import React from 'react';

import {ArrowButton} from '../../../../components/arrowButton';
import useAppLayout from '../../../../utils/useAppLayout';
import {SectionWrapper, SectionContent, ArrowWrapper} from '../../../../components/common';
import Linked from '../../../../components/navigation/linked';
import ClientsGrid from './ClientsGrid';
import {FOOTER_TEXT} from '../../constants/sectionFive';
import {ANIMATE_FROM_BOTTOM} from '../../constants/common';

const SectionFive = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <SectionContent layout={layout}>
        <ClientsGrid />
        <ArrowWrapper>
          <Linked component={(props) => <ArrowButton {...props} />} to={'/work#case-studies'} text={FOOTER_TEXT} />
        </ArrowWrapper>
      </SectionContent>
    </SectionWrapper>
  );
};

export default SectionFive;
