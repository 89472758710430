import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Header} from '../components/common';
import {Logo} from './logo';
import {getCurrentLayoutProperty} from '../utils/common';
import useAppLayout from '../utils/useAppLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${getCurrentLayoutProperty({
    isDesktop: '160px 0',
    isLaptop: '120px 0',
    isTablet: '160px 0 120px 0',
    isMobile: '88px 0 80px 0',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  max-width: 1925px;
`;

const Title = styled(Header)`
  width: ${getCurrentLayoutProperty({
    isDesktop: '80%',
    isLaptop: '70%',
    isTablet: '80%',
    isMobile: '70%',
  })};
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  margin-bottom: 0;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '103px',
    isLaptop: '72px',
    isTablet: '72px',
    isMobile: '42px',
  })};
`;

const HeaderWithAELogo = ({title}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <Logo />
      <Title layout={layout}>{title}</Title>
    </Wrapper>
  );
};

HeaderWithAELogo.propTypes = {
  title: PropTypes.string,
};

export default HeaderWithAELogo;
