import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useAppLayout from '../../utils/useAppLayout';
import {HeaderSmall} from '../../components/common';
import {getCurrentLayoutProperty} from '../../utils/common';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  flex: 1 1 auto;
  border: ${(props) => (props.active ? '1px solid #ff4242' : 'none')};
  text-shadow: none;
  align-items: center;
  padding: 20px 22px;
  font-family: Kontora Medium;
  font-style: normal;
  font-weight: 400;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '20px',
    isMobile: '17px',
  })};
  line-height: 120%;
  font-color: #cccccc;
  height: 50px;
`;

export const Label = styled(HeaderSmall)`
  margin: 0;
  margin-bottom: 16px;
  width: 100%;
`;

const Alarm = styled.span`
  color: #ff4242;
  margin-top: 10px;
`;

// TODO change x to icon
export const FormInput = React.forwardRef(({id, label, placeholder, register, required, errors, pattern}, ref) => {
  const layout = useAppLayout();

  return (
    <InputWrapper>
      <Label htmlFor={id} layout={layout}>
        {label}
      </Label>
      <Input
        id={id}
        name={id}
        layout={layout}
        placeholder={placeholder}
        ref={ref}
        {...register(label, {required: required, pattern: pattern && {value: pattern}})}
        errors
        label={label}
        active={errors[label] && true}
      />
      {errors[label] && errors[label].type === 'pattern' && <Alarm role="alert">x Valid {id} is required</Alarm>}
      {errors[label] && errors[label].type === 'required' && <Alarm role="alert">x {label} is required</Alarm>}
    </InputWrapper>
  );
});

FormInput.propTypes = {
  id: PropTypes.string,
  errors: PropTypes.object,
  pattern: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(RegExp)]),
  label: PropTypes.string.isRequired,
  register: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
