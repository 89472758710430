import React from 'react';
import PropTypes from 'prop-types';

import useAppLayout from '../../../../utils/useAppLayout';
import {PopupWrapper} from '../../../../components/popup-wrapper';
import {MemberContent} from './member-content';
import {MemberControls} from './member-controls';

const MemberPopup = (
  {
    photo,
    name,
    position,
    work,
    skills,
    getNextMember,
    getPrevMember,
    enableNextButton,
    enablePrevButton,
    closeCard,
    isOpen,
    setOpen,
  },
  index
) => {
  const layout = useAppLayout();

  return (
    <PopupWrapper
      member
      layout={layout}
      key={`new-${index}`}
      isOpen={isOpen}
      onClick={closeCard}
      setOpen={setOpen}
      content={<MemberContent photo={photo} name={name} position={position} work={work} skills={skills} />}
      controls={
        (layout.isDesktop || layout.isLaptop) && (
          <MemberControls
            getNextMember={getNextMember}
            getPrevMember={getPrevMember}
            enableNextButton={enableNextButton}
            enablePrevButton={enablePrevButton}
          />
        )
      }
    />
  );
};

export default MemberPopup;

MemberPopup.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  work: PropTypes.arrayOf(PropTypes.string),
  skills: PropTypes.arrayOf(PropTypes.string),
  getNextMember: PropTypes.func,
  getPrevMember: PropTypes.func,
  enableNextButton: PropTypes.bool,
  enablePrevButton: PropTypes.bool,
  closeCard: PropTypes.func,
  isOpen: PropTypes.bool,
  preview: PropTypes.bool,
  setOpen: PropTypes.func,
};
