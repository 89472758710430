import React, {useMemo} from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import Footer from '../../components/footer';
import CheckOurProjects from './components/CheckOurProjects';
import TextBlock from './components/TextBlock';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import MainBanner from './components/MainBanner';
import SloganPanel from './components/SloganPanel';
import {Header} from '../../components/common';

const mainBackgroundImage = {
  isDesktop: '/case-studies/foursquare/page1/mainBackgroundImageDesktop.webp',
  isLaptop: '/case-studies/foursquare/page1/mainBackgroundImageLaptop.webp',
  isTablet: '/case-studies/foursquare/page1/mainBackgroundImageTablet.webp',
  isMobile: '/case-studies/foursquare/page1/mainBackgroundImageMobile.webp',
};

const tabletImage = {
  isDesktop: '/case-studies/foursquare/page1/tabletImageDesktop.webp',
  isLaptop: '/case-studies/foursquare/page1/tabletImageLaptop.webp',
  isTablet: '/case-studies/foursquare/page1/tabletImageTablet.webp',
  isMobile: '/case-studies/foursquare/page1/tabletImageMobile.webp',
};

const FirstPageText = {
  mainHeader: 'Geospatial Data Conversions for ESRI',
  bages: ['product', 'web', 'digital cartography'],
};

//----------- End Page 1 Data

//----------- Page 2 Data

const SecondPageText = {
  sectionHeader: '3 unique products',
  sectionText: [
    `Our team developed and maintained 3 unique products related to this work: 
    a Tile converter, an I3S App and an I3S Debug tool. In a nutshell,
    each of these tools covers one of the dozens complex developers problems
    when working with 3D geospatial data on the web.`,
    `This project is a contribution to open-source, in collaboration with
    industry leaders. It means that anyone with the engineering skills
    can use these tools to make it easier for themselves
    to work with a particular project.`,
  ],
};

//----------- End Page 2 Data

//----------- Page 3 Data

const tileConverterText = {
  header: 'Tile converter',
  description: [
    `
    The tile-converter is a command line utility (CLI)
    for two-way batch conversion between 3DTiles
    and I3S formats. It can load the tile set to
    be converted directly from a URL. The purpose
    of creating this tool was to give the user
    the opportunity to choose what geospatial
    data format to work with`,
  ],
};

const i3sAppText = {
  header: 'I3S App',
  description: [
    `
    Without topic-specific knowledge, the description
    may sound much confusing. To make it simple: the
    work was done to improve, accelerate and simplify
    working with loaders.gl and deck.gl – JavaScript
    frameworks to work with data analysis
    and visualization on the web.`,

    `If you feel prepared: we improved upon deck.gl
    to work with segments of mesh data. Data can be
    segmented for detailed viewing throughout a dataset.
    We also optimized server requests by combining
    nodes into pages through metadata adjustments.
    The app brings DRACO-compressed data, a widely
    used encoder for 3D geometry data to I3S 1.7.
    Realistic models can be generated
    through GLTF formats in visgl `,
  ],
};

const codeImage = {
  isDesktop: '/case-studies/foursquare/page3/codeImageDesktop.webp',
  isLaptop: '/case-studies/foursquare/page3/codeImageLaptop.webp',
  isTablet: '/case-studies/foursquare/page3/codeImageTablet.webp',
  isMobile: '/case-studies/foursquare/page3/codeImageMobile.webp',
};

//----------- End Page 3 Data

//----------- Page 4 Data

const i3sDebugToolText = {
  header: 'I3S Debug Tool',
  description: `
    During this journey with ESRI, we found out that
    3D graphics data has a very complicated structure,
    and 3D graphics data for maps is even more complicated.
    There is a lot of extra information stored
    to show 3D objects on a map correctly.`,
};

//----------- End Page 4 Data

//----------- Page 5 Data

const i3sDebugText = `
  It wasn’t surprising that in such complex structures
  of data there could be mistakes. Some mistakes are
  really hard to find with general visualization use cases.
  This was our starting point for building a number
  of features that validate data which is usually hidden
   from plain sight. The result of this work is an I3S
   Debug Application that is hosted on loaders.gl.
`;

//----------- End Page 5 Data

const i3sDebugToolsImage = {
  isDesktop: '/case-studies/foursquare/page5/debugToolsImageDesktop.webp',
  isLaptop: '/case-studies/foursquare/page5/debugToolsImageLaptop.webp',
  isTablet: '/case-studies/foursquare/page5/debugToolsImageTablet.webp',
  isMobile: '/case-studies/foursquare/page5/debugToolsImageMobile.webp',
};

//----------- Page 6 Data

const slogan1 = {
  header: '>36 months',
  description: 'Cooperation with ESRI lasts',
  headerColor: '#000000',
  descriptionColor: '#000000',
  backgroundColor: '#F4E11B',
};
const slogan2 = {
  header: '>2,500,000',
  description: 'Lines of code written and implemented',
  headerColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
  backgroundColor: '#4F52CC',
};
const slogan3 = {
  header: '“It’s now much more convenient”',
  description: 'Geospatial 3D data experts early review results',
  headerColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
  backgroundColor: '#010101',
};

const sloganFragmentHeight = {
  isDesktop: '320px',
  isLaptop: '271px',
  isTablet: '312px',
  isMobile: '210px',
};

//----------- End Page 6 Data

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const toolsSectionGradientAngle = {
  isDesktop: '90',
  isLaptop: '90',
  isTablet: '6',
  isMobile: '6',
};

const gradientPercentage = {
  isDesktop: '50',
  isLaptop: '50',
  isTablet: '45',
  isMobile: '40',
};

const SecondPageWrapper = styled.div`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '80px',
    isMobile: '40px',
  })};
`;

const ToolsSection = styled.div`
  position: relative;
  background: linear-gradient(
    ${getCurrentLayoutProperty(toolsSectionGradientAngle)}deg,
    #000000 ${getCurrentLayoutProperty(gradientPercentage)}%,
    #f4e11b ${getCurrentLayoutProperty(gradientPercentage)}%
  );
  display: grid;
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr',
    isLaptop: '1fr 1fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};
  ${getCurrentLayoutProperty({
    isDesktop: 'grid-template-areas: "text2nd text1st" "img img";',
    isLaptop: 'grid-template-areas: "text2nd text1st" "img img";',
    isTablet: 'grid-template-areas: "text1st" "img" "text2nd";',
    isMobile: 'grid-template-areas: "text1st" "img" "text2nd";',
  })}

  padding-bottom: ${getCurrentLayoutProperty({
    isDesktop: '240px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};

  &:before {
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    background: linear-gradient(20deg, #000000 50%, rgba(0, 0, 0, 0) 50%);
    z-index: 0;
  }

  & > img {
    position: relative;
    margin: auto;
    width: ${getCurrentLayoutProperty({
      isDesktop: '80%',
      isLaptop: '80%',
      isTablet: '95%',
      isMobile: '95%',
    })};
  }

  & > div {
    padding: ${getCurrentLayoutProperty({
      isDesktop: '80px',
      isLaptop: '80px',
      isTablet: '40px',
      isMobile: '40px 16px',
    })};
  }
`;

const I3SDebugToolPanelsSection = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: #4f52cc;
  margin-top: -1px;

  &:after {
    bottom: 0;
    height: 100vw;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(17deg, #ffffff, #ffffff 25%, rgba(0, 0, 0, 0) 25%);
  }
`;

const ToolsImage = styled.img`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '-314px',
    isLaptop: '-200px',
    isTablet: '-140px',
    isMobile: '-84px',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '170px',
    isTablet: '152px',
    isMobile: '70px',
  })};

  width: 95%;
  z-index: 1;
`;

const I3SDebugToolSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: -1px;
  height: ${getCurrentLayoutProperty({
    isDesktop: '1302px',
    isLaptop: '923px',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
`;

const Gradient1 = styled.div`
  height: ${getCurrentLayoutProperty({
    isDesktop: '651px',
    isLaptop: '500px',
    isTablet: '295px',
    isMobile: '127px',
  })};

  background: black;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  position: ${getCurrentLayoutProperty({
    isDesktop: 'absolute',
    isLaptop: 'absolute',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  left: 0;
  right: 0;
  top: 0;
  margin-top: -1px;
`;

const GradientTextContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
`;

const GradientHeader = styled(Header)`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '86px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '35px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 80px 40px 80px',
    isLaptop: '0 80px 40px 80px',
    isTablet: '0 40px 40px 40px',
    isMobile: '0 16px 24px 16px',
  })};
  margin: 0;
`;

const GradientText = styled.div`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '29px',
    isMobile: '17px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 80px',
    isLaptop: '0 80px',
    isTablet: '0 40px',
    isMobile: '0 16px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '33px',
    isTablet: '40px',
    isMobile: '23px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Gradient2 = styled(Gradient1)`
  bottom: 0;
  background: #4f52cc;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  top: ${getCurrentLayoutProperty({
    isDesktop: '651px',
    isLaptop: '500px',
    isTablet: '295px',
    isMobile: '127px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '651px',
    isLaptop: '425px',
    isTablet: '294px',
    isMobile: '134px',
  })};
`;

const DebugTextContainer = styled(GradientText)`
  align-self: flex-end;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 80px',
    isLaptop: '0 80px',
    isTablet: '80px 40px',
    isMobile: '40px 16px',
  })};
`;

const DebugTextBlock = () => {
  const layout = useAppLayout();
  return (
    <GradientTextContainer>
      <GradientHeader layout={layout}>{i3sDebugToolText.header}</GradientHeader>
      <GradientText layout={layout}>{i3sDebugToolText.description}</GradientText>
    </GradientTextContainer>
  );
};

const CaseStudiesFoursquare = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;
  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);

  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        backgroundImage={mainBackgroundImage}
        upperHeader={
          <MainBannerUpperHeader>
            <img src={'/pages/work/esri-logo.webp'} alt="logo" />
          </MainBannerUpperHeader>
        }
        mainHeader={FirstPageText.mainHeader}
        bages={FirstPageText.bages}
        tabletImage={tabletImage}
      />
      <SecondPageWrapper layout={layout}>
        <TextBlockComponent.Text
          headerText={SecondPageText.sectionHeader}
          mainText={SecondPageText.sectionText}
          color="#010101"
          headerColor="#000000"
          wrapHeader={false}
        />
      </SecondPageWrapper>
      <ToolsSection layout={layout}>
        <TextBlock
          style={{gridArea: 'text1st'}}
          headerText={i3sAppText.header}
          mainText={i3sAppText.description}
          color="#010101"
          headerColor="#010101"
        />
        <img
          src={getCurrentLayoutProperty(codeImage)({layout})}
          style={{gridArea: 'img'}}
          alt="background picture"
          loading="lazy"
        />
        <TextBlock
          style={{gridArea: 'text2nd'}}
          headerText={tileConverterText.header}
          mainText={tileConverterText.description}
          color="#FFFFFF"
          headerColor="#F4E11B"
        />
      </ToolsSection>
      <I3SDebugToolSection layout={layout}>
        {!isDesktopType && <DebugTextBlock />}
        <Gradient1 layout={layout}>{isDesktopType && <DebugTextBlock />}</Gradient1>
        <DebugTextContainer layout={layout}>{i3sDebugText}</DebugTextContainer>
        <Gradient2 layout={layout} />
      </I3SDebugToolSection>
      <I3SDebugToolPanelsSection>
        <ToolsImage
          layout={layout}
          src={getCurrentLayoutProperty(i3sDebugToolsImage)({layout})}
          alt="debug tools"
          loading="lazy"
        />
      </I3SDebugToolPanelsSection>
      <SloganPanel
        slogan1={slogan1}
        slogan2={slogan2}
        slogan3={slogan3}
        height={getCurrentLayoutProperty(sloganFragmentHeight)({layout})}
      />
      <CheckOurProjects currentProjectName={'foursquare'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesFoursquare;
