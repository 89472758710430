import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {
  BACKGROUND_ONE,
  BACKGROUND_TWO,
  PARAGRAPH_ONE,
  PARAGRAPH_TWO,
  PICTURE_ONE,
  PICTURE_TWO,
  SUBTITLE_ONE,
  SUBTITLE_TWO,
} from '../../constants/sectionOne';
import InfoBlock from '../../../../components/info-block/info-block';
import PageHeader from './page-header';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  margin: auto;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '0',
    isMobile: '40px',
  })};
  max-width: 1925px;
`;

const SectionOne = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <PageHeader />
      <InfoBlock title={SUBTITLE_ONE} text={PARAGRAPH_ONE} backgroundImage={BACKGROUND_ONE} picture={PICTURE_ONE} />
      <InfoBlock
        title={SUBTITLE_TWO}
        text={PARAGRAPH_TWO}
        backgroundImage={BACKGROUND_TWO}
        picture={PICTURE_TWO}
        arrowLink={'/work'}
        arrowText={'See our work'}
      />
    </SectionWrapper>
  );
};

export default SectionOne;
