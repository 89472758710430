import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {getCurrentLayoutProperty} from '../../../utils/common';
import {ANIMATE_FROM_BOTTOM} from '../constants/common';

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '32px',
    isLaptop: '32px',
    isTablet: '32px',
    isMobile: '24px',
  })};
`;

const ImageLogo = styled.img`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  height: auto;
`;

const TextLogo = styled.img`
  display: flex;
  width: auto;
  height: ${getCurrentLayoutProperty({
    isDesktop: '38px',
    isLaptop: '38px',
    isTablet: '38px',
    isMobile: '28.5px',
  })};
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '6px',
    isLaptop: '6px',
    isTablet: '6px',
    isMobile: '4px',
  })};
`;

const Logo = ({layout}) => (
  <LogoWrapper layout={layout}>
    {/* TODO move it in correct place */}
    <ImageLogo src="/logo/AE-logo.webp" alt="AE Logo" layout={layout} />
    <TextLogo src="/logo/AE-text-logo.webp" alt="AE text Logo" layout={layout} />
  </LogoWrapper>
);

Logo.propTypes = {
  layout: PropTypes.object,
};

export default Logo;
