import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {Header} from '../../../../components/common';
import BulletItem from '../../../../components/bullet-item';

const TITLE = 'Our Approach';

const BULLETS = [
  {
    title: 'We Understand',
    text: 'We look to gather the background and context for your specific focus area, to best understand how to address solutions through technology.',
  },
  {
    title: 'We Research',
    text: 'Through User Interviews, Competive Analysis and Technical Assessments, we can map opportunity areas to best align data, insights and product solutions.',
  },
  {
    title: 'We Ideate',
    text: 'Using a Design-Led process, our designers, analylsts and engineers collaborate on potential solutions through rapid development and prototyping.',
  },
  {
    title: 'We Validate',
    text: 'We take the active solution to new and existing users to confirm how well we can bring data to action as well as how the system performs for various workflows.',
  },
  {
    title: 'We Finalize',
    text: 'All work is documented and thoroughly tested prior to conclusion, ensuring smooth hand off and accurate acceptance testing.',
  },
  {
    title: 'We Support',
    text: 'While the project is in action, we act agile and are flexible in making adjustments in order to make sure at each point teams are happy and business objectives are met',
  },
];

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  margin: auto;
  max-width: 1925px;
`;

const Wrapper = styled.ul`
  display: grid;
  width: 100%;
  padding: 0;

  margin: ${getCurrentLayoutProperty({
    isDesktop: '120px 0 0 0',
    isLaptop: '120px 0 0 0',
    isTablet: '64px 0 0 0',
    isMobile: '64px 0 0 0',
  })};

  row-gap: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};

  column-gap: ${getCurrentLayoutProperty({
    isDesktop: '32px',
    isLaptop: '32px',
    isTablet: '0',
    isMobile: '0',
  })};

  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr',
    isLaptop: '1fr 1fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};

  grid-template-rows: ${({bulletRowsCount}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletRowsCount === 3 ? '1fr 1fr 1fr' : '1fr 1fr',
      isLaptop: bulletRowsCount === 3 ? '1fr 1fr 1fr' : '1fr 1fr',
      isTablet: '1fr',
      isMobile: '1fr',
    })};
`;

const OurApproachSection = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <Header layout={layout}>{TITLE}</Header>
      <Wrapper layout={layout}>
        {BULLETS.map((item, index) => (
          <BulletItem key={item.title} header={item.title} text={item.text} itemNumber={index + 1} />
        ))}
      </Wrapper>
    </SectionWrapper>
  );
};

export default OurApproachSection;
