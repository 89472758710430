export const TITLE = 'Careers';
export const SUBTITLE_INSIDE = 'Inside the Engine';
export const SUBTITLE = "Don't see something for you?";

export const newContent = {
  description: 'Suprematism  - is one of the most influential areas of abstract art in the 20th century.',
  content: [
    'The structure of the universe in Suprematism is expressed in simple geometric forms: a straight line, a rectangle, a circle, a square on a light background, signifying the infinity of space. The ideas of Suprematism, the countdown of which was laid by the famous "Black Square", were embodied in architecture, scenography, printing, and industrial design.',
    '"Suprematism establishes connections with the Earth, but due to its economic constructions, it changes the entire architecture of things on the Earth, in the mundane sense of the word uniting with the space of the moving one-litter masses of the planetary system. Suprematism establishes connections with the Earth, but due to its economic constructions, it changes the entire architecture of things on the Earth, in the mundane sense of the word uniting with the space of the moving one-litter masses of the planetary system"',
  ],
};

export const newsList = [
  {picture: '/news/new-one.png', title: 'Mobile App Onboarding Best Practices', ...newContent},
  {picture: '/news/new-two.png', title: 'How we setup our team and tools', ...newContent},
  {
    picture: '/news/new-three.png',
    title: 'Kazimir Malevich and his Love Supreme',
    ...newContent,
  },
  {picture: '/news/new-four.png', title: 'Geospatial technologies', ...newContent},
  {picture: '/news/new-five.png', title: 'How we setup our team and tools', ...newContent},
];
export const IMAGE4 = {
  isTablet: '/pages/inside/Tablet-4.png',
  isMobile: '/pages/inside/Mobile-4.png',
};
