import React from 'react';
import styled from 'styled-components';

import {Header} from '../../../../components/common';
import {SwipeCarousel} from '../../../../components/swipe-carousel/swipe-carousel';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {newsList, TITLE} from '../../constants/sectionFive';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '92px',
    isTablet: '92px',
    isMobile: '16px',
  })};
`;

const SectionFive = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <Header layout={layout}>{TITLE}</Header>
      <SwipeCarousel options={newsList} />
    </SectionWrapper>
  );
};

export default SectionFive;
