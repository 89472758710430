import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  color: #ffffff;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '40px',
    isMobile: '16px',
  })};
`;

const Header = styled.div`
  color: ${(props) => props.color};
  font-family: 'Kontora ExtraBold';
  font-style: normal;
  font-weight: 700;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '86px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '35px',
  })};
  white-space: ${(props) => (props.wrapHeader ? 'initial' : 'nowrap')};
  line-height: 140%;
  width: ${getCurrentLayoutProperty({
    isDesktop: '40%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  /* or 49px */

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Text = styled.div`
  color: ${(props) => props.color};
  font-family: 'Kontora SemBd';
  font-style: normal;
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: 500,
    isLaptop: 500,
    isTablet: 500,
    isMobile: 600,
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: 140%;
  /* or 24px */

  letter-spacing: 0.01em;
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '16px',
  })};
`;

const propTypes = {
  headerText: PropTypes.object.isRequired,
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  color: PropTypes.string,
  headerColor: PropTypes.string,
  wrapHeader: PropTypes.bool,
};

const HorizontalTextBlock = ({headerText, mainText, color, headerColor, wrapHeader = true}) => {
  const layout = useAppLayout();
  const mainTextArray = Array.isArray(mainText) ? mainText : [mainText];
  return (
    <WholePageContainer layout={layout}>
      <Header color={headerColor ? headerColor : color} layout={layout} wrapHeader={wrapHeader}>
        {headerText}
      </Header>
      <MainTextWrapper>
        {mainTextArray.map((text, i) => (
          <Text color={color} key={i} layout={layout}>
            {text}
          </Text>
        ))}
      </MainTextWrapper>
    </WholePageContainer>
  );
};

HorizontalTextBlock.propTypes = propTypes;

export default HorizontalTextBlock;
