import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: ${getCurrentLayoutProperty({
    isDesktop: '384px',
    isLaptop: '20vw',
    isTablet: '279px',
    isMobile: '279px',
  })};
  min-height: 86px;
  height: ${getCurrentLayoutProperty({
    isDesktop: '116px',
    isLaptop: '116px',
    isTablet: '116px',
    isMobile: '86px',
  })};
  padding: 17px 16px 12px 16px;
  margin: 0;
  transition: 0.4s all;
  background-color: ${(props) => (props.active ? '#ff4242' : '#f7f7f7')};
  color: ${(props) => (props.active ? 'white' : 'inherit')};

  :hover {
    color: ${getCurrentLayoutProperty({
      isDesktop: 'white',
      isLaptop: 'white',
      isTablet: 'inherit',
      isMobile: 'inherit',
    })};
    background: ${getCurrentLayoutProperty({
      isDesktop: '#ff4242',
      isLaptop: '#ff4242',
      isTablet: 'inherit',
      isMobile: 'inherit',
    })};

    > :last-child {
      color: ${getCurrentLayoutProperty({
        isDesktop: 'white',
        isLaptop: 'white',
        isTablet: 'inherit',
        isMobile: 'inherit',
      })};
    }
  }
`;

const Title = styled.span`
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '500',
    isLaptop: '500',
    isTablet: '600',
    isMobile: '600',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: 120%;
`;

const Description = styled.span`
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '500',
    isLaptop: '500',
    isTablet: '600',
    isMobile: '600',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '20px',
    isMobile: '17px',
  })};
  line-height: 120%;
  color: #666666;
  transition: 0.4s all;
`;

const Arrow = styled(FontAwesomeIcon)`
  position: absolute;
  top: 37px;
  right: 24px;
  font-size: 20px;
`;

export const VacancyCard = ({id, title, description, optionId, onClick}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout} active={id == optionId} onClick={onClick}>
      <Title layout={layout}>{title}</Title>
      {layout.isMobile && <Arrow icon={faAngleRight} />}
      <Description layout={layout}>{description}</Description>
    </Wrapper>
  );
};

VacancyCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  optionId: PropTypes.number,
  onClick: PropTypes.func,
};
