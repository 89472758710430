import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import {HeaderMiddle} from '../../components/common';
import {ArrowButton} from '../arrowButton';
import Linked from '../navigation/linked';

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '15px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '24px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1000px',
    isLaptop: '720px',
    isTablet: '680px',
    isMobile: '460px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  max-width: 1925px;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
  margin: ${getCurrentLayoutProperty({
    isDesktop: '20px auto',
    isLaptop: '20px auto',
    isTablet: '20px auto',
    isMobile: '12px auto',
  })};
  background: url(${(props) => props.image}) ${(props) => props.backgroundColor} no-repeat;
  background-position: ${getCurrentLayoutProperty({
    isDesktop: 'bottom right',
    isLaptop: 'bottom right',
    isTablet: 'bottom center',
    isMobile: 'bottom center',
  })};
  background-size: contain;
`;

const ProjectLogo = styled.div`
  display: flex;
  align-items: center;
  height: ${getCurrentLayoutProperty({
    isDesktop: '48px',
    isLaptop: '40px',
    isTablet: '45px',
    isMobile: '25px',
  })};
  width: auto;
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '250px',
    isLaptop: '250px',
    isTablet: '250px',
    isMobile: '130px',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '10px',
    isMobile: '0',
  })};
  background: url(${(props) => props.logo}) no-repeat;
  background-size: contain;
`;

const Header = styled(HeaderMiddle)`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '65px',
    isLaptop: '55px',
    isTablet: '25px',
    isMobile: '-3px',
  })};
  margin-top: 0;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '42px',
    isMobile: '29px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '54%',
    isLaptop: '60%',
    isTablet: '90%',
    isMobile: '90%',
  })};
`;

const Project = ({
  logo,
  description,
  link,
  image,
  backgroundColor,
  textColor,
  arrowButtonBackground,
  arrowColor,
  solution,
}) => {
  const layout = useAppLayout();

  return (
    <ProjectWrapper layout={layout} image={image} backgroundColor={backgroundColor}>
      <ProjectLogo layout={layout} logo={logo} />
      <Header layout={layout} color={textColor}>
        {description}
      </Header>
      <Linked
        component={ArrowButton}
        to={link}
        text={solution ? 'view solution' : 'view project'}
        textColor={textColor}
        backgroundColor={arrowButtonBackground}
        arrowColor={arrowColor}
      />
    </ProjectWrapper>
  );
};

export default Project;

Project.propTypes = {
  logo: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  arrowButtonBackground: PropTypes.string,
  arrowColor: PropTypes.string,
  solution: PropTypes.bool,
};
