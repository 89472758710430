import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import {PageSubtitle, SubHeader} from '../../components/common';
import {ArrowButton} from '../arrowButton';
import {FormInput} from './form-input';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {GreetingsCard} from './greetings-card';
import {useForm} from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '70px 120px',
    isLaptop: '70px 100px',
    isTablet: '50px 70px',
    isMobile: '20px 16px 40px 16px',
  })};
  background: white;
  position: ${getCurrentLayoutProperty({
    isDesktop: 'fixed',
    isLaptop: 'fixed',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '50vw',
    isLaptop: '50vw',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '100vh',
    isLaptop: '100vh',
    isTablet: '100%',
    isMobile: '100%',
  })};
  z-index: 444;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none;
`;

const FormTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: #666666;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 8px;
`;

const CloseButton = styled.div`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
  })};
  margin: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
  })};
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 30px;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '50px',
    isTablet: '30px',
    isMobile: '20px',
  })};
  padding-bottom: 20px;
`;

const Title = styled(PageSubtitle)`
  margin: 0;
`;

const Button = styled(ArrowButton)`
  margin-top: 20px;
`;

export const CvForm = ({title, handleCloseForm, closeFormAndVacancy, setCVOpen, setVacancyOpen, setVacancy}) => {
  const layout = useAppLayout();
  const [apply, setApply] = useState(false);
  const {register, handleSubmit, formState} = useForm();
  const {errors} = formState;

  const refFrom = useRef();
  const vacancyRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (vacancyRef.current && !vacancyRef.current.contains(event.target) && (layout.isDesktop || layout.isLaptop)) {
        setCVOpen(false);
        setVacancyOpen(false);
        setVacancy(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [vacancyRef, setCVOpen, setVacancyOpen, setVacancy, layout]);

  const handleApply = (data, position) => {
    setApply(!apply);

    fetch('https://formsubmit.co/ajax/Dmitry.Varzin@actionengine.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({...data, position}),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  };

  return (
    <Wrapper layout={layout} ref={vacancyRef}>
      {(layout.isDesktop || layout.isLaptop) && (
        <CloseButton layout={layout} onClick={closeFormAndVacancy}>
          <img src="/icons/close.svg" alt="close" />
        </CloseButton>
      )}
      <FormTitle onClick={handleCloseForm}>
        <Icon icon={faArrowLeft} />
        <Title layout={layout}>{title}</Title>
      </FormTitle>
      {apply ? (
        <GreetingsCard isApplyForm />
      ) : (
        <Form onSubmit={handleSubmit((data) => handleApply(data, title))} layout={layout}>
          <SubHeader layout={layout}>Contact us</SubHeader>
          <FormInput
            id={'name'}
            label={'Name'}
            placeholder={'Required'}
            register={register}
            required={true}
            pattern={/[A-Za-z]{3}/}
            errors={errors}
          />
          <FormInput
            id={'email'}
            label={'Email'}
            placeholder={'Required'}
            register={register}
            required={true}
            pattern={/^\S+@\S+\.\S+$/}
            errors={errors}
          />
          <FormInput
            id={'phone'}
            label={'Phone'}
            placeholder={'Required'}
            register={register}
            required={true}
            pattern={/^[+]?[0-9-()]+[0-9]$/}
            errors={errors}
          />
          <FormInput
            id={'link'}
            label={'Link to CV'}
            placeholder={'Required'}
            register={register}
            required={true}
            pattern={/[A-Za-z]{3}/}
            errors={errors}
          />
          <Button text={'Apply for a job'} onClick={() => refFrom.current?.submit()} />
        </Form>
      )}
    </Wrapper>
  );
};

CvForm.propTypes = {
  title: PropTypes.string,
  handleCloseForm: PropTypes.func.isRequired,
  closeFormAndVacancy: PropTypes.func,
  setCVOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  setVacancy: PropTypes.func,
  setVacancyOpen: PropTypes.func,
};
