import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';

const TEXT_CENTER_LEFT = 'centerLeft';
const TEXT_BOTTOM_LEFT = 'bottomLeft';

const Square = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  color: ${(props) => props.textColor};
  background: ${(props) => props.fillColor};
`;

const TextWrapper = styled.span`
  height: 100%;
  display: flex;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* identical to box height, or 49px */
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '35px',
    isLaptop: '35px',
    isTablet: '35px',
    isMobile: '17px',
  })};
`;

const LeftCenteredText = styled(TextWrapper)`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const BottomLeftText = styled(TextWrapper)`
  align-items: flex-end;
  margin-left: 15px;
  padding-bottom: 14px;
`;

const propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.oneOf(['white', 'black']),
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  textPositioning: PropTypes.oneOf([TEXT_CENTER_LEFT, TEXT_BOTTOM_LEFT]),
};

const getTextBlock = (textPositioning) => {
  switch (textPositioning) {
    case TEXT_CENTER_LEFT:
      return LeftCenteredText;
    case TEXT_BOTTOM_LEFT:
      return BottomLeftText;
    default:
      return BottomLeftText;
  }
};

const ColoredRectangle = ({text, textColor, fillColor, width, height, textPositioning}) => {
  const layout = useAppLayout();
  const TextBlock = getTextBlock(textPositioning);

  return (
    <Square textColor={textColor} fillColor={fillColor} width={width} height={height}>
      <TextBlock layout={layout}>{text}</TextBlock>
    </Square>
  );
};

ColoredRectangle.propTypes = propTypes;

export default ColoredRectangle;
