import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';

export const TextBlockWrapper = styled.div`
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '808px',
    isTablet: '584px',
    isMobile: '460px',
  })};
`;
