import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {ANIMATE_FROM_BOTTOM} from '../../constants/common';
import {TITLE, CONTENT} from '../../constants/sectionFour';
import {TextBlockWrapper as TextWrapper} from '../common';
import {Header} from '../text';

const TextBlockWrapper = styled(TextWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '92px 0',
    isLaptop: '92px 0',
    isTablet: '92px 0',
    isMobile: '40px 0',
  })};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
  margin-left: auto;
  margin-right: auto;
`;

const SectionHeader = styled(Header)`
  color: #f4e11b;
`;

const TextBlock = () => {
  const layout = useAppLayout();

  return (
    <TextBlockWrapper layout={layout}>
      <SectionHeader layout={layout}>{TITLE}</SectionHeader>
    </TextBlockWrapper>
  );
};

export default TextBlock;
