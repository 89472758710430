import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import BottomSheet from '../../../../components/bottomSheet';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {foundersList} from '../../constants/sectionFour';
import MemberPopup from '../member-popup/member-popup';
import TeamMember from './team-member';

const FoundersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0',
    isMobile: '0',
  })};
  margin-bottom: 80px;
  flex-flow: row wrap;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'fit-content',
    isLaptop: 'fit-content',
    isTablet: 'calc(100vw - 86px)',
    isMobile: 'calc(100vw - 32px)',
  })};
`;

export const Founders = () => {
  const layout = useAppLayout();
  const [founder, setFounder] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(true);
  const [enablePrevButton, setEnablePrevButton] = useState(true);

  useEffect(() => {
    setEnableNextButton(founder?.id !== foundersList.length - 1);
    setEnablePrevButton(founder?.id !== foundersList[0].id);
  }, [founder]);

  const onFounderClick = useCallback((el) => {
    setOpen(true);
    setFounder(el);
  }, []);

  const handleCloseFounder = useCallback(() => {
    setOpen(false);
    setFounder(null);
  }, []);

  const getNextFounder = () => {
    setFounder(foundersList[founder.id + 1]);
  };

  const getPrevFounder = () => {
    setFounder(foundersList[founder.id - 1]);
  };

  const closeCard = () => setOpen(false);

  return (
    <>
      <FoundersWrapper layout={layout}>
        {foundersList.map((el, index) => (
          <TeamMember {...el} key={`founder-${index}`} onClick={() => onFounderClick(el)} founder />
        ))}
      </FoundersWrapper>
      {(layout.isDesktop || layout.isLaptop) && isOpen && (
        <MemberPopup
          {...founder}
          getNextMember={getNextFounder}
          getPrevMember={getPrevFounder}
          enableNextButton={enableNextButton}
          enablePrevButton={enablePrevButton}
          closeCard={closeCard}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      )}
      {(layout.isTablet || layout.isMobile) && (
        <BottomSheet
          isOpen={isOpen}
          onClose={closeCard}
          member
          getNextMember={getNextFounder}
          getPrevMember={getPrevFounder}
          enableNextButton={enableNextButton}
          enablePrevButton={enablePrevButton}
        >
          <MemberPopup
            {...founder}
            getNextMember={getNextFounder}
            getPrevMember={getPrevFounder}
            enableNextButton={enableNextButton}
            enablePrevButton={enablePrevButton}
            closeCard={handleCloseFounder}
          />
        </BottomSheet>
      )}
    </>
  );
};
