import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';

import {Header, SectionWrapper, SectionContent, YellowText} from '../../../../components/common';
import ReviewerCard from './reviewer-card';

const MARK_PICTURE = {
  isDesktop: '/pages/work/mark-desktop.webp',
  isLaptop: '/pages/work/mark-laptop.webp',
  isTablet: '/pages/work/mark-tablet.webp',
  isMobile: '/pages/work/mark-mobile.webp',
};

const IB_PICTURE = {
  isDesktop: '/pages/work/Ib-desktop.webp',
  isLaptop: '/pages/work/Ib-laptop.webp',
  isTablet: '/pages/work/Ib-tablet.webp',
  isMobile: '/pages/work/Ib-mobile.webp',
};

const DAN_PICTURE = {
  isDesktop: '/pages/work/dan-desktop.webp',
  isLaptop: '/pages/work/dan-laptop.webp',
  isTablet: '/pages/work/dan-tablet.webp',
  isMobile: '/pages/work/dan-mobile.webp',
};

const REVIEWERS = [
  {
    name: 'Mark Kronenberg',
    position: 'Automated Retail Associates',
    text: 'Action Engine gets the job done well and with excellent quality. They are flexible in how they approach software development and able to meet our changing business and technical needs every step of the way.',
    image: MARK_PICTURE,
  },
  {
    name: 'Ib Green',
    position: 'Foursquare',
    text: 'Action Engine has spent years building up unmatched deep knowledge of the open source web based geospatial technology stacks we are building on, and they have remained a very reliable development partner for Foursquare, always delivering on their commitments regardless of any complications that arise during the projects.',
    image: IB_PICTURE,
  },
  {
    name: 'Dan Kitay',
    position: 'Fireproof',
    text: 'It has been a very positive experience working with Action Engine. The team is very easy to work with, provide great ideas, and deliver on-time. The final version met all specifications and I was very pleased with the results.',
    image: DAN_PICTURE,
  },
];

const ReviewersSectionWrapper = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '0 92px',
    isMobile: '0 24px',
  })};
`;

const CenteredHeader = styled(Header)`
  text-align: center;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '100px auto 80px auto',
    isLaptop: '60px auto 80px auto',
    isTablet: '20px auto 80px auto',
    isMobile: '40px auto 40px auto',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '40%',
    isLaptop: '70%',
    isTablet: '584px',
    isMobile: '328px',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '29px',
  })};
`;

const GridContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 30px;

  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
`;

const SectionOne = () => {
  const layout = useAppLayout();

  return (
    <ReviewersSectionWrapper layout={layout}>
      <SectionContent layout={layout}>
        <CenteredHeader layout={layout}>
          What our <YellowText>key clients</YellowText>
          tell about working with us
        </CenteredHeader>
        <GridContent layout={layout}>
          {REVIEWERS.map(({name, position, text, image}) => (
            <ReviewerCard
              key={name}
              name={name}
              position={position}
              text={text}
              image={getCurrentLayoutProperty(image)({layout})}
            />
          ))}
        </GridContent>
      </SectionContent>
    </ReviewersSectionWrapper>
  );
};

export default SectionOne;
