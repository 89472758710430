import React from 'react';
import styled from 'styled-components';

import {Header} from '../../../../components/common';
import {Logo} from '../../../../components/logo';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {TITLE} from '../../constants/sectionOne';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '160px 0 60px 0',
    isLaptop: '160px 0 0 0',
    isTablet: '160px 92px 80px 92px',
    isMobile: '88px 30vw 16px 16px',
  })};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '50px',
    isTablet: '40px',
    isMobile: 'none',
  })};
`;

const PageHeader = () => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <Logo />
      <Header layout={layout}>{TITLE}</Header>
    </Wrapper>
  );
};

export default PageHeader;
