import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {ANIMATE_FROM_BOTTOM} from '../../constants/common';
import {TITLE} from '../../constants/sectionSix';
import {Header} from '../text';
import ContributorsGrid from './ContributorsGrid';

const SectionHeader = styled(Header)`
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '808px',
    isTablet: '584px',
    isMobile: 'auto',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 52px',
    isLaptop: '0 52px',
    isTablet: '0',
    isMobile: '0 16px',
  })};
`;

const TextBlock = () => {
  const layout = useAppLayout();

  return (
    <>
      <SectionHeader layout={layout}>{TITLE}</SectionHeader>
      <ContributorsGrid />
    </>
  );
};

export default TextBlock;
