import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../utils/common';

export const SVGAnimation = styled.img((props) => ({
  background: props.background,
}));

export const Picture = (imageUrl) => styled.div`
  background: url(${imageUrl}) center;
  background-size: cover;
`;

export const CarouselWrapper = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 258px)',
    isLaptop: 'calc(100vw - 210px)',
    isTablet: 'calc(100vw - 94px)',
    isMobile: 'calc(100vw - 18px)',
  })};
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const SectionWrapper = styled.div`
  min-height: auto;
  background-image: url(${(props) => props?.backgroundUrl});
  background-size: cover;
  background-position-x: 0;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '0 92px',
    isMobile: '0',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '240px',
    isLaptop: '160px',
    isTablet: '120px',
    isMobile: '80px',
  })};
`;

export const SectionContent = styled.div`
  max-width: 1925px;
  margin: auto;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AEBackgroundWrapper = styled.div`
  background: ${(props) =>
    props.isWebGL
      ? 'linear-gradient(to bottom right, rgba(17, 234, 163, 0.2), rgba(254, 236, 52, 0.2)'
      : 'transparent'});
  background-blend-mode: color;
  height: ${getCurrentLayoutProperty({
    isDesktop: '1100px',
    isLaptop: '1000px',
    isTablet: '1180px',
    isMobile: '750px',
  })};
`;

export const Header = styled.h1`
  font-style: normal;
  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora ExtraBold',
    isLaptop: 'Kontora ExtraBold',
    isTablet: 'Kontora ExtraBold',
    isMobile: 'Kontora Medium',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: 800,
    isLaptop: 800,
    isTablet: 800,
    isMobile: 700,
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '103px',
    isLaptop: '72px',
    isTablet: '72px',
    isMobile: '42px',
  })};
  line-height: 120%;
  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: '-0.02em',
    isLaptop: '-0.01em',
    isTablet: '-0.01em',
    isMobile: '-0.01em',
  })};
  margin: ${getCurrentLayoutProperty({
    isDesktop: '35px 0',
    isLaptop: '30px 0',
    isTablet: '20px 0',
    isMobile: '30px 0',
  })};
  width: 100%;
`;

export const HeaderMiddle = styled.h2`
  font-style: normal;
  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora ExtraBold',
    isLaptop: 'Kontora Medium',
    isTablet: 'Kontora Medium',
    isMobile: 'Kontora Medium',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: 800,
    isLaptop: 'bold',
    isTablet: 'bold',
    isMobile: 'bold',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '35px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '120%',
    isLaptop: '120%',
    isTablet: '133%',
    isMobile: '140%',
  })};
  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: '-0.02em',
    isLaptop: '-0.01em',
    isTablet: '-0.01em',
    isMobile: '-0.01em',
  })};
  color: ${(props) => props?.color || 'inherit'};
  width: 70%;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '35px 0',
    isLaptop: '30px 0',
    isTablet: '20px 0',
    isMobile: '30px 0',
  })};
`;

export const HeaderSmall = styled.h2`
  font-style: normal;
  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora ExtraBold',
    isLaptop: 'Kontora ExtraBold',
    isTablet: 'Kontora ExtraBold',
    isMobile: 'Kontora ExtraBold',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: 800,
    isLaptop: 'bold',
    isTablet: 'bold',
    isMobile: 'bold',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '140%',
    isLaptop: '140%',
    isTablet: '140%',
    isMobile: '120%',
  })};
  letter-spacing: 0.01em;
  width: ${getCurrentLayoutProperty({
    isDesktop: '70%',
    isLaptop: '48%',
    isTablet: '48%',
    isMobile: '50%',
  })};
  color: ${(props) => props?.color || 'inherit'};
  margin: 20px 0;
`;

export const SubHeader = styled.h3`
  font-style: normal;
  font-family: ${getCurrentLayoutProperty({
    isTablet: 'Kontora ExtraBold',
    isMobile: 'Kontora Medium',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isTablet: 700,
    isMobile: 'bold',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '42px',
    isTablet: '50px',
    isMobile: '35px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isTablet: '133%',
    isMobile: '140%',
  })};
  letter-spacing: ${getCurrentLayoutProperty({
    isTablet: '-0.01em',
    isMobile: 'inherit',
  })};
  margin: 0px;
`;

export const SubHeaderSmall = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0px;
`;

export const PageSubtitle = styled.h4`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  color: #666666;
  letter-spacing: 0.01em;
  display: ${getCurrentLayoutProperty({
    isDesktop: 'flex',
    isLaptop: 'flex',
    isTablet: 'flex',
    isMobile: 'flex',
  })};
  margin-bottom: 0;
`;

export const BoldText = styled.p`
  font-style: normal;
  font-family: 'Kontora SemBd';
  font-weight: 600;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '150%',
    isLaptop: '150%',
    isTablet: '150%',
    isMobile: '140%',
  })};
  letter-spacing: -0.01em;
  margin: 20px 0;
`;

export const Text = styled.p`
  font-style: normal;
  font-family: 'Kontora Medium';
  font-weight: 400;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '150%',
    isLaptop: '150%',
    isTablet: '150%',
    isMobile: '140%',
  })};
  letter-spacing: -0.01em;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '20px 0',
    isLaptop: '20px 0',
    isTablet: '0',
    isMobile: '0',
  })};
`;

export const YellowText = styled.p`
  display: inline;
  width: fit-content;
  margin: 0;
  background-color: #f4e11b;
  margin: 0 10px;
  padding: 10px 10px 10px 5px;
`;
