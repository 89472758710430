import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {ArrowButton} from '../../../../components/arrowButton';
import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {SectionWrapper, AEBackgroundWrapper} from '../../../../components/common';
import TextBlock from './TextBlock';
import {SCROLL_TEXT} from '../../constants/sectionOne';
import Planet from '../planet';
import {VideoBackground} from '../../../../components/planet-video';

const Section = styled(SectionWrapper)`
  margin-bottom: 0;
  position: relative;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '130px 0',
    isLaptop: '120px 60px',
    isTablet: '160px 92px',
    isMobile: '88px 16px 40px 16px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100% - 184px)',
    isLaptop: 'unset',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  max-width: 1925px;
  margin: auto;
`;

const TextContent = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '36vw',
    isLaptop: '40vw',
    isTablet: '100vw',
    isMobile: '90vw',
  })};
`;

const SectionOne = ({isWebGL}) => {
  const layout = useAppLayout();

  return (
    <AEBackgroundWrapper layout={layout} isWebGL={isWebGL}>
      <Section layout={layout}>
        {isWebGL ? <Planet /> : <VideoBackground />}
        <TextContent layout={layout}>
          <TextBlock />
          <ArrowButton text={SCROLL_TEXT} iconType={'bottom'} pointer />
        </TextContent>
      </Section>
    </AEBackgroundWrapper>
  );
};

SectionOne.propTypes = {
  isWebGL: PropTypes.bool,
};

export default SectionOne;
