import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(1925px / 4 - 72px);
  max-height: calc(1925px / 4 - 72px);
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(25vw - 100px)',
    isLaptop: 'calc(25vw - 62px)',
    isTablet: 'calc(50vw - 82px)',
    isMobile: 'calc(50vw - 30px)',
  })};
  margin: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: '60px auto',
      isLaptop: '15px auto',
      isTablet: props.founder ? '20px auto' : '40px auto',
      isMobile: props.founder ? '8px auto' : '16px auto',
    })};
  height: ${getCurrentLayoutProperty({
    isDesktop: 'calc(25vw - 100px)',
    isLaptop: 'calc(25vw - 62px)',
    isTablet: 'calc(50vw - 82px)',
    isMobile: 'calc(50vw - 30px)',
  })};

  cursor: ${(props) => (props.preview ? 'inherit' : 'pointer')};
  background: ${(props) => (props.preview ? 'none' : '#f7f7f7')};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '6px',
    isLaptop: '6px',
    isTablet: '6px',
    isMobile: '0',
  })};
  :hover {
    > span {
      text-decoration: ${(props) => (props.preview ? 'none' : 'underline')};
    }
    > :last-child {
      text-decoration: ${(props) => (props.preview ? 'none' : 'underline')};
      text-decoration-color: ${(props) => (props.preview ? 'none' : '#666666')};
    }
    > :first-child {
      box-shadow: ${(props) => (props.preview ? 'none' : '10px 15px 25px rgba(0, 0, 0, 0.1)')};
    }
  }
`;

export const AvatarWrapper = styled.div`
  width: ${(props) => (props.preview ? '185px' : '50%')};
  height: ${(props) => (props.preview ? '185px' : '50%')};
  overflow: hidden;
  border-radius: 100px;
  background: ${(props) =>
    !props.clicked
      ? `url(${props.photo}) center`
      : `linear-gradient(
      rgba(0, 0, 0, 0.45), 
      rgba(0, 0, 0, 0.45)), url(${props.photo}) center`};
  background-size: cover;
`;

export const Name = styled.span`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  font-weight: 700;
  color: #010101;
  text-align: center;
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '140%',
    isLaptop: '140%',
    isTablet: '140%',
    isMobile: '130%',
  })};
  margin-top: 15px;
  width: 100%;
`;

export const Position = styled.span`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '20px',
    isMobile: '13px',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '700',
    isLaptop: '700',
    isTablet: '600',
    isMobile: '600',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '140%',
    isLaptop: '140%',
    isTablet: '140%',
    isMobile: '130%',
  })};
  color: #666666;
  text-align: center;
`;

const TeamMember = ({id, photo, name, position, onClick, preview, founder}) => {
  const layout = useAppLayout();
  const [clicked, setClicked] = useState(false);

  return (
    <Wrapper
      key={id}
      onMouseDown={() => !preview && setClicked(true)}
      onMouseLeave={() => !preview && setClicked(false)}
      layout={layout}
      onClick={onClick}
      preview={preview}
      founder={founder}
    >
      <AvatarWrapper layout={layout} photo={photo} clicked={clicked} />
      <Name layout={layout}>{name}</Name>
      <Position layout={layout}>{position}</Position>
    </Wrapper>
  );
};

export default TeamMember;

TeamMember.propTypes = {
  id: PropTypes.number,
  photo: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
  preview: PropTypes.bool,
  founder: PropTypes.bool,
};
