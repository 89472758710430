import React from 'react';
import styled from 'styled-components';

import ColoredRectangle from './ColoredRectangle';

const DESKTOP_HEIGHTS = {
  top: 164,
  bottom: 452,
  right: 1312,
};

const rectanglesConfig = {
  left: {
    top: [
      {
        text: 'Strong Black',
        fillColor: '#1A1A1A',
        textColor: 'white',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Dark Grey',
        fillColor: '#9192A1',
        textColor: 'white',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Medium Grey',
        fillColor: '#C2C2D5',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Light Grey',
        fillColor: '#E4E9FB',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Background White',
        fillColor: '#F3F5F9',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
    ],
    bottom: [
      {text: 'Red Color Coding', fillColor: '#EB4C55', textColor: 'white'},
      {text: 'Green Color Coding', fillColor: '#1AA053', textColor: 'white'},
    ],
  },
  right: [{text: 'Brand Blue', fillColor: '#2831F4', textColor: 'white'}],
};

const DesktopRectanglesWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 160px;
  gap: 40px;
`;

const RectanglesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
`;

const RectanglesGapWrapperHorizontal = styled(RectanglesWrapper)`
  flex-flow: row;
  width: 100%;
  gap: 40px;
  margin-top: 40px;
`;

const DesktopRectanglesLayout = () => {
  const renderLeftTopRectangles = () =>
    rectanglesConfig.left.top.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={DESKTOP_HEIGHTS.top}
        textPositioning={rectangle.textPositioning}
      />
    ));

  const renderLeftBottomRectangles = () =>
    rectanglesConfig.left.bottom.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={DESKTOP_HEIGHTS.bottom}
        textPositioning={rectangle.textPositioning}
      />
    ));

  const renderRightRectangle = () =>
    rectanglesConfig.right.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={DESKTOP_HEIGHTS.right}
        textPositioning={rectangle.textPositioning}
      />
    ));

  return (
    <DesktopRectanglesWrapper>
      <RectanglesWrapper>
        {renderLeftTopRectangles()}
        <RectanglesGapWrapperHorizontal>{renderLeftBottomRectangles()}</RectanglesGapWrapperHorizontal>
      </RectanglesWrapper>
      <RectanglesWrapper>{renderRightRectangle()}</RectanglesWrapper>
    </DesktopRectanglesWrapper>
  );
};

export default DesktopRectanglesLayout;
