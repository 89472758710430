import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAppLayout from '../../utils/useAppLayout';

const MobileBurgerWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s all;
  background-color: ${(props) => (props.open ? 'transparent' : props.theme.colors.red)};
  z-index: 999;
  cursor: pointer;
  :hover {
    background-color: ${(props) => (props.open ? 'transparent' : '#db1c1c')};
  }
`;

const TabletBurgerWrapper = styled(MobileBurgerWrapper)`
  width: 80px;
  height: 80px;
`;

const LaptopBurgerWrapper = styled(TabletBurgerWrapper)`
  background-color: transparent;
  :hover {
    background-color: ${(props) => (props.open ? 'transparent' : '#333333')};
  }
`;

// TODO fix burger lines in case of !open
const MobileStyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none !important;
  padding: 0;
  cursor: pointer;

  > span {
    width: 16px;
    height: 1.33px;
    background: ${(props) => props.navColor};
    border-radius: 10px;
    transition: all 0.4s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({open}) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      transform: ${({open}) => (open ? 'translateX(20px)' : 'translateX(0)')};
      opacity: ${({open}) => (open ? '0' : '1')};
    }

    :nth-child(3) {
      transform: ${({open}) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const TabletStyledBurger = styled(MobileStyledBurger)`
  width: 24px;
  height: 24px;

  span {
    width: 24px;
    height: 2px;
  }
`;

const getBurgerView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        BurgerWrapper: LaptopBurgerWrapper,
        StyledBurger: TabletStyledBurger,
      };
    case layout.isTablet:
      return {
        BurgerWrapper: TabletBurgerWrapper,
        StyledBurger: TabletStyledBurger,
      };
    case layout.isMobile:
      return {
        BurgerWrapper: MobileBurgerWrapper,
        StyledBurger: MobileStyledBurger,
      };
    default:
      return {
        BurgerWrapper: () => null,
        StyledBurger: () => null,
      };
  }
};

const BurgerView = ({open, navColor, onClick}) => {
  const layout = useAppLayout();
  const Burger = useMemo(() => getBurgerView(layout), [layout]);

  return (
    <Burger.BurgerWrapper open={open} onClick={onClick}>
      <Burger.StyledBurger open={open} navColor={navColor} aria-label="nav button">
        <span />
        <span />
        <span />
      </Burger.StyledBurger>
    </Burger.BurgerWrapper>
  );
};

BurgerView.propTypes = {
  open: PropTypes.bool.isRequired,
  navColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default BurgerView;
