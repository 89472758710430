import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import TextBlock from './TextBlock';

const Section = styled(SectionWrapper)`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '100px',
    isLaptop: '100px',
    isTablet: '60px',
    isMobile: '45px',
  })};
  padding: 8px;
`;

const SectionFour = () => {
  const layout = useAppLayout();

  return (
    <Section layout={layout} backgroundUrl={'/pages/home/wireframe-map.webp'}>
      <SectionContent layout={layout}>
        <TextBlock />
      </SectionContent>
    </Section>
  );
};

export default SectionFour;
