import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import {BottomSheet as SpringBottomSheet} from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import {MemberControls} from '../pages/inside/components/member-popup/member-controls';
import {VacancyControls} from '../pages/inside/components/vacancy-popup/vacancy-controls';

const StyledBottomSheet = styled(SpringBottomSheet)`
  div[data-rsbs-scroll='true'] {
    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    scrollbar-width: none;
  }
`;

const BottomSheet = ({
  isOpen,
  onClose,
  children,
  getPrevMember,
  getNextMember,
  enablePrevButton,
  enableNextButton,
  member,
  openForm,
  vacancy,
}) => {
  const focusRef = useRef();
  const sheetRef = useRef();

  return (
    <StyledBottomSheet
      open={isOpen}
      onDismiss={onClose}
      expandOnContentDrag={true}
      skipInitialTransition
      defaultSnap={({maxHeight}) => maxHeight * 1}
      snapPoints={({maxHeight}) => [maxHeight * 1, maxHeight * 1]}
      ref={sheetRef}
      initialFocusRef={focusRef}
      footer={
        member ? (
          <MemberControls
            getNextMember={getNextMember}
            getPrevMember={getPrevMember}
            enableNextButton={enableNextButton}
            enablePrevButton={enablePrevButton}
          />
        ) : vacancy ? (
          <VacancyControls openForm={openForm} />
        ) : null
      }
    >
      {children}
    </StyledBottomSheet>
  );
};

export default BottomSheet;

BottomSheet.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  getNextMember: PropTypes.func,
  getPrevMember: PropTypes.func,
  enableNextButton: PropTypes.bool,
  enablePrevButton: PropTypes.bool,
  member: PropTypes.bool,
  openForm: PropTypes.func,
  vacancy: PropTypes.bool,
};
