import {css} from 'styled-components';

export const Glow = ({x, y, spread, size, colorA, colorB, duration, radius}) => css`{
  &:after {
    position: absolute;
    content: "";
    top: ${y};
    left: ${x};
    right: 0;
    border-radius: ${radius};
    height: 100%;
    width: 100%;
    z-index: -1;
    margin: 0 auto;
    transform: scale(${size});
    -webkit-filter: blur(${spread});
    -moz-filter: blur(${spread});
    -ms-filter: blur(${spread});
    filter: blur(${spread});
    background: radial-gradient(${colorA}, ${colorB}, ${colorA});
    background-size: 200% 200%;
    animation: animateGlow ${duration} ease infinite;
    
    @keyframes animateGlow {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
    }
  }
  `;
