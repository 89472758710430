import React from 'react';
import styled from 'styled-components';
import BulletItem from '../../../components/bullet-item';

import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import TextBlock from '../../case-studies/components/TextBlock';

const technologyFirstImage = {
  isDesktop: '/capabilities/technologyFirstImageDesktop.svg',
  isLaptop: '/capabilities/technologyFirstImageLaptop.svg',
  isTablet: '/capabilities/technologyFirstImageTablet.svg',
  isMobile: '/capabilities/technologyFirstImageMobile.svg',
};

const technologyBullets1 = [
  {
    text: 'Building, running, and monitoring processing and analytical pipelines at scale',
  },
  {
    text: 'Downloading, storing, and processing terabytes of satellite images',
  },
  {
    text: 'Enabling quick online search and discovery of geospatial assets',
  },
];

const technologyBullets2 = [
  {
    text: 'Constructing complex visualizations on top of existing layers',
  },
  {
    text: 'Packaging and sharing new visualizations as reusable layers',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '160px',
    isTablet: '80px',
    isMobile: '40px',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '120px',
    isMobile: '80px',
  })};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  max-width: 1925px;
`;

const BulletsContainer = styled.div`
  display: grid;

  column-gap: 52px;

  row-gap: ${getCurrentLayoutProperty({
    isDesktop: '44px',
    isLaptop: '44px',
    isTablet: '60px',
    isMobile: '40px',
  })};

  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '0.45fr 0.45fr',
    isLaptop: '0.45fr 0.45fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};

  width: 100%;

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '74px',
    isLaptop: '74px',
    isTablet: '50px',
    isMobile: '24px',
  })};
`;

const BulletsWrapper = styled.ul`
  display: grid;
  row-gap: ${getCurrentLayoutProperty({
    isDesktop: '44px',
    isLaptop: '44px',
    isTablet: '60px',
    isMobile: '40px',
  })};
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '0.95fr',
    isLaptop: '0.95fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};
  grid-template-rows: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr 1fr',
    isLaptop: '1fr 1fr 1fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};
  width: 100%;
  padding: 0;
  margin: 0;
`;

const SecondBulletsWrapper = styled(BulletsWrapper)`
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '0.7fr',
    isLaptop: '0.7fr',
    isTablet: '1fr',
    isMobile: '0.9fr',
  })};
`;

const TechnologiesImage = styled.img`
  object-fit: contain;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '90px',
    isLaptop: '90px',
    isTablet: '90px',
    isMobile: '40px',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  max-width: 1925px;
`;

const headerFontSizes = {
  isDesktop: '86px',
  isLaptop: '50px',
  isTablet: '50px',
  isMobile: '29px',
};

const textFontSizes = {
  isDesktop: '35px',
  isLaptop: '24px',
  isTablet: '24px',
  isMobile: '17px',
};

const textFontFamilies = {
  isDesktop: 'Kontora Medium',
  isLaptop: 'Kontora SemBd',
  isTablet: 'Kontora SemBd',
  isMobile: 'Kontora SemBd',
};

const TechnologyFirst = () => {
  const layout = useAppLayout();

  return (
    <Container layout={layout}>
      <ContentWrapper layout={layout}>
        <TextBlock
          headerText="Technology-First, Design-Led Approach"
          mainText={`
            AE has extensive experience in geospatial technologies.
            We utilize a modern technological stack and build high efficiency pipelines.
            This allows us to create fully custom and very effective geospatial solutions,
            one of which is our recent deforestation tracking app.`}
          color="black"
          headerColor="black"
          mainTextOpacity={'1'}
          headerFontSize={getCurrentLayoutProperty(headerFontSizes)({layout})}
          textFontSize={getCurrentLayoutProperty(textFontSizes)({layout})}
          textFontFamily={getCurrentLayoutProperty(textFontFamilies)({layout})}
          style={{padding: 0}}
        />
        <BulletsContainer layout={layout}>
          <BulletsWrapper layout={layout}>
            {technologyBullets1.map((item) => (
              <BulletItem key={item.text} text={item.text} />
            ))}
          </BulletsWrapper>
          <SecondBulletsWrapper layout={layout}>
            {technologyBullets2.map((item) => (
              <BulletItem key={item.text} text={item.text} />
            ))}
          </SecondBulletsWrapper>
        </BulletsContainer>
      </ContentWrapper>
      <TechnologiesImage
        layout={layout}
        src={getCurrentLayoutProperty(technologyFirstImage)({layout})}
        alt="Action Engine Geospatial solutions"
      />
    </Container>
  );
};

export default TechnologyFirst;
