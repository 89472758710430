import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../../../utils/common';

export const Header = styled.h1`
  font-style: normal;
  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora ExtraBold',
    isLaptop: 'Kontora ExtraBold',
    isTablet: 'Kontora ExtraBold',
    isMobile: 'Kontora Medium', // Kontora ??
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: 800,
    isLaptop: 800,
    isTablet: 800,
    isMobile: 'bold',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '29px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '120%',
    isLaptop: '133%',
    isTablet: '133%',
    isMobile: '140%',
  })};
  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: '-0.02em',
    isLaptop: '-0.01em',
    isTablet: '-0.01em',
    isMobile: 'unset',
  })};
  margin: 0px;
`;

export const Text = styled.p`
  font-style: normal;
  font-family: 'Kontora SemBd';
  font-weight: 600;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '150%',
    isLaptop: '150%',
    isTablet: '150%',
    isMobile: '140%',
  })};
  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: 'unset',
    isMobile: '0.01em',
  })};
  margin: 0px;
`;
