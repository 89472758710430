import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {FormInput} from '../form-input';

const DesktopFormWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 32px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
`;

export const DesktopForm = ({register, errors}) => {
  return (
    <DesktopFormWrapper>
      <Column>
        <FormInput
          id={'name'}
          label={'Name'}
          placeholder={'Required'}
          register={register}
          required={true}
          pattern={/[A-Za-z]{3}/}
          errors={errors}
        />
        <FormInput
          id={'company'}
          label={'Company'}
          placeholder={'Required'}
          required={true}
          register={register}
          pattern={/[A-Za-z]{3}/}
          errors={errors}
        />
      </Column>
      <Column>
        <FormInput
          id={'email'}
          label={'Email'}
          placeholder={'Required'}
          required={true}
          register={register}
          pattern={/^\S+@\S+\.\S+$/}
          errors={errors}
        />
        <FormInput
          id={'phone'}
          label={'Phone'}
          placeholder={'Optional'}
          register={register}
          pattern={/^[+]?[0-9-()]+[0-9]$/}
          required={false}
          errors={errors}
        />
      </Column>
    </DesktopFormWrapper>
  );
};

DesktopForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
};
