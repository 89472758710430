import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../../utils/common';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: 0,
    isMobile: 0,
  })};
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '80px',
    isMobile: '48px',
  })};
`;
