import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {Header} from '../text';

const ImageBlockWrapper = styled.div`
  height: ${getCurrentLayoutProperty({
    isDesktop: '1035px',
    isLaptop: '680px',
    isTablet: '680px',
    isMobile: '460px',
  })};
  background: url(${(props) => props.image}) ${(props) => props.backgroundColor} no-repeat;
  background-size: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.centered ? '650px' : '50%',
      isLaptop: props.centered ? '400px' : '50%',
      isTablet: props.centered ? '450px' : '550px',
      isMobile: props.centered ? '320px' : '355px',
    })};
  background-position: ${(props) =>
    props.centered
      ? 'bottom 0 center'
      : getCurrentLayoutProperty({
          isDesktop: 'bottom 0 right',
          isLaptop: 'bottom 0 right',
          isTablet: 'bottom 0 right -210px',
          isMobile: 'bottom 0 right -70px',
        })};
`;

const Title = styled(Header)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 0 80px 80px',
    isLaptop: '40px 0 40px 40px',
    isTablet: '40px 0 40px 40px',
    isMobile: '24px 0 15px 24px',
  })};

  width: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.centered ? '65%' : '40%',
      isLaptop: props.centered ? '50%' : '70%',
      isTablet: '90%',
      isMobile: '90%',
    })};

  color: ${(props) => props.textColor};
`;

const Description = styled.div`
  font-family: 'Kontora SemBd';
  font-style: normal;
  font-weight: 600;
  font-feature-settings: 'ss01' on;
  color: ${(props) => props.textColor};

  width: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.centered ? '90%' : '55%',
      isLaptop: props.centered ? '90%' : '50%',
      isTablet: '90%',
      isMobile: '90%',
    })};

  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 0 80px 80px',
    isLaptop: '0 0 40px 40px',
    isTablet: '0 0 40px 40px',
    isMobile: '0 0 24px 24px',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};

  line-height: ${getCurrentLayoutProperty({
    isDesktop: '150%',
    isLaptop: '150%',
    isTablet: '150%',
    isMobile: '140%',
  })};

  color: ${(props) => props.textColor};
`;

const ImageBlock = ({title, description, image, textColor = 'white', backgroundColor, centered}) => {
  const layout = useAppLayout();

  return (
    <ImageBlockWrapper layout={layout} image={image} backgroundColor={backgroundColor} centered={centered}>
      <Title centered={centered} textColor={textColor} layout={layout}>
        {title}
      </Title>
      <Description centered={centered} textColor={textColor} layout={layout}>
        {description}
      </Description>
    </ImageBlockWrapper>
  );
};

ImageBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  centered: PropTypes.bool,
};

export default ImageBlock;
