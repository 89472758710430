import React, {useState} from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {PageWrapper} from '../../components/layout';

import SectionOne from './components/sectionOne';
import SectionTwo from './components/sectionTwo';
import SectionThree from './components/sectionThree';
import SectionFour from './components/sectionFour';
import SectionFive from './components/sectionFive';
import SectionSix from './components/sectionSix';
import SectionSeven from './components/sectionSeven';
import SectionEight from './components/sectionEight';
import SectionNine from './components/sectionNine';
import Footer from '../../components/footer';
import CreatingOppotunityBlock from './components/creatingOpportunity';

const Page = styled(PageWrapper)`
  position: relative;
  padding-top: 0;
`;

const Home = () => {
  const layout = useAppLayout();
  const [isWebGL, setIsWebGL] = useState(document.createElement('canvas').getContext('webgl2') ? true : false);

  // Enable it when animation will be ready
  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);
  // });

  // useEffect(() => {
  //   gsap.utils.toArray(`.${ANIMATE_FROM_BOTTOM}`).forEach(function (elem) {
  //     hide(elem); // assure that the element is hidden when scrolled into view

  //     ScrollTrigger.create({
  //       trigger: elem,
  //       onEnter: function () {
  //         animateFromTo(elem);
  //       },
  //       onEnterBack: function () {
  //         animateFromTo(elem, -1);
  //       },
  //     });
  //   });
  // }, [layout]);

  return (
    <Page layout={layout}>
      <SectionOne isWebGL={isWebGL} />
      <SectionTwo />
      <SectionThree />
      <CreatingOppotunityBlock />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionEight />
      <SectionSeven />
      <SectionNine headerId={'contact-us'} isWebGL={isWebGL} />
      <Footer skipContactButton />
    </Page>
  );
};

export default Home;
