import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useAppLayout from '../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../utils/common';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faArrowRight, faArrowUp, faArrowDown, faArrowLeft, faCheck} from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRight, faArrowUp, faArrowDown, faArrowLeft, faCheck);

const ARROW_TOP = 'top';
const ARROW_RIGHT = 'right';
const ARROW_BOTTOM = 'bottom';
const ARROW_LEFT = 'left';
const CHECK = 'check';

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.6' : 1)};
  width: ${(props) =>
    props.text
      ? 'fit-content'
      : getCurrentLayoutProperty({
          isDesktop: '64px',
          isLaptop: '64px',
          isTablet: '64px',
          isMobile: '48px',
        })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  white-space: nowrap;
  border: none !important;
  padding: 0;
  
  :hover {
      > span {
        background: ${(props) => props.textColor};
        color: ${(props) => (props.textColor === 'white' ? 'black' : 'white')};
      }
    }
  pointer-events: ${(props) => props.pointer && 'none'};
  }

  span {
    padding: ${getCurrentLayoutProperty({
      isDesktop: '0 32px',
      isLaptop: '0 32px',
      isTablet: '0 32px',
      isMobile: '0 16px',
    })};
    display: inline-block;
    color: ${(props) => props.textColor};
    font-family: 'Kontora ExtraBold';
    font-style: normal;
    font-weight: 800;
    font-size: ${getCurrentLayoutProperty({
      isDesktop: '20px',
      isLaptop: '20px',
      isTablet: '20px',
      isMobile: '17px',
    })};
    line-height: ${getCurrentLayoutProperty({
      isDesktop: '64px',
      isLaptop: '64px',
      isTablet: '64px',
      isMobile: '48px',
    })};
    letter-spacing: 0.02em;
    text-transform: uppercase;
    transition: 0.7s all;
  }
`;

const IconWrapper = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  min-width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.arrowColor};
  background: ${(props) => (props.backgroundColor === 'red' ? '#ff4242' : props.backgroundColor)};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

const icons = {
  [ARROW_TOP]: 'arrow-up',
  [ARROW_RIGHT]: 'arrow-right',
  [ARROW_BOTTOM]: 'arrow-down',
  [ARROW_LEFT]: 'arrow-left',
  [CHECK]: 'check',
};

const getIcon = (layout, iconType) => {
  switch (true) {
    case layout.isDesktop:
      return icons[iconType];
    case layout.isLaptop:
      return icons[iconType];
    case layout.isTablet:
      return icons[iconType];
    case layout.isMobile:
      return icons[iconType];
    default:
      return 'arrow-right';
  }
};

// TODO Customize all possible arrow buttons.
export const ArrowButton = ({
  iconType = ARROW_RIGHT,
  disabled = false,
  text = '',
  textColor = '#000000',
  arrowColor = 'black',
  onClick,
  backgroundColor = '#f4e11b',
  className,
  pointer,
}) => {
  const layout = useAppLayout();
  const icon = getIcon(layout, iconType);

  return (
    <ButtonWrapper
      layout={layout}
      onClick={onClick}
      backgroundColor={backgroundColor}
      textColor={textColor}
      disabled={disabled}
      className={className}
      pointer={pointer}
      text={text}
      aria-label="Arrow button"
    >
      <IconWrapper backgroundColor={backgroundColor} arrowColor={arrowColor} layout={layout}>
        <Icon icon={icon} />
      </IconWrapper>
      {text && <span>{text}</span>}
    </ButtonWrapper>
  );
};

const propTypes = {
  iconType: PropTypes.oneOf([ARROW_TOP, ARROW_RIGHT, ARROW_BOTTOM, ARROW_LEFT, CHECK]),
  text: PropTypes.string,
  disabled: PropTypes.bool,
  textColor: PropTypes.string,
  arrowColor: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  pointer: PropTypes.bool,
};

ArrowButton.propTypes = propTypes;
