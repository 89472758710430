import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const GridWrapper = styled.div`
  display: grid;
  max-width: 1925px;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto;

  grid-column-gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '24px',
  })};

  grid-row-gap: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '24px',
  })};

  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr 1fr',
    isLaptop: '1fr 1fr 1fr',
    isTablet: '1fr 1fr',
    isMobile: '1fr 1fr',
  })};

  padding: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '0 16px',
  })};
`;

const Client = styled.img`
  max-width: 100%;
`;

const ContributorWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: url('/pages/home/logo-contibutor.webp') 100% no-repeat;
    background-size: contain;
    z-index: 1;

    width: ${getCurrentLayoutProperty({
      isDesktop: '120px',
      isLaptop: '120px',
      isTablet: '120px',
      isMobile: '56px',
    })};

    height: ${getCurrentLayoutProperty({
      isDesktop: '36px',
      isLaptop: '36px',
      isTablet: '36px',
      isMobile: '19px',
    })};

    top: ${getCurrentLayoutProperty({
      isDesktop: '-5px',
      isLaptop: '-5px',
      isTablet: '-20px',
      isMobile: '5px',
    })};

    left: ${getCurrentLayoutProperty({
      isDesktop: '75%',
      isLaptop: '75%',
      isTablet: '75%',
      isMobile: '65%',
    })};
  }
`;

const ContributorsGrid = () => {
  const layout = useAppLayout();

  return (
    <GridWrapper layout={layout}>
      <ContributorWrapper layout={layout}>
        <Client src={'/pages/home/logo-loaders.webp'} alt="loaders logo" />
      </ContributorWrapper>
      <ContributorWrapper layout={layout}>
        <Client src={'/pages/home/logo-deck.webp'} alt="deck.gl logo" />
      </ContributorWrapper>
      <ContributorWrapper layout={layout}>
        <Client src={'/pages/home/logo-luma.webp'} alt="luma.gl logo" />
      </ContributorWrapper>
      <ContributorWrapper layout={layout}>
        <Client src={'/pages/home/logo-kepler.webp'} alt="kepler.gl logo" />
      </ContributorWrapper>
      <Client src={'/pages/home/logo-arcgis.webp'} alt="arcgis logo" />
      <Client src={'/pages/home/logo-pytorch.webp'} alt="pytorch logo" />
      <Client src={'/pages/home/logo-tensorflow.webp'} alt="tensorflow logo" />
      <Client src={'/pages/home/logo-prefect.webp'} alt="prefect logo" />
      {(layout.isDesktop || layout.isLaptop) && (
        <Client src={'/pages/home/logo-kubernates.webp'} alt="kubernetes logo" />
      )}
    </GridWrapper>
  );
};

export default ContributorsGrid;
