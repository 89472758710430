import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';

const WholePageContainer = styled.div`
  position: relative;
  color: #ffffff;
  padding: 0px
    ${getCurrentLayoutProperty({
      isDesktop: '0',
      isLaptop: '0',
      isTablet: '92px 40px 92px',
      isMobile: '16px',
    })};
`;

export const Header = styled.div`
  color: ${(props) => props.color};
  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora ExtraBold',
    isLaptop: 'Kontora ExtraBold',
    isTablet: 'Kontora ExtraBold',
    isMobile: 'Kontora Medium',
  })};
  font-style: normal;
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '800',
    isLaptop: '800',
    isTablet: '800',
    isMobile: '700',
  })};
  font-size: ${({headerFontSize}) =>
    headerFontSize ||
    getCurrentLayoutProperty({
      isDesktop: '86px',
      isLaptop: '50px',
      isTablet: '50px',
      isMobile: '35px',
    })};
  line-height: ${({headerLineHeight}) =>
    headerLineHeight ||
    getCurrentLayoutProperty({
      isDesktop: '120%',
      isLaptop: '133%',
      isTablet: '133%',
      isMobile: '140%',
    })};
  margin-bottom: ${(props) =>
    props.headerMarginBottom ||
    getCurrentLayoutProperty({
      isDesktop: '40px',
      isLaptop: '40px',
      isTablet: '40px',
      isMobile: '24px',
    })};
  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: '-0.02em',
    isLaptop: '-0.01em',
    isTablet: '-0.01em',
    isMobile: 'normal',
  })};
`;

export const Text = styled.div`
  color: ${(props) => props.color};
  font-family: ${({textFontFamily}) => textFontFamily || 'Kontora SemBd'};
  font-style: normal;
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '500',
    isLaptop: '600',
    isTablet: '600',
    isMobile: '600',
  })};
  font-size: ${({textFontSize}) =>
    textFontSize ||
    getCurrentLayoutProperty({
      isDesktop: '29px',
      isLaptop: '24px',
      isTablet: '29px',
      isMobile: '17px',
    })};
  line-height: ${({textLineHeight}) =>
    textLineHeight ||
    getCurrentLayoutProperty({
      isDesktop: '140%',
      isLaptop: '150%',
      isTablet: '150%',
      isMobile: '140%',
    })};
  opacity: ${(props) => props.opacity};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '16px',
  })};

  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: 'normal',
    isLaptop: 'normal',
    isTablet: 'normal',
    isMobile: '0.01em',
  })};
`;

const propTypes = {
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  color: PropTypes.string,
  headerColor: PropTypes.string,
  mainTextOpacity: PropTypes.string,
  headerLineHeight: PropTypes.string,
  textLineHeight: PropTypes.string,
  textFontFamily: PropTypes.string,
  headerMarginBottom: PropTypes.string,
  headerFontSize: PropTypes.string,
  textFontSize: PropTypes.string,
  style: PropTypes.object,
};

const TextBlock = ({
  headerText,
  mainText,
  color,
  headerColor,
  mainTextOpacity = 1,
  headerLineHeight,
  textLineHeight,
  textFontFamily,
  headerMarginBottom,
  headerFontSize,
  textFontSize,
  style,
}) => {
  const layout = useAppLayout();
  const mainTextArray = Array.isArray(mainText) ? mainText : [mainText];

  return (
    <WholePageContainer layout={layout} style={style}>
      {headerText && (
        <Header
          color={headerColor ? headerColor : color}
          headerLineHeight={headerLineHeight}
          headerMarginBottom={headerMarginBottom}
          headerFontSize={headerFontSize}
          layout={layout}
        >
          {headerText}
        </Header>
      )}
      {mainText &&
        mainTextArray.map((text, i) => (
          <Text
            color={color}
            textLineHeight={textLineHeight}
            textFontFamily={textFontFamily}
            opacity={mainTextOpacity}
            key={i}
            layout={layout}
            textFontSize={textFontSize}
          >
            {text}
          </Text>
        ))}
    </WholePageContainer>
  );
};

TextBlock.propTypes = propTypes;

export default TextBlock;
