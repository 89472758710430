import React from 'react';
import styled from 'styled-components';
import {Header} from '../../../../components/common';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {PRINCIPLES, TITLE} from '../../constants/sectionTwo';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  margin: auto;
  max-width: 1925px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: 'repeat(4, 1fr)',
    isLaptop: 'repeat(4, 1fr)',
    isTablet: 'repeat(2, 2fr)',
    isMobile: 'repeat(2, 2fr)',
  })};
  grid-column-gap: ${getCurrentLayoutProperty({
    isDesktop: '3vw',
    isLaptop: '2vw',
    isTablet: '92px',
    isMobile: '8px',
  })};
  grid-row-gap: 0;
`;

const PrincipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '21px',
    isMobile: '4px',
  })};
`;

const Princip = styled.div`
  display: flex;
  > img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.label`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '42px',
    isLaptop: '42px',
    isTablet: '42px',
    isMobile: '24px',
  })};
  letter-spacing: 0.01em;
  font-weight: 700;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '40px auto',
    isLaptop: '40px auto',
    isTablet: '40px auto 24px auto',
    isMobile: '40px auto 24px auto',
  })};
`;

const SectionTwo = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <Header layout={layout}>{TITLE}</Header>
      <Wrapper layout={layout}>
        {PRINCIPLES.map((item) => (
          <PrincipWrapper key={item.id}>
            <Title layout={layout}>{item.name}</Title>
            <Princip layout={layout}>
              <img src={item.picture} alt="principle" />
            </Princip>
          </PrincipWrapper>
        ))}
      </Wrapper>
    </SectionWrapper>
  );
};

export default SectionTwo;
