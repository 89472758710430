import React from 'react';
import styled from 'styled-components';

import {HeaderMiddle} from '../../../../components/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {Founders} from '../../components/team/founders';
import {SUBTITLE_1} from '../../constants/team';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(HeaderMiddle)`
  width: 100%;
  text-align: center;
`;

const SectionFour = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <Title layout={layout}>{SUBTITLE_1}</Title>
      <Founders />
    </SectionWrapper>
  );
};

export default SectionFour;
