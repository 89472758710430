import React from 'react';
import styled from 'styled-components';
import HeaderWithAELogo from '../../../../components/header-with-AE-logo';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';

import InfoBlock from '../../../../components/info-block/info-block';
import {HeaderMiddle, SectionWrapper, SectionContent, YellowText} from '../../../../components/common';

const LINK = '/capabilities';
const LINK_TEXT = 'More about our capabilities';

const FIRST_TITLE = 'Geospatial Data Visualization';
const FIRST_TEXT =
  'Using WebGL frameworks including deck.gl, kepler.gl, loaders.gl, and formats like I3S, 3D Tiles, glTF, Draco, Basis';
const FIRST_PICTURE = {
  isDesktop: '/pages/work/triangle-desktop.webp',
  isLaptop: '/pages/work/triangle-laptop.webp',
  isTablet: '/pages/work/triangle-tablet.webp',
  isMobile: '/pages/work/triangle-mobile.webp',
};

const SECOND_TITLE = 'Full-Stack Design and Development Processes';
const SECOND_TEXT =
  'Helping teams and projects with User Experience Design, Frontend, Backend, Mobile, and even No-Code Development, Quality Assurance, Development Operations (DevOps), and Web3 technologies and approaches';
const SECOND_PICTURE = {
  isDesktop: '/pages/work/spanner-desktop.webp',
  isLaptop: '/pages/work/spanner-laptop.webp',
  isTablet: '/pages/work/spanner-tablet.webp',
  isMobile: '/pages/work/spanner-mobile.webp',
};

const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

const Wrapper = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '0',
    isMobile: '0',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '80px',
    isMobile: '40px',
  })};
`;

const CenteredHeader = styled(HeaderMiddle)`
  text-align: ${getCurrentLayoutProperty({
    isDesktop: 'center',
    isLaptop: 'start',
    isTablet: 'center',
    isMobile: 'center',
  })};
  margin: ${getCurrentLayoutProperty({
    isDesktop: '240px auto 0 auto',
    isLaptop: '160px auto 0 auto',
    isTablet: '40px auto 0 auto',
    isMobile: '40px auto 0 auto',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: '45%',
    isLaptop: '95%',
    isTablet: '74%',
    isMobile: '75%',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '29px',
  })};
`;

const OurWorkBlocks = styled(SectionContent)`
  > :first-child {
    margin-bottom: ${getCurrentLayoutProperty({
      isDesktop: '160px',
      isLaptop: '120px',
      isTablet: '40px',
      isMobile: '40px',
    })};
  }
`;

const SectionOne = () => {
  const layout = useAppLayout();

  return (
    <>
      <TitleWrapper layout={layout}>
        <HeaderWithAELogo title="Your partner to co-create value through a design-led approach" />
      </TitleWrapper>
      {/*<Wrapper layout={layout}>*/}
      {/*  <OurWorkBlocks layout={layout}>*/}
      {/*    <InfoBlock*/}
      {/*      title={FIRST_TITLE}*/}
      {/*      text={FIRST_TEXT}*/}
      {/*      backgroundImage={getCurrentLayoutProperty(FIRST_PICTURE)({layout})}*/}
      {/*      arrowLink={LINK}*/}
      {/*      arrowText={LINK_TEXT}*/}
      {/*    />*/}
      {/*    <InfoBlock*/}
      {/*      title={SECOND_TITLE}*/}
      {/*      text={SECOND_TEXT}*/}
      {/*      backgroundImage={getCurrentLayoutProperty(SECOND_PICTURE)({layout})}*/}
      {/*      arrowLink={LINK}*/}
      {/*      arrowText={LINK_TEXT}*/}
      {/*    />*/}
      {/*  </OurWorkBlocks>*/}
      {/*  <CenteredHeader layout={layout}>*/}
      {/*    Our expertize is highlighted across <YellowText>various cases</YellowText>*/}
      {/*    we have completed*/}
      {/*  </CenteredHeader>*/}
      {/*</Wrapper>*/}
    </>
  );
};

export default SectionOne;
