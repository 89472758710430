import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../utils/common';
import useAppLayout from '../utils/useAppLayout';

const Video = styled.video`
  object-fit: cover;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 80px)',
    isLaptop: 'calc(100vw - 80px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: '1000px',
    isTablet: '1180px',
    isMobile: '750px',
  })};
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
`;

const videoWEBM = {
  isDesktop: '/videos/planet-desktop.webm',
  isLaptop: '/videos/planet-desktop.webm',
  isTablet: '/videos/planet-tablet.webm',
  isMobile: '/videos/planet-mobile.webm',
};

const videoMP4 = {
  isDesktop: '/videos/planet-desktop.mp4',
  isLaptop: '/videos/planet-desktop.mp4',
  isTablet: '/videos/planet-tablet.mp4',
  isMobile: '/videos/planet-mobile.mp4',
};

const gif = {
  isDesktop: '/videos/planet-desktop.gif',
  isLaptop: '/videos/planet-desktop.gif',
  isTablet: '/videos/planet-tablet.gif',
  isMobile: '/videos/planet-mobile.gif',
};

export const VideoBackground = () => {
  const layout = useAppLayout();
  const imageRef = useRef();

  // useEffect(() => {
  //   if (imageRef.current) {
  //     console.log(imageRef.current.parentNode);
  //   }
  // }, []);

  // // TODO add replacing with GIF
  // const fallback = (video) => {
  //   var img = video.querySelector('img');
  //   if (img) video.parentNode.replaceChild(img, video);
  // };

  return (
    <Video autoPlay loop muted playsinline layout={layout}>
      <source src={getCurrentLayoutProperty(videoWEBM)({layout})} />
      <source src={getCurrentLayoutProperty(videoMP4)({layout})} />
      {/* <img src={getCurrentLayoutProperty(gif)({layout})} ref={imageRef} /> */}
    </Video>
  );
};
