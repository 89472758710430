import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';

const propTypes = {
  children: PropTypes.array.isRequired,
};

const theme = {
  colors: {
    white: '#ffffff',
    black: '#0A0A0A',
    red: '#DE2B2B',
    coral: '#FF4242',
    lemon: '#F4E11B',
    purple: '#713DF5',
    mint: '#11EAA3',
    gray: '#F7F7F7',
  },
};

export const Theme = ({children}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

Theme.propTypes = propTypes;
