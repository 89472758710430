import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';

const Counter = styled.div`
  display: flex;
  flex-shrink: 0;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  font-feature-settings: 'ss01' on;
  letter-spacing: normal;

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '35px',
    isLaptop: '35px',
    isTablet: '35px',
    isMobile: '17px',
  })};

  color: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.color,
      isLaptop: props.color,
      isTablet: props.color === 'black' ? 'white' : 'black',
      isMobile: props.color === 'black' ? 'white' : 'black',
    })};
  margin-left: 40px;

  background: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: '',
      isLaptop: '',
      isTablet: props.color,
      isMobile: props.color,
    })};
  border-radius: 50%;
  width: ${getCurrentLayoutProperty({
    isDesktop: '',
    isLaptop: '',
    isTablet: '96px',
    isMobile: '48px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '',
    isLaptop: '',
    isTablet: '96px',
    isMobile: '48px',
  })};

  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'flex-start',
    isTablet: 'center',
    isMobile: 'center',
  })};

  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'flex-start',
    isTablet: 'center',
    isMobile: 'center',
  })};

  > span {
    margin-top: ${getCurrentLayoutProperty({
      isDesktop: 'unset',
      isLaptop: 'unset',
      isTablet: '8px',
      isMobile: '4px',
    })};
  }
`;

const ListItem = styled.li`
  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'flex-start',
    isTablet: 'center',
    isMobile: 'center',
  })};
  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'flex-start',
    isTablet: 'space-between',
    isMobile: 'space-between',
  })};
`;

const AnchorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Anchor = styled.a`
  color: ${(props) => props.color};
`;

const Link = ({onClick, text, isActive, onHover, navColor, counter = null, onCounterClick}) => {
  const layout = useAppLayout();

  return (
    <ListItem className={isActive ? 'active' : ''} onClick={onClick} onMouseEnter={onHover} layout={layout}>
      <AnchorWrapper>
        {navColor === 'white' ? (
          <img src="/icons/stroke-white.svg" alt="AE Logo" />
        ) : (
          <img src="/icons/stroke-black.svg" alt="AE Logo" />
        )}

        <Anchor color={navColor}>{text}</Anchor>
      </AnchorWrapper>
      {counter && (
        <Counter onClick={onCounterClick} layout={layout} color={navColor}>
          <span>{counter}</span>
        </Counter>
      )}
    </ListItem>
  );
};

Link.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  navColor: PropTypes.string,
  counter: PropTypes.string,
  onCounterHover: PropTypes.func,
  onCounterClick: PropTypes.func,
};

export default Link;
