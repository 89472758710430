import React from 'react';
import styled from 'styled-components';
import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {Header} from '../text';
import InfoBlock from '../../../../components/info-block/info-block';
import {SectionWrapper, SectionContent} from '../../../../components/common';

const lampImage = {
  isDesktop: '/pages/home/lamp-desktop.svg',
  isLaptop: '/pages/home/lamp-laptop.svg',
  isTablet: '/pages/home/lamp-tablet.svg',
  isMobile: '/pages/home/lamp-mobile.svg',
};

const pigImage = {
  isDesktop: '/pages/home/pig-desktop.svg',
  isLaptop: '/pages/home/pig-laptop.svg',
  isTablet: '/pages/home/pig-tablet.svg',
  isMobile: '/pages/home/pig-mobile.svg',
};

const brainImage = {
  isDesktop: '/pages/home/brain-desktop.svg',
  isLaptop: '/pages/home/brain-laptop.svg',
  isTablet: '/pages/home/brain-tablet.svg',
  isMobile: '/pages/home/brain-mobile.svg',
};

const workImage = {
  isDesktop: '/pages/home/work-desktop.svg',
  isLaptop: '/pages/home/work-laptop.svg',
  isTablet: '/pages/home/work-tablet.svg',
  isMobile: '/pages/home/work-mobile.svg',
};

const TITLE = 'Creating an Opportunity for Growth for Your Business with deck.gl and Kepler.gl';

const LAMP_TITLE = 'Any kind of GIS data visualization';
const LAMP_TEXT =
  'vis.gl is a growing technology and fully adaptive to many industries and use cases, allowing us to center any GIS solution around it';
const WORK_TITLE = 'Customizing vis.gl libraries to your requiremements';
const WORK_TEXT =
  'With a strong extertize in working with the core of vis.gl technologies, we can fully customize it adding any required additional modules and features';
const BRAIN_TITLE = 'Visualizing your existing data analysis and ML models';
const BRAIN_TEXT =
  'We work with data in any format making it easy to understand and creating understandable visualizations dashboards from it';
const PIG_TITLE = 'Building alternatives to expensive out-of-the-box solutions';
const PIG_TEXT =
  'With the power that vis.gl toolstack brings to us, it’s all open-source, meaning that on top of it we can build much cheaper alternative to expensive software';

const Wrapper = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const OpportunityInfoBlocks = styled.div`
  > * {
    margin-bottom: ${getCurrentLayoutProperty({
      isDesktop: '87px',
      isLaptop: '124px',
      isTablet: '0',
      isMobile: '0',
    })};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const CenteredHeader = styled(Header)`
  text-align: center;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '0 auto 67px auto',
    isLaptop: '0 auto 141px auto',
    isTablet: '0 auto 111px auto',
    isMobile: '0 auto 55px auto',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '808px',
    isTablet: '584px',
    isMobile: '328px',
  })};
`;

const CreatingOppotunityBlock = () => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <SectionContent layout={layout}>
        <CenteredHeader layout={layout}>{TITLE}</CenteredHeader>
        <OpportunityInfoBlocks layout={layout}>
          <InfoBlock
            title={LAMP_TITLE}
            text={LAMP_TEXT}
            backgroundImage={getCurrentLayoutProperty(lampImage)({layout})}
            bgSize={'contain'}
          />
          <InfoBlock
            title={WORK_TITLE}
            text={WORK_TEXT}
            backgroundImage={getCurrentLayoutProperty(workImage)({layout})}
            bgSize={'contain'}
            reverse
          />
          <InfoBlock
            title={BRAIN_TITLE}
            text={BRAIN_TEXT}
            backgroundImage={getCurrentLayoutProperty(brainImage)({layout})}
            bgSize={'contain'}
          />
          <InfoBlock
            title={PIG_TITLE}
            text={PIG_TEXT}
            backgroundImage={getCurrentLayoutProperty(pigImage)({layout})}
            bgSize={'contain'}
            reverse
          />
        </OpportunityInfoBlocks>
      </SectionContent>
    </Wrapper>
  );
};

export default CreatingOppotunityBlock;
