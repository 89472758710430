import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > p {
    letter-spacing: 0.01em;
    line-height: 140%;
    margin: 15px 0;
    font-weight: ${getCurrentLayoutProperty({
      isDesktop: '400',
      isLaptop: '400',
      isTablet: '400',
      isMobile: '600',
    })};
  }

  > span {
    font-size: ${getCurrentLayoutProperty({
      isDesktop: '24px',
      isLaptop: '24px',
      isTablet: '24px',
      isMobile: '17px',
    })};
    font-weight: ${getCurrentLayoutProperty({
      isDesktop: '600',
      isLaptop: '600',
      isTablet: '600',
      isMobile: '800',
    })};
    color: #ff4242;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  :hover {
    > p {
      text-decoration: underline;
    }

    > :last-child {
      text-decoration: underline;
      text-decoration-color: #ff4242;
    }
  }
`;

export const SwipeItemLink = ({onClick, title}) => {
  const layout = useAppLayout();

  return (
    <Wrapper onClick={onClick} layout={layout}>
      <p>{title}</p>
      <span>
        Read article
        <img src={'/icons/arrow-right.webp'} alt="to right"></img>
      </span>
    </Wrapper>
  );
};

SwipeItemLink.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};
