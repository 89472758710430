export const TITLE = 'Inside The Engine';
export const SUBTITLE_ONE = 'Your In-House Product Development Team.';
export const PARAGRAPH_ONE =
  'Headquartered in Silicon Valley and Eastern Europe, we look to bring a custom, tailored approach to each client’s needs. Think of us as your custom product development team, ready to provide the engine to get to action.';
export const SUBTITLE_TWO = 'Design, Development & Data Engineering.';
export const PARAGRAPH_TWO =
  'We believe in solving complex problems by applying leading technologies and contributing to the better of the world by servicing our clients.';
export const BUTTON_TEXT = 'See our work';
export const BACKGROUND_ONE = '/pages/inside/content-1.webp';
export const BACKGROUND_TWO = '/pages/inside/content-2.webp';
export const PICTURE_ONE = '/pages/inside/wrench.webp';
export const PICTURE_TWO = '/pages/inside/flange.webp';
