import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Theme} from './styles/theme';
import {GlobalStyles} from './styles/global';

import App from './App';
import Sidebar from './components/navigation/sidebar';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Theme>
        <GlobalStyles />
        <Sidebar />
        <App />
      </Theme>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('app')
);
