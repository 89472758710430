import React, {useMemo} from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import Footer from '../../components/footer';
import CheckOurProjects from './components/CheckOurProjects';
import MainBanner from './components/MainBanner';
import TextBlock, {Header, Text} from './components/TextBlock';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import {getCurrentLayoutProperty} from '../../utils/common';
import ColoredBanner from './components/ColoredBanner';

const mainBackgroundImage = {
  isDesktop: '/case-studies/kepler/page1/mainBackgroundImageDesktop.webp',
  isLaptop: '/case-studies/kepler/page1/mainBackgroundImageLaptop.webp',
  isTablet: '/case-studies/kepler/page1/mainBackgroundImageTablet.webp',
  isMobile: '/case-studies/kepler/page1/mainBackgroundImageMobile.webp',
};

const tabletImage = {
  isDesktop: '/case-studies/kepler/page1/TabletImageDesktop.webp',
  isLaptop: '/case-studies/kepler/page1/TabletImageLaptop.webp',
  isTablet: '/case-studies/kepler/page1/TabletImageTablet.webp',
  isMobile: '/case-studies/kepler/page1/TabletImageMobile.webp',
};

const page3Image = {
  isDesktop: '/case-studies/kepler/page3/appDesktop.webp',
  isLaptop: '/case-studies/kepler/page3/appLaptop.webp',
  isTablet: '/case-studies/kepler/page3/appTablet.webp',
  isMobile: '/case-studies/kepler/page3/appMobile.webp',
};

const page5Image = {
  isDesktop: '/case-studies/kepler/page5/appDesktop.webp',
  isLaptop: '/case-studies/kepler/page5/appLaptop.webp',
  isTablet: '/case-studies/kepler/page5/appTablet.webp',
  isMobile: '/case-studies/kepler/page5/appTablet.webp',
};

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const TextContent = {
  upperHeader: <img src={'/pages/work/foursquare.webp'} alt="logo" />,
  mainHeader: 'Improving and Optimizing Kepler.gl with Typescript',
  bages: ['product', 'web', 'digital cartography'],
  section2Header: 'Expanding our .gl portfolio',
  section2Text: [
    'Kepler.gl is a powerful open-source software for geospatial analysis. By making it easier and faster to explore large-scale datasets, kepler.gl introduces effective tools for digital cartography enthusiasts, data analytics and developers to create beautiful maps, gain insights from location data and make data-driven business decisions.',
    'Our challenge during this project was to rewrite the entire code base from JavaScript to TypeScript to apply best practices and increase the overall efficiency.',
  ],
  section3Header: 'Layering improvements over time',
  section3Text: [
    'In October, 2021, we started our involvement with kepler.gl. We have made a few commits, with some of them related to the platform UI. With no contract, agreement, or commitment, it was more of a goodwill gesture, an indication of our direct interest. With interest from our contributions, we contracted further  work to refine and evolve the system across multiple tasks and challenges.',
  ],
  section4Header: 'Refactoring and rewriting for focused scalability',
  section4Text: [
    'At the first stage, our engineers focused on re-writing the platform from JavaScript to Typescript. By doing this, we would be able to achieve more stability and maintainability.',
  ],
  section5Header1: 'Applying the industry’s best practices',
  section5Text1: [
    'In 2022, frontend development using TypeScript is a common industry practice. With kepler.gl being an open source software, it’s crucial to allow and make it easier to contribute for as many developers as possible. Keeping that in mind, we managed to achieve better structure for the code base, make all dependencies much more clear and evident, and highlight relationships between components and different data types. All of that allowed us to make the contribution to the project easier and more attractive both for us and for any other developers, and as a result, improve the overall state of the entire product.',
  ],
  section5Header2: 'Making it more predictable',
  section5Text2: [
    'Being a superset of JavaScript, TS makes its code completely legal, although extending it with static type checker and error checking. It allows you to add new features and refactor code safer while being sure that the compiler tells you whether what you are doing now makes sense at all. Freeing time from many hours of debugging, it makes a developer’s life easier, and the application itself more stable. It was a huge advantage for kepler.gl.',
  ],
  section6Header: 'Applying a Modular approach',
  section6Text1:
    'The second huge milestone for us was to break the code base into modules and build a monorepo for kepler.gl. Code modules can be built separately and independently from one another. This allows us and any other developer to focus on only one functionality area at a time while being sure that no other application logic is affected. It’s also beneficial for the end user: it increases app performance by decreasing load time and memory usage.',
  section6Text2:
    'Building a monorepo for kepler.gl ensured a unified build process and CI/CD, better transparency, consistency, and single source of truth for the developers – all of that together would minimize errors while building the application, and facilitate deployment.',
  section7Header1: '15% of bugs',
  section7Text1: ['Prevented due to TS transfer'],
  section7Header2: '>120,000',
  section7Text2: ['Lines of code successfully transfered'],
  section7Header3: 'The project has become more stable and predictable – that’s great work!',
  section7Text3: ['Project contributors feedback'],
};

const PageText = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const PageTextWrapper = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '50vw',
    isLaptop: '60vw',
    isTablet: '100%',
    isMobile: '100%',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '40px 92px',
    isMobile: '40px 16px',
  })};
`;

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const Page2Wrapper = styled.div`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '80px',
    isMobile: '40px',
  })};
`;

const Page3Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Page3TextContent = styled.div`
  background: black;
  color: white;
`;

const Page3Gradient = styled.div`
  position: relative;
  display: flex;

  img {
    margin: 0 auto;
    align-self: center;
  }

  :after {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '1008px',
      isLaptop: '685px',
      isTablet: '451px',
      isMobile: '223px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
`;

const Page4Wrapper = styled(Page3Wrapper)`
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '40px',
    isMobile: '40px',
  })};
`;

const Page4TextContent = styled.div`
  color: black;
`;

const Page5Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  
  > img {
    display: ${getCurrentLayoutProperty({
      isDesktop: 'flex',
      isLaptop: 'flex',
      isTablet: 'none',
      isMobile: 'none',
    })}
    margin: 0 auto;
    align-self: center;
  }
`;

const Page5ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row-reverse',
    isLaptop: 'row-reverse',
    isTablet: 'column',
    isMobile: 'column',
  })};
`;

const Page5RightColumn = styled.div`
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Page5TextWrapper = styled.div`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '40px 92px',
    isMobile: '40px 16px',
  })};
`;

const Page5Header = styled(Header)`
  color: #11eaa3;
  width: ${getCurrentLayoutProperty({
    isDesktop: '80%',
    isLaptop: '70%',
    isTablet: '90%',
    isMobile: '70%',
  })};
`;

const Page5LeftColumn = styled(Page5RightColumn)`
  background: #11eaa3;
  color: black;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: '-1px',
    isMobile: '-1px',
  })};

  > img {
    align-self: center;
    z-index: 1;
    width: calc(100% - 32px);
  }
`;

const Page5ColumnGradient = styled.div`
  position: relative;
  display: ${getCurrentLayoutProperty({
    isDesktop: 'none',
    isLaptop: 'none',
    isTablet: 'flex',
    isMobile: 'flex',
  })};

  :before {
    content: '';
    height: ${getCurrentLayoutProperty({
      isTablet: '40px',
      isMobile: '50px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: black;
    position: absolute;
    width: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }

  :after {
    content: '';
    height: ${getCurrentLayoutProperty({
      isTablet: '40px',
      isMobile: '50px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: #11eaa3;
    position: absolute;
    width: 50%;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
`;

const Page5ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  :before {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '200px',
      isLaptop: '200px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: #11eaa3;
    position: absolute;
    width: 50%;
    left: 50;
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }

  > img {
    display: ${getCurrentLayoutProperty({
      isDesktop: 'flex',
      isLaptop: 'flex',
      isTablet: 'none',
      isMobile: 'none',
    })};
    z-index: 1;
  }
`;

const Page6Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

const Page6TitleWrapper = styled.div`
  background: black;
  width: 100%;
  margin-top: -1px;
`;

const Page6Header = styled(Header)`
  width: ${getCurrentLayoutProperty({
    isDesktop: '50vw',
    isLaptop: '50vw',
    isTablet: '100%',
    isMobile: '100%',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px 40px 92px',
    isLaptop: '80px 60px 40px 60px',
    isTablet: '40px 92px 0 92px',
    isMobile: '0 16px 0 16px',
  })};
  color: white;
`;

const Page6Gradient = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  background: black;
  color: white;
  margin-top: -1px;

  :before {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '734px',
      isLaptop: '908px',
      isTablet: '295px',
      isMobile: '127px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${getCurrentLayoutProperty({
      isDesktop: '0',
      isLaptop: '0',
      isTablet: '346px',
      isMobile: '304px',
    })};
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  :after {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '734px',
      isLaptop: '908px',
      isTablet: '295px',
      isMobile: '127px',
    })};
    margin-top: -1px;
    padding-bottom: 1px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: ${getCurrentLayoutProperty({
      isDesktop: '0',
      isLaptop: '0',
      isTablet: '346px',
      isMobile: '305px',
    })};
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    transform: rotate(-180deg);
  }
`;

const Page6LeftColumn = styled.div`
  color: white;
  width: 100%;
  display: flex;
  height: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: 'auto',
    isTablet: '306px',
    isMobile: '304px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px 80px 92px',
    isLaptop: '0 60px 80px 60px',
    isTablet: '0 92px',
    isMobile: '24px 16px',
  })};
  z-index: 1;
`;

const Page6RightColumn = styled(Page6LeftColumn)`
  color: black;
  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'flex-end',
    isLaptop: 'flex-end',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  background: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: 'white',
    isMobile: 'white',
  })};
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: '330px',
    isMobile: '127px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px 80px 92px',
    isLaptop: '0 60px 80px 60px',
    isTablet: '40px 92px',
    isMobile: '24px 16px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '734px',
    isLaptop: '908px',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
  margin-bottom: -1px;
`;

const Page7Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const CaseStudiesKepler = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;
  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);

  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        backgroundImage={mainBackgroundImage}
        upperHeader={<MainBannerUpperHeader>{TextContent.upperHeader}</MainBannerUpperHeader>}
        mainHeader={TextContent.mainHeader}
        bages={TextContent.bages}
        tabletImage={tabletImage}
        largeTitle
      />
      <Page2Wrapper layout={layout}>
        <TextBlockComponent.Text
          headerText={TextContent.section2Header}
          mainText={TextContent.section2Text}
          color="#010101"
          headerColor="#000000"
        />
      </Page2Wrapper>
      <Page3Wrapper>
        <Page3TextContent layout={layout}>
          <PageTextWrapper layout={layout}>
            <Header layout={layout}>{TextContent.section3Header}</Header>
            <PageText layout={layout}>{TextContent.section3Text[0]}</PageText>
          </PageTextWrapper>
        </Page3TextContent>
        <Page3Gradient layout={layout}>
          <img src={getCurrentLayoutProperty(page3Image)({layout})} alt="app picture" layout={layout} />
        </Page3Gradient>
      </Page3Wrapper>
      <Page4Wrapper layout={layout}>
        <Page4TextContent layout={layout}>
          <PageTextWrapper layout={layout}>
            <Header layout={layout}>{TextContent.section4Header}</Header>
            <PageText layout={layout}>{TextContent.section4Text[0]}</PageText>
          </PageTextWrapper>
        </Page4TextContent>
      </Page4Wrapper>
      <Page5Wrapper layout={layout}>
        <Page5ColumnsWrapper layout={layout}>
          <Page5LeftColumn layout={layout}>
            <Page5TextWrapper layout={layout}>
              <Header layout={layout}>{TextContent.section5Header2}</Header>
              <PageText layout={layout}>{TextContent.section5Text2[0]}</PageText>
            </Page5TextWrapper>
            {!isDesktopType && <img src={getCurrentLayoutProperty(page5Image)({layout})} alt="app picture" />}
            {!isDesktopType && <Page5ColumnGradient layout={layout} />}
          </Page5LeftColumn>
          <Page5RightColumn layout={layout}>
            <Page5TextWrapper layout={layout}>
              <Page5Header layout={layout}>{TextContent.section5Header1}</Page5Header>
              <PageText layout={layout}>{TextContent.section5Text1[0]}</PageText>
            </Page5TextWrapper>
          </Page5RightColumn>
        </Page5ColumnsWrapper>
        {isDesktopType && (
          <Page5ImageWrapper layout={layout}>
            <img src={getCurrentLayoutProperty(page5Image)({layout})} alt="app picture" />
          </Page5ImageWrapper>
        )}
      </Page5Wrapper>
      <Page6Wrapper>
        <Page6TitleWrapper>
          <Page6Header layout={layout}>{TextContent.section6Header}</Page6Header>
        </Page6TitleWrapper>
        <Page6Gradient layout={layout}>
          <Page6LeftColumn layout={layout}>
            <PageText layout={layout}>{TextContent.section6Text1}</PageText>
          </Page6LeftColumn>
          <Page6RightColumn layout={layout}>
            <PageText layout={layout}>{TextContent.section6Text2}</PageText>
          </Page6RightColumn>
        </Page6Gradient>
      </Page6Wrapper>
      <Page7Wrapper layout={layout}>
        <ColoredBanner
          headerText={TextContent.section7Header1}
          contentText={TextContent.section7Text1}
          backgrounColor={'#11eaa3'}
          textColor={'black'}
        />
        <ColoredBanner
          headerText={TextContent.section7Header2}
          contentText={TextContent.section7Text2}
          backgrounColor={'#57b8d3'}
          textColor={'white'}
        />
        <ColoredBanner
          headerText={TextContent.section7Header3}
          contentText={TextContent.section7Text3}
          backgrounColor={'#010101'}
          textColor={'white'}
        />
      </Page7Wrapper>
      <CheckOurProjects currentProjectName={'kepler'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesKepler;
