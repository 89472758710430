import React from 'react';

import useAppLayout from '../../../../utils/useAppLayout';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import TextBlock from './TextBlock';

const SectionSix = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <SectionContent layout={layout}>
        <TextBlock />
      </SectionContent>
    </SectionWrapper>
  );
};

export default SectionSix;
