import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';
import TextBlock from '../../case-studies/components/TextBlock';
import useAppLayout from '../../../utils/useAppLayout';
import BulletItem from '../../../components/bullet-item';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({backgroundColor}) => backgroundColor};
  width: 100%;

  padding: ${getCurrentLayoutProperty({
    isDesktop: '120px 0 160px 0',
    isLaptop: '120px 0 160px 0',
    isTablet: '0 0 40px 0',
    isMobile: '0 0 40px 0',
  })};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  max-width: 1925px;
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ArticleImage = styled.img`
  display: block;
  object-fit: contain;

  width: ${getCurrentLayoutProperty({
    isDesktop: '45%',
    isLaptop: '45%',
    isTablet: '100vw',
    isMobile: '100vw',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '40px',
    isMobile: '40px',
  })};
`;

const BulletsContent = styled.ul`
  display: grid;
  width: 100%;
  padding: 0;

  margin: ${getCurrentLayoutProperty({
    isDesktop: '120px 0 0 0',
    isLaptop: '120px 0 0 0',
    isTablet: '64px 0 0 0',
    isMobile: '64px 0 0 0',
  })};

  row-gap: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};

  column-gap: ${getCurrentLayoutProperty({
    isDesktop: '32px',
    isLaptop: '32px',
    isTablet: '0',
    isMobile: '0',
  })};

  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr',
    isLaptop: '1fr 1fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};

  grid-template-rows: ${({bulletRowsCount}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletRowsCount === 3 ? '1fr 1fr 1fr' : '1fr 1fr',
      isLaptop: bulletRowsCount === 3 ? '1fr 1fr 1fr' : '1fr 1fr',
      isTablet: '1fr',
      isMobile: '1fr',
    })};
`;

const ArticlePage = ({
  header,
  text,
  image,
  altImage,
  bullets,
  bulletRowsCount = 3,
  backgroundColor = 'white',
  style,
  children,
}) => {
  const layout = useAppLayout();
  const textColor = backgroundColor === 'white' ? 'black' : 'white';
  const isTabletOrMobile = layout.isTablet || layout.isMobile;

  const headerFontSizes = {
    isDesktop: '72px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '29px',
  };

  const textFontSizes = {
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  };

  const textFontFamilies = {
    isDesktop: 'Kontora Medium',
    isLaptop: 'Kontora SemBd',
    isTablet: 'Kontora SemBd',
    isMobile: 'Kontora SemBd',
  };

  return (
    <Container layout={layout} backgroundColor={backgroundColor} style={style}>
      {isTabletOrMobile && <ArticleImage layout={layout} src={image} alt={altImage} />}
      <ContentWrapper layout={layout}>
        <ArticleWrapper>
          <TextBlock
            headerText={header}
            mainText={text}
            color={textColor}
            headerColor={textColor}
            mainTextOpacity={'1'}
            headerFontSize={getCurrentLayoutProperty(headerFontSizes)({layout})}
            textFontSize={getCurrentLayoutProperty(textFontSizes)({layout})}
            textFontFamily={getCurrentLayoutProperty(textFontFamilies)({layout})}
            style={{padding: 0, width: isTabletOrMobile ? 'auto' : '45%'}}
          />
          {!isTabletOrMobile && <ArticleImage layout={layout} src={image} alt={altImage} />}
        </ArticleWrapper>
        <BulletsContent layout={layout} bulletRowsCount={bulletRowsCount}>
          {bullets.map((item) => (
            <BulletItem
              key={item.text}
              header={item.header}
              text={item.text}
              bulletImage={item.bulletImage}
              bulletImageAlt={item.bulletImageAlt}
              textColor={textColor}
            />
          ))}
        </BulletsContent>
        {children}
      </ContentWrapper>
    </Container>
  );
};

ArticlePage.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  altImage: PropTypes.string.isRequired,
  bullets: PropTypes.array.isRequired,
  bulletRowsCount: PropTypes.number,
  backgroundColor: PropTypes.oneOf(['white', 'black']),
  style: PropTypes.object,
  children: PropTypes.node,
};

export default ArticlePage;
