import React from 'react';
import styled from 'styled-components';

import HeaderWithAELogo from '../../../components/header-with-AE-logo';
import ProjectsBlock from '../components/projects-block';
import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';

const geoCapabilitiesImage = {
  isDesktop: '/capabilities/geoCapabilitiesImageDesktop.webp',
  isLaptop: '/capabilities/geoCapabilitiesImageLaptop.webp',
  isTablet: '/capabilities/geoCapabilitiesImageTablet.webp',
  isMobile: '/capabilities/geoCapabilitiesImageMobile.webp',
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CapabilitiesLogo = styled.img`
  object-fit: contain;

  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  max-width: 1925px;
`;

const GeospatioalCapabilities = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper>
      <HeaderWithAELogo title="Action Engine Capabilities" />
      <ProjectsBlock />
      <CapabilitiesLogo
        layout={layout}
        src={getCurrentLayoutProperty(geoCapabilitiesImage)({layout})}
        alt="Geospatial capabilities"
        loading="eager"
      />
    </SectionWrapper>
  );
};

export default GeospatioalCapabilities;
