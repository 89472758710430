export const TITLE = 'Careers';
export const SUBTITLE = "Don't see something for you?";
export const FOOTER_TEXT_ONE = 'send cv';
export const FOOTER_TEXT_TWO = 'life at action engine';

export const VACANCY_1 = [
  {
    title: 'Responsibilities Of The Role',
    options: [
      'Build data pipelines to transform, analyze, and optimize geospatial datasets;',
      'Integrate and ingest a variety of external datasets;',
      'Build systems to efficiently query and access data;',
      'Cloud stack: AWS and Google Cloud Platform;',
      'Orchestration: Prefect (experience with Airflow a plus);',
      'Execution: Dask, Docker (experience with Kubernetes a plus);',
      'Language: Python;',
      'Data processing stack: Pandas, GeoPandas, Dask, PySpark.',
    ],
  },
  {
    title: 'Qualifications',
    options: [
      'A minimum of 3-5 years’ experience;',
      'At ease with technology, able to quickly pick up new platforms and troubleshoot;',
      'Excellent written and oral communication skills;',
      'Proactive, able to take initiative and work independently;',
      'Excellent organizational skills, ability to prioritize and handle multiple tasks.',
    ],
  },
  {
    title: 'Nice To Have',
    options: [
      'Experience with analytics database systems (Elasticsearch, Druid, Pinot, ClickHouse, etc) a plus;',
      'Experience with other Big Data technologies, (BigQuery, Snowflake, HDFS, Hive, Presto) Arrow, Parquet is always appreciated;',
      'Experience developing REST microservices;',
      'Experience with geospatial data;',
      'Experience with spatial indexing systems: H3, S2, geohash.',
    ],
  },
  {
    title: 'We offer',
    options: [
      'Opportunities for personal and career growth in a progressive industry;',
      'Competitive salary and various benefits.',
    ],
  },
];

export const VACANCY_2 = [
  {
    title: 'Responsibilities Of The Role',
    options: [
      'Develop elegant and responsive user interfaces;',
      'Maintain and improve existing code;',
      'Ensure web design is optimized for mobile and performance;',
      'Write reusable code for components and styles;',
      'Use and support suite of leading open source geospatial technologies - kepler.gl, deck.gl and H3.',
    ],
  },
  {
    title: 'Qualifications',
    options: [
      '3+ years experience as a Front-End developer;',
      'Expert with HTML, CSS, JavaScript and Typescript;',
      'React, Redux, React Hooks, styled-components, and Webpack;',
      'Excellent knowledge of browser troubleshooting, debugging and security practices;',
    ],
  },
  {
    title: 'Nice To Have',
    options: [
      'Experience with implementing responsive web design;',
      'Experience with developing data-rich applications and techniques for creating great UI architecture and engineering;',
      'Experience with client-side data processing, rendering performance, and visualizations;',
      'Proactive, able to take initiative and work independently.',
    ],
  },
  {
    title: 'We offer',
    options: [
      'Opportunities for personal and career growth in a progressive industry;',
      'Competitive salary and various benefits.',
    ],
  },
];

export const vacanciesList = [
  {id: 0, title: 'Data Engineer', description: '3-5 years', content: VACANCY_1},
  {id: 1, title: 'Frontend Developer', description: '3+ years', content: VACANCY_2},
];
