import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {MEMBER_PREVIEW_FIRST_TITLE, MEMBER_PREVIEW_SECOND_TITLE} from '../../constants/team';
import {AvatarWrapper, Name, Position} from '../team/team-member';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${getCurrentLayoutProperty({
    isDesktop: '550px',
    isLaptop: '550px',
    isTablet: 'calc(100vw - 40px)',
    isMobile: '100vw',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '40px 40px 150px 40px',
    isLaptop: '40px 40px 150px 40px',
    isTablet: '70px 70px 40px 70px',
    isMobile: '24px 16px 40px 16px',
  })};
  position: relative;
`;

const ParagraphTitle = styled.p`
  font-weight: 800;
  font-size: 17px;
  line-height: 120%;
  font-family: 'Kontora ExtraBold';
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '130px auto 20px auto',
    isLaptop: '130px auto 20px auto',
    isTablet: '30px auto 40px auto',
    isMobile: '40px auto 20px auto',
  })};
`;

const ContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
`;

const ParagraphContent = styled.li`
  font-weight: 600;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  line-height: 140%;
  font-family: 'Kontora SemBd';
  list-style-type: initial;
  white-space: pre-line;
  word-wrap: break-word;
`;

export const MemberContent = ({photo, name, position, work, skills}) => {
  const layout = useAppLayout();

  return (
    <MemberWrapper layout={layout}>
      <HeaderWrapper layout={layout}>
        <AvatarWrapper layout={layout} photo={photo} preview />
        <Name layout={layout}>{name}</Name>
        <Position layout={layout}>{position}</Position>
      </HeaderWrapper>
      <ParagraphTitle layout={layout}>{MEMBER_PREVIEW_FIRST_TITLE}</ParagraphTitle>
      <ContentWrapper>
        {work?.map((el, index) => (
          <ParagraphContent key={`work-${index}`} layout={layout}>
            {el}
          </ParagraphContent>
        ))}
      </ContentWrapper>
      {skills && <ParagraphTitle layout={layout}>{MEMBER_PREVIEW_SECOND_TITLE}</ParagraphTitle>}
      <ContentWrapper>
        {skills?.map((el, index) => (
          <ParagraphContent key={`skill-${index}`} layout={layout}>
            {el}
          </ParagraphContent>
        ))}
      </ContentWrapper>
    </MemberWrapper>
  );
};

MemberContent.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  work: PropTypes.arrayOf(PropTypes.string),
  skills: PropTypes.arrayOf(PropTypes.string),
};
