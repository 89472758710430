import React, {useMemo} from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import Footer from '../../components/footer';
import CheckOurProjects from './components/CheckOurProjects';
import MainBanner from './components/MainBanner';
import TextBlock, {Header, Text} from './components/TextBlock';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import {getCurrentLayoutProperty} from '../../utils/common';
import ColoredBanner from './components/ColoredBanner';

const mainBackgroundImage = {
  isDesktop: '/case-studies/deforestation/page1/mainBackgroundImageDesktop.webp',
  isLaptop: '/case-studies/deforestation/page1/mainBackgroundImageDesktop.webp',
  isTablet: '/case-studies/deforestation/page1/mainBackgroundImageTablet.webp',
  isMobile: '/case-studies/deforestation/page1/mainBackgroundImageMobile.webp',
};

const tabletImage = {
  isDesktop: '/case-studies/deforestation/page1/TabletImageDesktop.webp',
  isLaptop: '/case-studies/deforestation/page1/TabletImageLaptop.webp',
  isTablet: '/case-studies/deforestation/page1/TabletImageTablet.webp',
  isMobile: '/case-studies/deforestation/page1/TabletImageMobile.webp',
};

const headerLogo = {
  isDesktop: '/case-studies/deforestation/page1/logoDesktop.svg',
  isLaptop: '/case-studies/deforestation/page1/logoDesktop.svg',
  isTablet: '/case-studies/deforestation/page1/logoDesktop.svg',
  isMobile: '/case-studies/deforestation/page1/logoMobile.svg',
};

const page3Background = {
  isDesktop: '/case-studies/deforestation/page3/mainBackgroundDesktop.webp',
  isLaptop: '/case-studies/deforestation/page3/mainBackgroundLaptop.webp',
  isTablet: '/case-studies/deforestation/page3/mainBackgroundTablet.webp',
  isMobile: '/case-studies/deforestation/page3/mainBackgroundTablet.webp',
};

const page5Image = {
  isDesktop: '/case-studies/deforestation/page5/appDesktop.webp',
  isLaptop: '/case-studies/deforestation/page5/appLaptop.webp',
  isTablet: '/case-studies/deforestation/page5/appTablet.webp',
  isMobile: '/case-studies/deforestation/page5/appTablet.webp',
};

const page6Image = {
  isDesktop: '/case-studies/deforestation/page6/Desktop.webp',
  isLaptop: '/case-studies/deforestation/page6/Laptop.webp',
  isTablet: '/case-studies/deforestation/page6/Tablet.webp',
  isMobile: '/case-studies/deforestation/page6/Mobile.webp',
};

const page7Image = {
  isDesktop: '/case-studies/deforestation/page7/Desktop.webp',
  isLaptop: '/case-studies/deforestation/page7/Laptop.webp',
  isTablet: '/case-studies/deforestation/page7/Tablet.webp',
  isMobile: '/case-studies/deforestation/page7/Tablet.webp',
};

const page8Image = {
  isDesktop: '/case-studies/deforestation/page8/Desktop.webp',
  isLaptop: '/case-studies/deforestation/page8/Laptop.webp',
  isTablet: '/case-studies/deforestation/page8/Tablet.webp',
  isMobile: '/case-studies/deforestation/page8/Tablet.webp',
};

const page9Image = {
  isDesktop: '/case-studies/deforestation/page9/Desktop.webp',
  isLaptop: '/case-studies/deforestation/page9/Laptop.webp',
  isTablet: '/case-studies/deforestation/page9/Tablet.webp',
  isMobile: '/case-studies/deforestation/page9/Tablet.webp',
};

const page10Image = {
  isDesktop: '/case-studies/deforestation/page10/Desktop.webp',
  isLaptop: '/case-studies/deforestation/page10/Laptop.webp',
  isTablet: '/case-studies/deforestation/page10/Tablet.webp',
  isMobile: '/case-studies/deforestation/page10/Tablet.webp',
};

const page10LeftColumnImage = {
  isDesktop: '/case-studies/deforestation/page10/RightColumnDesktop.webp',
  isLaptop: '/case-studies/deforestation/page10/RightColumnLaptop.webp',
  isTablet: '/case-studies/deforestation/page10/RightColumnTablet.webp',
  isMobile: '/case-studies/deforestation/page10/RightColumnTablet.webp',
};

const page10RightColumnImage = {
  isDesktop: '/case-studies/deforestation/page10/LeftColumnDesktop.webp',
  isLaptop: '/case-studies/deforestation/page10/LeftColumnLaptop.webp',
  isTablet: '/case-studies/deforestation/page10/LeftColumnTablet.webp',
  isMobile: '/case-studies/deforestation/page10/LeftColumnTablet.webp',
};

const page11Image = {
  isDesktop: '/case-studies/deforestation/page11/page11-desktop.webp',
  isLaptop: '/case-studies/deforestation/page11/page11-laptop.webp',
  isTablet: '/case-studies/deforestation/page11/page11-tablet.webp',
  isMobile: '/case-studies/deforestation/page11/page11-mobile.webp',
};

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const TextContent = {
  mainHeader: 'Monitoring Deforestation with Actionable Geospatial Data Analytics',
  bages: ['product', 'web', 'digital cartography'],
  section2Header: 'The Global Problem',
  section2Text: [
    'Forests are being cleared to make space for agriculture and animal grazing, obtaining wood for fuel, manufacturing, and construction. This results in increased risks of global warming, biodiversity loss and soil erosion.',
    'From the WWF and Amazon Watch, to Unilever and Disney, thousands of the world’s largest and smallest organizations are trying to find a solution to this problem. Despite all the negative forecasts, we still have a chance to change this situation. At Action Engine, we’re happy to announce our own step forward.',
  ],
  section4Header: 'Accessible Actionability',
  section4Text:
    'The main thing we always strive to achieve is actionability. Each and every step should provide just enough information so the user can do exactly what is needed right now. We deliver on this promise  – our advanced data processing pipeline allows us to provide users with incredible insights. That’s why we begin the user journey with an actions-driven dashboard. The first thing a user sees are alerts – areas they have assigned to keep a close eye on any vegetation changes of.  If something needs attention, it is available right here in a short summary.',
  section6Header: 'Deep Dive – Quick and Easy',
  section6Text: [
    'Sometimes a summary isn`t just enough. In this case, we provide deep analysis of that area. We utilize cloud storage, handling terabytes of raw and processed data combined with results of analysis and machine learning. Through this, we can show average historic changes and vegetation distribution, highlighting key dependencies and the most significant events on the timeline. This level of detail is enough for researchers to understand what`s going on and what should be done next.',
  ],
  section7Header: 'Intuitive and Fully Interactive Mapping',
  section7Text: [
    'The second core element of the user experience is the interactive map. Scientific satellite missions provide open satellite imagery data on dozens of factors impacting life on Earth, including deforestation. By analyzing it, we provide insights into geospatial data enabling the user to discover previously unseen events and patterns at scale.',
    'Utilizing tiling services which provide fast and secure access to terabytes of geospatial data, we have developed an intuitive and fully interactive way to explore satellite images, augmented with layers of in-depth analysis. You can analyze vegetation change across the last month – so if you select May on the timeline, you will see changes from April to May as a heatmap where the strongest red is –100%, and the strongest green is +100% in vegetation.',
  ],
  section8Header: 'Making Your Research Easier',
  section8Text: [
    'The solution is packed with features that greatly complement analysis. For example, you can search for a particular place using its coordinates. If you select that place, you will see historic changes of vegetation within the map’s one pixel boundaries (10x10 meters in the real world). Area Selection works similarly, but shows more statistics applicable to this area, similar to the dashboard, without having to navigate to it.',
  ],
  section9Header: 'Keep a close eye on anything you want',
  section9Text: [
    'Alerts are also available here. If you need to track vegetation in some area, you select an area on the map, and assign a threshold.  The system will notify you when it is exceeded.  After that, if something needs your attention, it will be highlighted on the menu. Then you’ll have an option either to investigate, and then proceed to the statistics for that area, or ignore for now and explore it later.',
  ],
  section10LeftHeader: 'Any Kind of Team Collaboration',
  section10LeftText: [
    'Our tool fully supports inter-department and team collaboration. Any area can be easily shared via email or provided with a unique link. The receiver will see exactly what you see – a perfect point for actionable discussions.',
    'Also, we can create a workspace for you and your colleagues so all the vital work you do is done at one convenient place. We empower you with additional collaboration tools such as sharing inside the system – without having to use either email or any other tools.',
  ],
  section10RightHeader: 'Communicate Right Here',
  section10RightText: [
    'When you need to discuss something with your teammates, you can use the Comments area. Here you can also mention colleagues in your workspace so they receive a notification. After the comment is done, the pin appears on the map, and everyone inside your workspace will be able to see it and respond or resolve it.',
  ],
  section11Header: 'Golden Standard for Our Company',
  section11Text: [
    'All of these functions are combined with a great user experience. We strive to make researchers work not just easier, but also enjoyable. We hope it might inspire them to find great insights while fighting deforestation.',
  ],
  section12Header1: 'Business Driven Solution',
  section12Text1: ['We make deforestation monitoring easier and more accessible for everyone'],
  section12Header2: 'User-Centered Design',
  section12Text2: [
    'Our solution is supportive for researchers various journeys. We hope it inspires them to produce outstanding results',
  ],
  section12Header3: 'Unique Solution Out of Open Data',
  section12Text3: [
    'We strive to highlight that impacting the world does not depend on how much money you are ready to spend on it.',
  ],
  section13Header: 'An ongoing initiative',
  section13Text: [
    'We strongly believe that taking deforestation, greenhouse gas emissions, global warming and all the other factors affecting the planet and our life on it under strict control can make the world a better place. We understand that we’re still at the very beginning of that future but see our deforestation solution as a first step forward.',
  ],
};

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const PageText = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const Page2Wrapper = styled.div`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '80px',
    isMobile: '40px',
  })};
`;

const Page3Wrapper = styled.div`
  display: flex;

  > img {
    width: 100%;
    height: auto;
  }
`;

const Page4Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 50% 0 92px',
    isLaptop: '0 40% 0 60px',
    isTablet: '40px 92px 0 92px',
    isMobile: '20px 16px 0 16px',
  })};
`;

const Page5Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'right',
    isLaptop: 'center',
    isTablet: 'center',
    isMobile: 'center',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1550px',
    isLaptop: '1210px',
    isTablet: '840px',
    isMobile: '400px',
  })};

  > img {
    width: ${getCurrentLayoutProperty({
      isDesktop: '1440px',
      isLaptop: '1080px',
      isTablet: 'auto',
      isMobile: 'calc(100vw - 32px)',
    })};
    height: auto;
    position: absolute;
    margin: ${getCurrentLayoutProperty({
      isDesktop: '100px auto 100px auto',
      isLaptop: '80px auto',
      isTablet: '40px auto',
      isMobile: '40px auto',
    })};
  }
`;

const Gradient5 = styled.div`
  height: ${getCurrentLayoutProperty({
    isDesktop: '1550px',
    isLaptop: '1210px',
    isTablet: '840px',
    isMobile: '400px',
  })};
  background: black;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  transform: rotate(-180deg);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 1px;
`;

const Page6Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1px;

  > img {
    z-index: 1;
    margin: 0 auto;
    align-self: center;
    position: absolute;
    bottom: ${getCurrentLayoutProperty({
      isDesktop: '40px',
      isLaptop: '40px',
      isTablet: '0',
      isMobile: '0',
    })};
  }
`;

const Gradient6 = styled(Gradient5)`
  transform: unset;
  height: ${getCurrentLayoutProperty({
    isDesktop: '1210px',
    isLaptop: '800px',
    isTablet: '484px',
    isMobile: '212px',
  })};
  position: unset;
  margin-top: -1px;
`;

const Page6Backgound = styled.div`
  background: black;
  color: white;
  height: ${getCurrentLayoutProperty({
    isDesktop: '700px',
    isLaptop: '400px',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '60px 92px',
    isLaptop: '20px 60px',
    isTablet: '40px 92px',
    isMobile: '40px 16px',
  })};
`;

const Page6TextWrapper = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '43vw',
    isLaptop: '60vw',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Page7Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '100px 0 92px 0',
    isLaptop: '60px 0 60px 0',
    isTablet: '0 0 20px 0',
    isMobile: '0 0 20px 0',
  })};
  height: auto;

  > img {
    width: ${getCurrentLayoutProperty({
      isDesktop: 'calc(100% - 184px)',
      isLaptop: 'calc(100% - 120px)',
      isTablet: 'calc(100% - 32px)',
      isMobile: 'calc(100% - 32px)',
    })};
    height: auto;
    margin: ${getCurrentLayoutProperty({
      isDesktop: '40px auto 0 auto',
      isLaptop: '40px auto 0 auto',
      isTablet: '40px auto',
      isMobile: '20px auto',
    })};
  }
`;

const Page7Text = styled(PageText)`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const Page8Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;

  > img {
    width: 100%;
    height: auto;
    z-index: 1;
  }
`;

const Page8Background = styled.div`
  background: black;
  width: 100%;
  display: flex;
  justify-content: right;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '40px 92px',
    isMobile: '40px 16px',
  })};
`;

const Page8GradientContainer = styled.div`
  position: relative;

  :after {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '931px',
      isLaptop: '612px',
      isTablet: '380px',
      isMobile: '200px',
    })};
    margin-top: -1px;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -380;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
`;

const Page8Content = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '65%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Page9Wrapper = styled(Page7Wrapper)`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '110px',
    isLaptop: '100px',
    isTablet: '40px',
    isMobile: '40px',
  })};
`;

const Page10Wrapper = styled.div`
  display: flex;
  background: black;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '2840px',
    isLaptop: '2135px',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
`;

const Page10TextContentWrapper = styled.div`
  height: ${getCurrentLayoutProperty({
    isDesktop: '740px',
    isLaptop: '650px',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
  display: flex;
  flex-direction: column;
  padding-bottom: ${getCurrentLayoutProperty({
    isTablet: '20px',
    isMobile: '20px',
  })};

  > span {
    color: #ffec26;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffec26;
  color: #010101;
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '56px 92px',
    isMobile: '24px 16px',
  })};
  position: relative;
  margin-top: -1px;
  padding-bottom: ${getCurrentLayoutProperty({
    isTablet: '499px',
    isMobile: '0',
  })};
`;

const RightColumn = styled(LeftColumn)`
  background: #010101;
  color: white;
  padding-bottom: 40px;
  display: flex;
  margin-top: ${getCurrentLayoutProperty({
    isTablet: '0',
    isMobile: '40px',
  })};
`;

const LeftColumnImage = styled.img`
  align-self: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'center',
  })};
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '800px',
    isLaptop: '480px',
  })};
  height: auto;
  margin-top: 20px;
`;

const RightColumnImage = styled.img`
  align-self: ${getCurrentLayoutProperty({
    isDesktop: 'flex-end',
    isLaptop: 'center',
  })};
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '800px',
    isLaptop: '480px',
  })};
  height: auto;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '50px',
    isMobile: '20px',
  })};
`;

const Page10Image = styled.img`
  position: ${getCurrentLayoutProperty({
    isDesktop: 'absolute',
    isLaptop: 'absolute',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  bottom: 0;
  align-self: center;
  right: ${getCurrentLayoutProperty({
    isDesktop: '100px',
    isLaptop: '50px',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: 'calc(100vw - 220px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  height: auto;
  max-height: 1100px;
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '-5px',
    isLaptop: '-5px',
    isTablet: '0',
    isMobile: '0',
  })};
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '0',
    isMobile: '0',
  })};
  background: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: 'black',
    isMobile: 'black',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: 'unset',
    isLaptop: 'unset',
    isTablet: '40px 16px',
    isMobile: '24px 16px',
  })};
`;

const Page10ImageTablet = styled(Page10Image)`
  display: ${getCurrentLayoutProperty({
    isDesktop: 'none',
    isLaptop: 'none',
    isTablet: 'flex',
    isMobile: 'flex',
  })};
  padding-top: 20px;
`;

const Gradient10 = styled.div`
  display: ${getCurrentLayoutProperty({
    isDesktop: 'flex',
    isLaptop: 'flex',
    isTablet: 'none',
    isMobile: 'none',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '296px',
    isLaptop: '289px',
  })};
  background: #010101;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  position: absolute;
  bottom: ${getCurrentLayoutProperty({
    isDesktop: '-2px',
    isLaptop: '-2px',
  })};
  left: 0;
  right: 0;
`;

const ImagesTabletWrapper = styled.div`
  display: ${getCurrentLayoutProperty({
    isDesktop: 'none',
    isLaptop: 'none',
    isTablet: 'flex',
    isMobile: 'flex',
  })};
  flex-direction: column;
  width: 100vw;
  margin-top: ${getCurrentLayoutProperty({
    isTablet: '40px',
    isMobile: '0',
  })};
  margin-left: ${getCurrentLayoutProperty({
    isTablet: '-92px',
    isMobile: '-16px',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isTablet: '-500px',
    isMobile: '-16px',
  })};
  z-index: 1;
`;

const TabletImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: calc(100% - 32px);
  gap: ${getCurrentLayoutProperty({
    isTablet: '24px',
    isMobile: '16px',
  })};
  align-self: center;
  position: relative;

  :after {
    height: ${getCurrentLayoutProperty({
      isTablet: '353px',
      isMobile: '153px',
    })};
    background: #010101;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    content: '';
    width: 100vw;
    margin-left: -16px;
  }

  > img {
    width: 50%;
    height: auto;
    align-self: flex-start;
    z-index: 1;
  }
`;

const Page11Wrapper = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row-reverse',
    isLaptop: 'row-reverse',
    isTablet: 'column',
    isMobile: 'column',
  })};
  position: relative;
  margin-bottom: ${getCurrentLayoutProperty({
    isTablet: '280px',
    isMobile: '180px',
  })};
  margin-top: -1px;
  background: ${getCurrentLayoutProperty({
    isTablet: 'black',
    isMobile: 'black',
  })};

  > img {
    z-index: 1;
    width: ${getCurrentLayoutProperty({
      isDesktop: '50vw',
      isLaptop: '50vw',
      isTablet: '100vw',
      isMobile: '100vw',
    })};
    padding: ${getCurrentLayoutProperty({
      isDesktop: '80px 92px',
      isLaptop: '80px 60px',
      isTablet: '0',
      isMobile: '0',
    })};
    height: auto;
    align-self: center;
  }
`;

const Page11TextContent = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 1;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '40px 92px',
    isMobile: '40px 16px',
  })};
`;

const Page11Background = styled.div`
  background: black;
  position: absolute;
  top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '49%',
    isMobile: '49%',
  })};
  left: 0;
  width: 100%;
  height: 50%;
`;

const Gradient11 = styled.div`
  height: ${getCurrentLayoutProperty({
    isDesktop: '296px',
    isLaptop: '219px',
    isTablet: '219px',
    isMobile: '80px',
  })};
  background: #010101;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  position: absolute;
  top: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '99.9%',
    isMobile: '99.9%',
  })};
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: -1px;
`;

const Page12Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const Page13Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('/case-studies/deforestation/background12.webp') no-repeat;
  background-size: cover;
  height: ${getCurrentLayoutProperty({
    isDesktop: '652px',
    isLaptop: '437px',
    isTablet: '425px',
    isMobile: '418px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 29%',
    isLaptop: '80px 23%',
    isTablet: '40px',
    isMobile: '40px',
  })};
  color: white;
  text-align: center;
  margin-top: ${getCurrentLayoutProperty({
    isTablet: '80px',
    isMobile: '80px',
  })};

  > span {
    color: #f4e11b;
  }
`;

const CaseStudiesDeforestation = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;
  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);

  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        backgroundImage={mainBackgroundImage}
        upperHeader={
          <MainBannerUpperHeader>
            <img src={getCurrentLayoutProperty(headerLogo)({layout})} alt="header logo" />
          </MainBannerUpperHeader>
        }
        mainHeader={TextContent.mainHeader}
        bages={TextContent.bages}
        tabletImage={tabletImage}
        largeTitle
      />
      <Page2Wrapper layout={layout}>
        <TextBlockComponent.Text
          headerText={TextContent.section2Header}
          mainText={TextContent.section2Text}
          color="#010101"
          headerColor="#000000"
        />
      </Page2Wrapper>
      <Page3Wrapper layout={layout}>
        <img src={getCurrentLayoutProperty(page3Background)({layout})} alt="background picture" />
      </Page3Wrapper>
      <Page4Wrapper layout={layout}>
        <Header layout={layout}>{TextContent.section4Header}</Header>
        <PageText layout={layout}>{TextContent.section4Text}</PageText>
      </Page4Wrapper>
      <Page5Wrapper layout={layout}>
        <Gradient5 layout={layout} />
        <img src={getCurrentLayoutProperty(page5Image)({layout})} alt="app picture" layout={layout} />
      </Page5Wrapper>
      <Page6Wrapper layout={layout}>
        <Page6Backgound layout={layout}>
          <Page6TextWrapper layout={layout}>
            <Header layout={layout}>{TextContent.section6Header}</Header>
            <PageText layout={layout}>{TextContent.section6Text}</PageText>
          </Page6TextWrapper>
        </Page6Backgound>
        <Gradient6 layout={layout} />
        <img src={getCurrentLayoutProperty(page6Image)({layout})} alt="app picture" layout={layout} />
      </Page6Wrapper>
      <Page7Wrapper layout={layout}>
        <Page4Wrapper layout={layout}>
          <Header layout={layout}>{TextContent.section7Header}</Header>
          <Page7Text layout={layout}>{TextContent.section7Text[0]}</Page7Text>
          <Page7Text layout={layout}>{TextContent.section7Text[1]}</Page7Text>
        </Page4Wrapper>
        <img src={getCurrentLayoutProperty(page7Image)({layout})} alt="app picture" layout={layout} />
      </Page7Wrapper>
      <Page8Wrapper layout={layout}>
        <Page8Background layout={layout}>
          <Page8Content layout={layout}>
            <Header layout={layout}>{TextContent.section8Header}</Header>
            <PageText layout={layout}>{TextContent.section8Text}</PageText>
          </Page8Content>
        </Page8Background>
        <Page8GradientContainer layout={layout} />
        <img src={getCurrentLayoutProperty(page8Image)({layout})} alt="app picture" layout={layout} />
      </Page8Wrapper>
      <Page9Wrapper layout={layout}>
        <Page4Wrapper layout={layout}>
          <Header layout={layout}>{TextContent.section9Header}</Header>
          <PageText layout={layout}>{TextContent.section9Text}</PageText>
        </Page4Wrapper>
        <img src={getCurrentLayoutProperty(page9Image)({layout})} alt="app picture" layout={layout} />
      </Page9Wrapper>
      <Page10Wrapper layout={layout}>
        <LeftColumn layout={layout}>
          <Page10TextContentWrapper layout={layout}>
            <Header layout={layout}>{TextContent.section10LeftHeader}</Header>
            <PageText layout={layout}>{TextContent.section10LeftText[0]}</PageText>
            <PageText layout={layout}>{TextContent.section10LeftText[1]}</PageText>
          </Page10TextContentWrapper>
          {(layout.isDesktop || layout.isLaptop) && (
            <LeftColumnImage
              src={getCurrentLayoutProperty(page10LeftColumnImage)({layout})}
              alt="app picture"
              layout={layout}
            />
          )}
          {(layout.isTablet || layout.isMobile) && (
            <ImagesTabletWrapper layout={layout}>
              <TabletImages layout={layout}>
                <img src={getCurrentLayoutProperty(page10LeftColumnImage)({layout})} alt="app picture" />
                <img src={getCurrentLayoutProperty(page10RightColumnImage)({layout})} alt="app picture" />
              </TabletImages>
              <Page10ImageTablet
                src={getCurrentLayoutProperty(page10Image)({layout})}
                alt="app picture"
                layout={layout}
              />
            </ImagesTabletWrapper>
          )}
          <Gradient10 layout={layout} />
        </LeftColumn>
        <RightColumn layout={layout}>
          <Page10TextContentWrapper layout={layout}>
            <span>
              <Header layout={layout}>{TextContent.section10RightHeader}</Header>
            </span>
            <PageText layout={layout}>{TextContent.section10RightText}</PageText>
          </Page10TextContentWrapper>
          {(layout.isDesktop || layout.isLaptop) && (
            <RightColumnImage
              src={getCurrentLayoutProperty(page10RightColumnImage)({layout})}
              alt="app picture"
              layout={layout}
            />
          )}
          {(layout.isDesktop || layout.isLaptop) && (
            <Page10Image src={getCurrentLayoutProperty(page10Image)({layout})} alt="app picture" layout={layout} />
          )}
        </RightColumn>
      </Page10Wrapper>
      <Page11Wrapper layout={layout}>
        <img src={getCurrentLayoutProperty(page11Image)({layout})} alt="app picture" layout={layout} />
        <Page11TextContent layout={layout}>
          <Header layout={layout}>{TextContent.section11Header}</Header>
          <PageText layout={layout}>{TextContent.section11Text}</PageText>
        </Page11TextContent>
        <Page11Background layout={layout} />
        <Gradient11 layout={layout} />
      </Page11Wrapper>
      <Page12Wrapper layout={layout}>
        <ColoredBanner
          headerText={TextContent.section12Header1}
          contentText={TextContent.section12Text1}
          backgrounColor={'#f4e11b'}
          textColor={'black'}
          minHeight
        />
        <ColoredBanner
          headerText={TextContent.section12Header2}
          contentText={TextContent.section12Text2}
          backgrounColor={'#713df5'}
          textColor={'white'}
          minHeight
        />
        <ColoredBanner
          headerText={TextContent.section12Header3}
          contentText={TextContent.section12Text3}
          backgrounColor={'#010101'}
          textColor={'white'}
          minHeight
        />
      </Page12Wrapper>
      <Page13Wrapper layout={layout}>
        <span>
          <Header layout={layout}>{TextContent.section13Header}</Header>
        </span>
        <PageText layout={layout}>{TextContent.section13Text}</PageText>
      </Page13Wrapper>
      <CheckOurProjects currentProjectName={'deforestation'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesDeforestation;
