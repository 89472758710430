import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/footer';

import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';
import ArticlePage from './components/article-page';
import GeospatioalCapabilities from './components/geospatial-capabilities';
import TechnologyBanner from './components/technology-banner';
import TechnologyFirst from './components/technology-first';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: 0,
    isMobile: 0,
  })};
`;

// -------------First Article Page Data-------------

const customGeospatialHeader = 'Custom Geospatial Applications for any use case';
const customGeospatialText = `
  ActionEngine geospatial application allows
  to interactively explore satellite imagery
  augmented with layers of analytical data,
  while preserving great user experience.
`;

const customGeospatialImage = {
  isDesktop: '/capabilities/customGeospatialApplicationsImageDesktop.webp',
  isLaptop: '/capabilities/customGeospatialApplicationsImageDesktop.webp',
  isTablet: '/capabilities/customGeospatialApplicationsImageTablet.webp',
  isMobile: '/capabilities/customGeospatialApplicationsImageMobile.webp',
};

const customGeospatialAltImage = 'Custom Geospatial Applications for any use case';

const customGeospatialBullets = [
  {
    header: 'Product Design',
    text: 'With product design expertise, tools, and methodoligies, our applications maintain the highest quality of user experience.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/sputnik.svg',
    bulletImageAlt: 'Product Design',
  },
  {
    header: 'Modern Web-development Stack',
    text: 'Stacking of image processing libraries allows us to convert incoming data to a common format for optimizing storage and compute resources.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/stack.svg',
    bulletImageAlt: 'Modern Web-development Stack',
  },
  {
    header: 'Deck.gl',
    text: 'We use deck.gl, a WebGL-powered framework for visual exploratory data analysis of large datasets.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/deck.gl.svg',
    bulletImageAlt: 'deck.gl',
  },
  {
    header: 'Raster Geospatial Data',
    text: 'Visualizing layers of raster data allows us to deliver raw satellite images and analytical insights to users as interactive maps.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/raster.svg',
    bulletImageAlt: 'Raster Geospatial Data',
  },
  {
    header: 'Vector Geospatial Data',
    text: 'Vector data allows us to work with shapes and individual points that represent areas of interest and geospatial events.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/vector.svg',
    bulletImageAlt: 'Vector Geospatial Data',
  },
  {
    header: '3D Geospatial Visualization',
    text: 'Utilizing 3D Tiles and I3S formats makes it possible to stream and render large amounts of heterogeneously distributed geographic three-dimensional data.',
    bulletImage: '/capabilities/bullet-icons/custom-geospatial/3d.svg',
    bulletImageAlt: '3D Geospatial Visualization',
  },
];

const customGeospatialMargin = {
  isDesktop: 0,
  isLaptop: 0,
  isTablet: '120px',
  isMobile: '80px',
};

// -------------End First Article Page Data---------

// -------------Second Article Page Data------------

const satelliteProcessingHeader = 'Satellite Image Processing';
const satelliteProcessingText = `
  Using open data from scientific missions, we’ve built up extremely effective pipeline
  which allows us to download and process terabytes of satellite
  imagery various sources converting it to common format for
  future storage and analysis.
`;

const satelliteProcessingImage = {
  isDesktop: '/capabilities/satelliteProcessingImageDesktop.webp',
  isLaptop: '/capabilities/satelliteProcessingImageDesktop.webp',
  isTablet: '/capabilities/satelliteProcessingImageTablet.webp',
  isMobile: '/capabilities/satelliteProcessingImageMobile.webp',
};

const satelliteProcessingAltImage = 'Satellite Image Processing';

const satelliteProcessingBullets = [
  {
    header: 'Distributed Computing',
    text: 'Distributing computing allows us to ingest large volumes of data while controlling performance/cost tradeoff.',
    bulletImage: '/capabilities/bullet-icons/sattelite-image-processing/distribution.svg',
    bulletImageAlt: 'Distributed Computing',
  },
  {
    header: 'Scientific Satellite Missions',
    text: 'Scientific satellite missions provide open satellite imagery data on vegetation, water, atmosphere, and other factors impacting climate on Earth.',
    bulletImage: '/capabilities/bullet-icons/sattelite-image-processing/scientific.svg',
    bulletImageAlt: 'Scientific Satellite Missions',
  },
  {
    header: 'Geospatial Data Catalog',
    text: 'Registering processed images in Spatio-Temporal Asset Catalog allows combining a collection of overlapping satellite images from different dates into seamless cloudless mosaic.',
    bulletImage: '/capabilities/bullet-icons/sattelite-image-processing/catalog.svg',
    bulletImageAlt: 'Geospatial Data Catalog',
  },
  {
    header: 'Multi-band Image Processing',
    text: `Combining red, green, and blue bands of multi-band satellite imagery allows us to get colorful images of Earth's surface.`,
    bulletImage: '/capabilities/bullet-icons/sattelite-image-processing/multiband.svg',
    bulletImageAlt: 'Multi-band Image Processing',
  },
  {
    header: 'Cloud Storage',
    text: 'Taking advantage of Cloud Storage, we handle terabytes of raw and processed data, as well as results of analysis and machine learning.',
    bulletImage: '/capabilities/bullet-icons/sattelite-image-processing/cloud.svg',
    bulletImageAlt: 'Cloud Storage',
  },
];

const satelliteProcessingMargin = {
  isDesktop: 0,
  isLaptop: 0,
  isTablet: '120px',
  isMobile: '40px',
};

// -------------End Second Article Page Data---------

// -------------Third Article Page Data------------

const dataAnaliticsHeader = 'Data Analytics of Satellite Imagery';
const dataAnaliticsText = `
  With our satellite imagery analysis pipeline,
  we provide insights into geospatial data and
  enable you to discover previously
  unseen events and patterns at scale.
`;

const dataAnaliticsImage = {
  isDesktop: '/capabilities/dataAnaliticsImageDesktop.webp',
  isLaptop: '/capabilities/dataAnaliticsImageDesktop.webp',
  isTablet: '/capabilities/dataAnaliticsImageTablet.webp',
  isMobile: '/capabilities/dataAnaliticsImageMobile.webp',
};

const dataAnaliticsAltImage = 'Data Analytics of Satellite Imagery';

const dataAnaliticsBullets = [
  {
    header: 'Machine Learning',
    text: 'Using state of the art machine learning techniques at scale allows us to automate object detection, land cover recognition, and geospatial time series analysis',
    bulletImage: '/capabilities/bullet-icons/data-analitics/machine-learning.svg',
    bulletImageAlt: 'Machine Learning',
  },
  {
    header: 'Distributed Computing',
    text: 'Our scalable computing cluster allows us to apply machine learning methods and geospatial analytics to big geospatial data.',
    bulletImage: '/capabilities/bullet-icons/data-analitics/distributed-computing.svg',
    bulletImageAlt: 'Distributed Computing',
  },
  {
    header: 'Data Enrichment',
    text: 'Synergy of geospatial data and other non-geospatial data sources helps to understand nature of events from different angles.',
    bulletImage: '/capabilities/bullet-icons/data-analitics/data-enrichment.svg',
    bulletImageAlt: 'Data Enrichment',
  },
  {
    header: 'Data Visualization',
    text: `Our custom-tailored interactive maps and dashboards help to make data actionable.`,
    bulletImage: '/capabilities/bullet-icons/data-analitics/data-visualisation.svg',
    bulletImageAlt: 'Data Visualization',
  },
];

// -------------End Third Article Page Data---------

// -------------Fourth Article Page Data------------

const modernStandartsHeader = 'Modern Standards for Geospatial Data';
const modernStandartsText = `
  With Spatio-Temporal Asset Catalog, we manage metadata for
  large geospatial datasets that allows to efficiently
  organize terabytes of images into searchable catalogs.
  Cloud-Optimized GeoTIFF is a widely adopted format
  for raster geospatial data. COG allows to get rid
  of tiles precomputation thus drastically reducing
  compute and storage resources. It also allows
  storing overviews within the TIFF package to
  reduce overhead of managing multiple images. 
`;

const modernStandartsImage = {
  isDesktop: '/capabilities/modernStandartsImageDesktop.webp',
  isLaptop: '/capabilities/modernStandartsImageDesktop.webp',
  isTablet: '/capabilities/modernStandartsImageTablet.webp',
  isMobile: '/capabilities/modernStandartsImageMobile.webp',
};

const modernStandartsAltImage = 'Modern Standards for Geospatial Data';

const modernStandartsBullets = [
  {
    header: 'STAC API',
    text: 'STAC API allows navigating vast collections of terabytes of satellite images organized as searchable catalogs for data discovery using automation tools.',
    bulletImage: '/capabilities/bullet-icons/modern-standarts/static-api.svg',
    bulletImageAlt: 'STAC API',
  },
  {
    header: 'Cloud-Optimized GeoTIFF',
    text: `
      Storing raster geospatial data in Cloud-Optimized GeoTIFF
      allows to store uncompressed data together with overviews.
      That approach allows using the same data as input for
      data analysis pipelines and for dynamically
      creating tiles for web maps.`,
    bulletImage: '/capabilities/bullet-icons/modern-standarts/cloud-optimized-geotiff.svg',
    bulletImageAlt: 'Cloud-Optimized GeoTIFF',
  },
  {
    header: 'MosaicJSON',
    text: 'MosaicJSON allows the creation of a mosaic of satellite images combined onto one seamless map.',
    bulletImage: '/capabilities/bullet-icons/modern-standarts/mosaic-json.svg',
    bulletImageAlt: 'MosaicJSON',
  },
  {
    header: 'GeoJSON',
    text: `GeoJSON is a widely adopted format for storing vector geospatial data. As opposed to raster data, vector data allows storing shapes and points.`,
    bulletImage: '/capabilities/bullet-icons/modern-standarts/geojson.svg',
    bulletImageAlt: 'GeoJSON',
  },
  {
    header: 'OGC WMTS Standard',
    text: 'We support the OGC WMTS standard, allowing us to add layers of raster data from 3rd party services.',
    bulletImage: '/capabilities/bullet-icons/modern-standarts/ogc-standart.svg',
    bulletImageAlt: 'OGC WMTS Standard',
  },
];

// -------------End Fourth Article Page Data---------

const Capabilities = () => {
  const layout = useAppLayout();

  return (
    <PageWrapper layout={layout}>
      <GeospatioalCapabilities />
      <TechnologyFirst />
      <ArticlePage
        header={customGeospatialHeader}
        text={customGeospatialText}
        image={getCurrentLayoutProperty(customGeospatialImage)({layout})}
        altImage={customGeospatialAltImage}
        bullets={customGeospatialBullets}
        backgroundColor="black"
        style={{marginBottom: getCurrentLayoutProperty(customGeospatialMargin)({layout})}}
      />
      <ArticlePage
        header={satelliteProcessingHeader}
        text={satelliteProcessingText}
        image={getCurrentLayoutProperty(satelliteProcessingImage)({layout})}
        altImage={satelliteProcessingAltImage}
        bullets={satelliteProcessingBullets}
        backgroundColor="white"
        style={{marginBottom: getCurrentLayoutProperty(satelliteProcessingMargin)({layout})}}
      />
      <ArticlePage
        header={dataAnaliticsHeader}
        text={dataAnaliticsText}
        image={getCurrentLayoutProperty(dataAnaliticsImage)({layout})}
        altImage={dataAnaliticsAltImage}
        bullets={dataAnaliticsBullets}
        bulletRowsCount={2}
        backgroundColor="black"
        style={{marginBottom: getCurrentLayoutProperty(customGeospatialMargin)({layout})}}
      />
      <ArticlePage
        header={modernStandartsHeader}
        text={modernStandartsText}
        image={getCurrentLayoutProperty(modernStandartsImage)({layout})}
        altImage={modernStandartsAltImage}
        bullets={modernStandartsBullets}
        backgroundColor="white"
      >
        <TechnologyBanner />
      </ArticlePage>
      <Footer />
    </PageWrapper>
  );
};

export default Capabilities;
