import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useAppLayout from '../../../../utils/useAppLayout';
import {Text} from '../../../../components/common';
import {getCurrentLayoutProperty} from '../../../../utils/common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 60px 0 60px',
    isLaptop: '80px 40px 0 40px',
  })};
`;

const Picture = styled.div`
  > img {
    width: 50%;
    float: left;
    margin: 0 30px 20px 0;
    display: inline-block;
  }
`;

const Title = styled.p`
  font-weight: 800;
  color: black;
  margin: 0;
  line-height: ${getCurrentLayoutProperty({
    isDesktop: '120%',
    isLaptop: '120%',
  })};
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '42px',
  })};
  width: 85%;
  padding-bottom: 50px;
`;

export const ArticleContent = ({title, picture, content}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <Title layout={layout}>{title}</Title>
      <Picture>
        <img src={picture} alt="article picture" />
        {content?.map((el, i) => (
          <Text key={`content-${i}`} layout={layout}>
            {el}
          </Text>
        ))}
      </Picture>
    </Wrapper>
  );
};

ArticleContent.propTypes = {
  title: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
};
