import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../../utils/common';

import {outsideClick} from '../../utils/outsideClick';
import useAppLayout from '../../utils/useAppLayout';
import Burger from './burger';
import {LINKS} from './constants';
import Links from './links-list';

const OUR_WORK_ID = 2;

const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;

  width: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.open ? '100vw' : '80px',
      isLaptop: props.open ? '100vw' : '80px',
      isTablet: props.open ? '100vw' : '80px',
      isMobile: props.open ? '100vw' : '48px',
    })};

  height: ${getCurrentLayoutProperty({
    isDesktop: '100vh',
    isLaptop: '100vh',
    isTablet: '80px',
    isMobile: '48px',
  })};

  background-color: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.open ? props.backgroundColor : props.theme.colors.black,
      isLaptop: props.open ? props.backgroundColor : props.theme.colors.black,
      isTablet: props.open ? props.backgroundColor : props.theme.colors.gray,
      isMobile: props.open ? props.backgroundColor : props.theme.colors.gray,
    })};
`;

const LinksWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
`;

// TODO add transitions in case of changing layout in runtime
const SidebarView = () => {
  const layout = useAppLayout();
  const menuRef = useRef(null);
  const [open, setOpen] = outsideClick(menuRef, false);
  const [background, setBackground] = useState(LINKS[0].background);
  const [navColor, setNavColor] = useState(LINKS[0].textColor);
  const [showOurWorkPanel, setShowOurWorkPanel] = useState(false);

  const toggleMenu = () => setOpen(!open);

  useEffect(() => {
    !open && setNavColor('white');
    !open && setShowOurWorkPanel(false);
  }, [navColor, open]);

  const onHover = (item, layout) => {
    setBackground(item.background);
    setNavColor(item.textColor);

    if (item.id === OUR_WORK_ID && (layout.isDesktop || layout.isLaptop)) {
      setShowOurWorkPanel(true);
    } else {
      setShowOurWorkPanel(false);
    }
  };

  const handleCounterClick = (event, layout) => {
    event.stopPropagation();

    if (layout.isTablet || layout.isMobile) {
      setShowOurWorkPanel(true);
    }
  };

  return (
    <NavBar layout={layout} open={open}>
      <Burger open={open} onClick={toggleMenu} navColor={navColor} />
      {open && (
        <LinksWrapper>
          <Links
            innerRef={menuRef}
            onClick={toggleMenu}
            background={background}
            navColor={navColor}
            onHover={onHover}
            showOurWorkPanel={showOurWorkPanel}
            onCounterClick={(event) => handleCounterClick(event, layout)}
            onCloseOurWorkPanel={() => setShowOurWorkPanel(false)}
          />
        </LinksWrapper>
      )}
    </NavBar>
  );
};

export default SidebarView;
