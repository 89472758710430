import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Linked from './linked';
import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';

const cesiumImage = {
  isDesktop: '/logo/clients/cesiumLogoDesktop.webp',
  isLaptop: '/logo/clients/cesiumLogoLaptop.webp',
  isTablet: '/logo/clients/cesiumLogoTablet.webp',
  isMobile: '/logo/clients/cesiumLogoTablet.webp',
};

const foursquareImage = {
  isDesktop: '/logo/clients/foursquareLogoDesktop.webp',
  isLaptop: '/logo/clients/foursquareLogoLaptop.webp',
  isTablet: '/logo/clients/foursquareLogoTablet.webp',
  isMobile: '/logo/clients/foursquareLogoMobile.webp',
};

const aueousImage = {
  isDesktop: '/logo/clients/aqueousLogoDesktop.webp',
  isLaptop: '/logo/clients/aqueousLogoLaptop.webp',
  isTablet: '/logo/clients/aqueousLogoTablet.webp',
  isMobile: '/logo/clients/aqueousLogoMobile.webp',
};

const haydenImage = {
  isDesktop: '/logo/clients/haydenLogoDesktop.webp',
  isLaptop: '/logo/clients/haydenLogoLaptop.webp',
  isTablet: '/logo/clients/haydenLogoTablet.webp',
  isMobile: '/logo/clients/haydenLogoMobile.webp',
};

const keplerImage = {
  isDesktop: '/logo/clients/keplerDesktop.webp',
  isLaptop: '/logo/clients/keplerLaptop.webp',
  isTablet: '/logo/clients/keplerTablet.webp',
  isMobile: '/logo/clients/keplerMobile.webp',
};

const deforestationImage = {
  isDesktop: '/logo/clients/deforestationDesktop.webp',
  isLaptop: '/logo/clients/deforestationLaptop.webp',
  isTablet: '/logo/clients/deforestationTablet.webp',
  isMobile: '/logo/clients/deforestationMobile.webp',
};

const OUR_WORK_IMAGE_SIZE = {
  width: {
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '50%',
    isMobile: '100%',
  },
  height: {
    isDesktop: 'auto',
    isLaptop: 'auto',
    isTablet: 'auto',
    isMobile: 'auto',
  },
};

const OUR_WORK_CONFIG = [
  {
    image: cesiumImage,
    title: 'Adding I3S Building Scene Layer Support for Cesium Engine',
    description: 'Frontend, Testing',
    path: '/casestudies-cesium',
  },
  {
    image: foursquareImage,
    title: 'Geospatial Data Conversions for ESRI',
    description: 'Frontend, Testing',
    path: '/casestudies-esri',
  },
  {
    image: aueousImage,
    title: 'Commercial Real-Estate Analytics & Investment',
    description: 'UI/UX, Frontend, Backend, Testing, Consulting',
    path: '/casestudies-fireproof',
  },
  {
    image: haydenImage,
    title: 'Technology-First Approach to Road Safety and Traffic Flow',
    description: 'UI/UX and Prototyping',
    path: '/casestudies-hayden',
  },
  {
    image: keplerImage,
    title: 'Improving and Optimizing Kepler.gl with Typescript',
    description: 'Backend, Testing',
    path: '/casestudies-kepler',
  },
  {
    image: deforestationImage,
    title: 'Monitoring Deforestation with Actionable Geospatial Data Analytics',
    description: 'UI/UX, Frontend, Backend, Testing',
    path: '/casestudies-deforestation',
  },
  // {
  //   image: ourInitiativesImage,
  //   title: 'Our Initiatives',
  //   description: 'UI/UX, ML/AI',
  //   path: '/work#our-initiatives',
  // },
];

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  height: 100vh;
  margin: 0;
  width: 100%;

  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 40px',
    isLaptop: '40px',
    isTablet: '56px 20px',
    isMobile: '40px 24px',
  })};

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none; /* Firefox 64 */
`;

const ListItem = styled.li`
  display: flex;
  justify-content: center;
  width: 100%;

  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'center',
    isLaptop: 'center',
    isTablet: 'center',
    isMobile: ' flex-start',
  })};

  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'row',
    isMobile: 'column',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};

  :last-child {
    margin-bottom: 0;
  }

  > img {
    align-self: flex-start;
  }

  :hover {
    text-decoration: underline;

    > img {
      box-shadow: 0px 45px 56px rgba(0, 0, 0, 0.09);
    }
  }

  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '0',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: '20vw',
    isLaptop: '20vw',
    isTablet: '50vw',
    isMobile: '100%',
  })};
`;

const Header = styled.a`
  color: #010101;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin-bottom: 10px;
  border: none;
  outline: unset;

  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora Medium',
    isLaptop: 'Kontora Medium',
    isTablet: 'Kontora Medium',
    isMobile: 'Kontora SemBd',
  })};

  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '700',
    isLaptop: '700',
    isTablet: '700',
    isMobile: '600',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '42px',
    isLaptop: '29px',
    isTablet: '42px',
    isMobile: '24px',
  })};

  line-height: ${getCurrentLayoutProperty({
    isDesktop: '120%',
    isLaptop: '140%',
    isTablet: '120%',
    isMobile: '150%',
  })};

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '24px',
  })};
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #333333;
  line-height: 140%;

  font-family: ${getCurrentLayoutProperty({
    isDesktop: 'Kontora Medium',
    isLaptop: 'Kontora Medium',
    isTablet: 'Kontora Medium',
    isMobile: 'Kontora SemBd',
  })};

  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '700',
    isLaptop: '700',
    isTablet: '700',
    isMobile: '600',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '17px',
    isLaptop: '17px',
    isTablet: '17px',
    isMobile: '13px',
  })};
`;

const OurWorkItem = ({item, layout, onClick}) => (
  <ListItem onClick={onClick} layout={layout}>
    <img
      width={getCurrentLayoutProperty(OUR_WORK_IMAGE_SIZE.width)({layout})}
      height={getCurrentLayoutProperty(OUR_WORK_IMAGE_SIZE.height)({layout})}
      src={getCurrentLayoutProperty(item.image)({layout})}
      alt="project picture"
      loading="eager"
    />
    <TextWrapper layout={layout}>
      <Header layout={layout}>{item.title}</Header>
      <Description layout={layout}>{item.description}</Description>
    </TextWrapper>
  </ListItem>
);

export const OurWorkList = ({onWorkItemClick}) => {
  const layout = useAppLayout();

  return (
    <Container layout={layout}>
      {OUR_WORK_CONFIG.map((item, index) => (
        <Linked
          key={index}
          onClick={onWorkItemClick}
          layout={layout}
          item={item}
          component={OurWorkItem}
          to={item.path}
        />
      ))}
    </Container>
  );
};

OurWorkList.propTypes = {
  onWorkItemClick: PropTypes.func,
};

OurWorkItem.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    path: PropTypes.string,
  }),
  layout: PropTypes.object,
  onClick: PropTypes.func,
};
