import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {SUBTITLE, vacanciesList, FOOTER_TEXT_ONE} from '../constants/sectionSix';
import {ArrowButton} from '../../../components/arrowButton';
import {VacanciesSlider} from './vacancies/vacancies-slider';
import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';
import BottomSheet from '../../../components/bottomSheet';
import VacancyPopup from './vacancy-popup/vacancy-popup';

const Wrapper = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
`;

const VacanciesTitle = styled.span`
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: #666666;
  margin-bottom: 20px;
`;

const SectionSubTitle = styled.h2`
  font-weight: 600;
  line-height: 150%;
  margin: 60px 0 24px 0;
  width: 100vw;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '42px',
    isLaptop: '42px',
    isTablet: '42px',
    isMobile: '24px',
  })};
`;

const CV_ADDRESS = 'mailto:Dmitry.Varzin@actionengine.com';

export const SectionFiveContent = () => {
  const layout = useAppLayout();
  const [vacancyOpen, setVacancyOpen] = useState(false);
  const [vacancy, setVacancy] = useState(null);
  const [CVopen, setCVOpen] = useState(false);

  const closeVacancy = useCallback(() => {
    setVacancyOpen(false);
    setVacancy(null);
  }, []);

  const openVacancy = (key) => {
    setVacancy(key);
    setVacancyOpen(true);
  };

  const openForm = useCallback(() => setCVOpen(true), []);
  const closeForm = useCallback(() => setCVOpen(false), []);

  const closeFormAndVacancy = () => {
    closeForm();
    closeVacancy();
  };

  const sendCv = () => (window.location = CV_ADDRESS);

  useEffect(() => !vacancyOpen && setVacancy(null), [vacancyOpen]);

  return (
    <>
      <Wrapper>
        <VacanciesTitle>{`${vacanciesList.length} vacancies`}</VacanciesTitle>
        <VacanciesSlider options={vacanciesList} openVacancy={openVacancy} vacancy={vacancy} />
        <SectionSubTitle layout={layout}>{SUBTITLE}</SectionSubTitle>
        <ArrowButton text={FOOTER_TEXT_ONE} backgroundColor={'red'} arrowColor={'white'} onClick={sendCv} />
      </Wrapper>
      {(layout.isDesktop || layout.isLaptop) && vacancyOpen && (
        <VacancyPopup
          {...vacancy}
          closeVacancy={closeVacancy}
          vacancyOpen={vacancyOpen}
          setVacancyOpen={setVacancyOpen}
          setVacancy={setVacancy}
          openForm={openForm}
          closeForm={closeForm}
          closeFormAndVacancy={closeFormAndVacancy}
          CVopen={CVopen}
          setCVOpen={setCVOpen}
        />
      )}
      {(layout.isTablet || layout.isMobile) && (
        <BottomSheet isOpen={vacancyOpen} onClose={closeVacancy} openForm={openForm} vacancy>
          <VacancyPopup {...vacancy} CVopen={CVopen} closeForm={closeForm} closeFormAndVacancy={closeFormAndVacancy} />
        </BottomSheet>
      )}
    </>
  );
};
