import React, {useMemo} from 'react';
import styled from 'styled-components';
import useAppLayout from '../../utils/useAppLayout';

import MainBanner from './components/MainBanner';
import TextBlock from './components/TextBlock';
import SloganPanel from './components/SloganPanel';
import CheckOurProjects from './components/CheckOurProjects';
import {getCurrentLayoutProperty} from '../../utils/common';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import Footer from '../../components/footer';

const gear = '/case-studies/aqueous/Gear.svg';
const union = '/case-studies/aqueous/Union.svg';

const tabletImage = {
  isDesktop: '/case-studies/aqueous/TabletImage/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/TabletImage/isLaptop.webp',
  isTablet: '/case-studies/aqueous/TabletImage/isTablet.webp',
  isMobile: '/case-studies/aqueous/TabletImage/isMobile.webp',
};

const thirdSectionFirstImg = {
  isDesktop: '/case-studies/aqueous/ThirdSectionFirstImg/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/ThirdSectionFirstImg/isLaptop.webp',
  isTablet: '/case-studies/aqueous/ThirdSectionFirstImg/isTablet.webp',
  isMobile: '/case-studies/aqueous/ThirdSectionFirstImg/isMobile.webp',
};

const forthSectionImg = {
  isDesktop: '/case-studies/aqueous/ForthSectionImg/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/ForthSectionImg/isLaptop.webp',
  isTablet: '/case-studies/aqueous/ForthSectionImg/isTablet.webp',
  isMobile: '/case-studies/aqueous/ForthSectionImg/isMobile.webp',
};

const thirdSectionSecondImg = '/case-studies/aqueous/ThirdSectionSecondImage.webp';

const fifthSectionImg = {
  isDesktop: '/case-studies/aqueous/FifthSectionImg/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/FifthSectionImg/isLaptop.webp',
  isTablet: '/case-studies/aqueous/FifthSectionImg/isTablet.webp',
  isMobile: '/case-studies/aqueous/FifthSectionImg/isMobile.webp',
};

const sixSectionImg = {
  isDesktop: '/case-studies/aqueous/SixSectionImg/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/SixSectionImg/isLaptop.webp',
  isTablet: '/case-studies/aqueous/SixSectionImg/isTablet.webp',
  isMobile: '/case-studies/aqueous/SixSectionImg/isMobile.webp',
};

const seventhSectionImg = {
  isDesktop: '/case-studies/aqueous/SeventhSectionImg/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/SeventhSectionImg/isLaptop.webp',
  isTablet: '/case-studies/aqueous/SeventhSectionImg/isTablet.webp',
  isMobile: '/case-studies/aqueous/SeventhSectionImg/isMobile.webp',
};

const mainBackgroundImage = {
  isDesktop: '/case-studies/aqueous/MainBackgroundImage/isDesktop.webp',
  isLaptop: '/case-studies/aqueous/MainBackgroundImage/isLaptop.webp',
  isTablet: '/case-studies/aqueous/MainBackgroundImage/isTablet.webp',
  isMobile: '/case-studies/aqueous/MainBackgroundImage/isMobile.webp',
};

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const Text = {
  upperHeader: <img src={'/pages/work/fireproof.webp'} alt="logo" />,
  mainHeader: 'Commercial Real-Estate Analytics & Investment',
  bages: ['product', 'web', 'mobile', 'real estate'],
  sectionHeader1: 'Fully transparent and data rich platform',
  sectionText1: [
    '“Fireproof” (we’ve changed their name until they leave stealth mode) is a marketplace, forecast predictor and trading platform for commercial real estate investment.  Enabling accredited investors to have direct access to a marketplace of potential opportunities tied to a predictive analysis tool to understand the potential performance of properties.',
    'Action Engine worked closely with “Fireproof” from their initial product concept, developing their entire workflow and analytics platform',
  ],
  sectionHeader2: 'Brand new product',
  sectionText2:
    'We’ve been working closely with the “Fireproof” founders to transform real-word commercial real estate investments model into digital platform while maintaining and making available all legal processes related to investments in the US market. To achieve this, we talked a lot with American accredited investors, lawyers and commercial real estate managers. As a result, we got a complex, but at the same time logical and understandable digital system with all workflows broken down in explained and illustrated steps.',
  sectionHeader3: 'Own predictive algorythm',
  sectionText3:
    'Users have access to analytics and forecasts regarding the price of each specific asset, which significantly improves the process of working with and investment in real estate',
  sectionHeader41: 'Iterative approach',
  sectionText41:
    'Through iterative sprints and layered product development phases, we built the initial business logic utilizing our partner technology Thealchemy.app platform developed by Wearehere.labs.  This allowed us to accelerate certain functions quickly to be able to internally test and refine.',
  sectionHeader42: 'Design Thinking',
  sectionText42:
    'Led through a Design-Thinking method, we defined user journeys based on targeted personas.  Workflows led into prototypes that could be tested with alpha users to further refine the usability and data appearance',
  sectionHeader5: 'Empowering decision making',
  sectionText5:
    'Users can make investments in real estate, form funds and partnerships, raise funds, see predicted market prices for potential investments.  They also have the ability to exchange and communicate performance information and track performance analytics of partnerships all through one centralized portal.',

  slogan1: {
    header: '<10 months',
    description: 'Left to launch',
    headerColor: '#000000',
    descriptionColor: '#000000',
    backgroundColor: '#11EAA3',
  },
  slogan2: {
    header: '>$20 million',
    description: 'Investment potential',
    headerColor: '#000000',
    descriptionColor: '#000000',
    backgroundColor: '#5AC7D5',
  },
  slogan3: {
    header: '“Just awesome”',
    description: 'Early adopters aggregated feedback',
    headerColor: '#FFFFFF',
    descriptionColor: '#FFFFFF',
    backgroundColor: '#010101',
  },
};

const sloganFragmentHeight = {
  isDesktop: '320px',
  isLaptop: '271px',
  isTablet: '271px',
  isMobile: '186px',
};

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const Gradient = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '311px',
    isLaptop: '230px',
    isTablet: '80px',
    isMobile: '50px',
  })};
  background-color: #5ac7d5;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    height: 100vw;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(222deg, #ffffff, #ffffff 25%, rgba(0, 0, 0, 0) 25%),
      linear-gradient(138deg, #ffffff, #ffffff 25%, rgba(0, 0, 0, 0) 25%);
  }

  &:after {
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    background: linear-gradient(26deg, #000000 50%, rgba(0, 0, 0, 0) 50%);
    z-index: 0;
  }

  & > img {
    position: relative;
    max-width: 90%;
    z-index: 1;
  }
`;

const ThirdSectionSecondImage = styled.img`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '126px',
    isLaptop: '82px',
    isTablet: '57px',
    isMobile: '24px',
  })};
`;

const ForthSection = styled.div`
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0px',
    isMobile: '0px',
  })};
  box-sizing: border-box;
  background-color: #000000;
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    height: 30vw;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0;
    background: linear-gradient(15deg, #ffffff 50%, rgba(0, 0, 0, 0) 50%);
    z-index: 0;
    left: 0;
  }

  & > img {
    margin-top: ${getCurrentLayoutProperty({
      isDesktop: '40px',
      isLaptop: '40px',
      isTablet: '0px',
      isMobile: '24px',
    })};
    position: relative;
    max-width: 90%;
    z-index: 1;
    align-self: center;
  }
  & > div {
    width: ${getCurrentLayoutProperty({
      isDesktop: '50%',
      isLaptop: '50%',
      isTablet: '100%',
      isMobile: '100%',
    })};
  }
`;

const FifthSection = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '240px',
    isLaptop: '126px',
    isTablet: '254px',
    isMobile: '81px',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 0;
  }

  & > img:last-of-type {
    margin-top: ${getCurrentLayoutProperty({
      isDesktop: '0px',
      isLaptop: '0px',
      isTablet: '289px',
      isMobile: '140px',
    })};
    position: relative;
    max-width: 90%;
    z-index: 1;
    align-self: center;
  }
  & > div {
    align-self: flex-end;
    width: ${getCurrentLayoutProperty({
      isDesktop: '50%',
      isLaptop: '50%',
      isTablet: '100%',
      isMobile: '100%',
    })};
  }
`;

const sixSectionGradientAngle = {
  isDesktop: '90',
  isLaptop: '90',
  isTablet: '6',
  isMobile: '6',
};

const SixSection = styled.div`
  position: relative;
  background: linear-gradient(${getCurrentLayoutProperty(sixSectionGradientAngle)}deg, #000000 50%, #11eaa3 50%);
  display: grid;
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr',
    isLaptop: '1fr 1fr',
    isTablet: '1fr',
    isMobile: '1fr',
  })};
  ${getCurrentLayoutProperty({
    isDesktop: 'grid-template-areas: "text2nd text1st" "img img";',
    isLaptop: 'grid-template-areas: "text2nd text1st" "img img";',
    isTablet: 'grid-template-areas: "text1st" "img" "text2nd";',
    isMobile: 'grid-template-areas: "text1st" "img" "text2nd";',
  })}

  &:before {
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    background: linear-gradient(26deg, #000000 50%, rgba(0, 0, 0, 0) 50%);
    z-index: 0;
  }

  & > img {
    position: relative;
    margin: auto;
  }

  & > div {
    padding: ${getCurrentLayoutProperty({
      isDesktop: '80px',
      isLaptop: '80px',
      isTablet: '80px 107px 0 40px',
      isMobile: '40px 16px 8px 16px',
    })};
  }
`;

const BackgroundElement = styled.img`
  position: absolute !important;
`;

const SeventhSection = styled.div`
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0px',
    isMobile: '0px',
  })};
  background: linear-gradient(170deg, #000000 50%, #5ac7d5 50%);
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
`;

const SevenSectImage = styled.img`
  width: 100%;
  height: ${getCurrentLayoutProperty({
    isDesktop: '1576px',
    isLaptop: '1088px',
    isTablet: 'auto',
    isMobile: 'auto',
  })}; ;
`;

const CaseStudiesAqueous = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;

  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);
  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        backgroundImage={mainBackgroundImage}
        upperHeader={<MainBannerUpperHeader>{Text.upperHeader}</MainBannerUpperHeader>}
        mainHeader={Text.mainHeader}
        bages={Text.bages}
        tabletImage={tabletImage}
      />
      <TextBlockComponent.Text
        headerText={Text.sectionHeader1}
        mainText={Text.sectionText1}
        color="#010101"
        headerColor="#000000"
      />
      <Gradient layout={layout}>
        <BackgroundElement
          src={gear}
          alt="gear"
          style={{
            width: '20%',
            left: getCurrentLayoutProperty({
              isDesktop: '11%',
              isLaptop: '23%',
              isTablet: '10%',
              isMobile: '16%',
            })({layout}),
            top: getCurrentLayoutProperty({
              isDesktop: '-200px',
              isLaptop: '-200px',
              isTablet: '-80px',
              isMobile: '-40px',
            })({layout}),
          }}
        />
        <ThirdSectionSecondImage
          layout={layout}
          src={getCurrentLayoutProperty(thirdSectionFirstImg)({layout})}
          alt="second background"
        />
        <img src={thirdSectionSecondImg} alt="background picture" />
      </Gradient>
      <ForthSection layout={layout}>
        <TextBlock headerText={Text.sectionHeader2} mainText={Text.sectionText2} color="#ffffff" />
        <img src={getCurrentLayoutProperty(forthSectionImg)({layout})} alt="background picture" />
      </ForthSection>
      <FifthSection layout={layout}>
        <BackgroundElement
          src={union}
          alt="union"
          style={{
            width: getCurrentLayoutProperty({
              isDesktop: '40%',
              isLaptop: '40%',
              isTablet: '50%',
              isMobile: '50%',
            })({layout}),
            left: '0%',
            top: getCurrentLayoutProperty({
              isDesktop: '0%',
              isLaptop: '0%',
              isTablet: '30%',
              isMobile: '36%',
            })({layout}),
          }}
        />
        <TextBlock headerText={Text.sectionHeader3} mainText={Text.sectionText3} color="#010101" />
        <img src={getCurrentLayoutProperty(fifthSectionImg)({layout})} alt="background picture" loading="lazy" />
      </FifthSection>
      <SixSection layout={layout}>
        <TextBlock
          style={{gridArea: 'text1st'}}
          headerText={Text.sectionHeader41}
          mainText={Text.sectionText41}
          color="#010101"
        />
        <img
          src={getCurrentLayoutProperty(sixSectionImg)({layout})}
          style={{gridArea: 'img'}}
          alt="background picture"
          loading="lazy"
        />
        <TextBlock
          style={{gridArea: 'text2nd'}}
          headerText={Text.sectionHeader42}
          mainText={Text.sectionText42}
          color="#ffffff"
          headerColor="#11EAA3"
        />
      </SixSection>
      <SeventhSection layout={layout}>
        <TextBlock
          style={{gridArea: 'text2nd'}}
          headerText={Text.sectionHeader5}
          mainText={Text.sectionText5}
          color="#ffffff"
        />
        <SevenSectImage
          layout={layout}
          src={getCurrentLayoutProperty(seventhSectionImg)({layout})}
          alt="section background"
        />
      </SeventhSection>
      <SloganPanel
        slogan1={Text.slogan1}
        slogan2={Text.slogan2}
        slogan3={Text.slogan3}
        height={getCurrentLayoutProperty(sloganFragmentHeight)({layout})}
      />
      <CheckOurProjects currentProjectName={'aqueous'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesAqueous;
