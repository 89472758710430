import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import TextBlock from '../../case-studies/components/TextBlock';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4e11b;
  margin-top: 80px;

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '40px',
    isMobile: '40px',
  })};

  padding: ${getCurrentLayoutProperty({
    isDesktop: '100px',
    isLaptop: '100px',
    isTablet: '100px 100px 60px 100px',
    isMobile: '40px',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  max-width: 1925px;
`;

const headerFontSizes = {
  isDesktop: '86px',
  isLaptop: '50px',
  isTablet: '50px',
  isMobile: '29px',
};

const textFontSizes = {
  isDesktop: '35px',
  isLaptop: '24px',
  isTablet: '24px',
  isMobile: '17px',
};

const TechnologyBanner = () => {
  const layout = useAppLayout();

  return (
    <Container layout={layout}>
      <TextBlock
        headerText="Your Ideal Technology Partner"
        mainText={`
          Through our well-rounded set of capabilities and platforms,
          we can accelerate your geospatial project or enhance your
          existing systems.  We work as an extension to your
          team or as your core technology provider, ensuring
          strong success in any work we take on together.`}
        color="black"
        headerColor="black"
        mainTextOpacity={'1'}
        headerFontSize={getCurrentLayoutProperty(headerFontSizes)({layout})}
        textFontSize={getCurrentLayoutProperty(textFontSizes)({layout})}
        style={{padding: 0}}
      />
    </Container>
  );
};

export default TechnologyBanner;
