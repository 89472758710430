import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '40px 92px 15vh 92px',
    isLaptop: '40px 92px 15vh 92px',
    isTablet: '40px 92px 50px 92px',
    isMobile: '40px 16px 50px 16px',
  })};
`;

const Title = styled.h1`
  font-weight: bold;
  line-height: 133%;
  margin-bottom: 0;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '40px',
  })};
`;

const SubTitle = styled.p`
  font-family: Kontora ExtraBold;
  font-weight: 800;
  line-height: 120%;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '22px',
    isLaptop: '22px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const Option = styled.li`
  font-family: Kontora SemBd;
  font-weight: 400;
  line-height: 150%;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '22px',
    isLaptop: '22px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

export const VacancyContent = ({title, content}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <Title layout={layout}>{title}</Title>
      {content?.map((el, i) => (
        <div key={`vacancy-${i}`}>
          <SubTitle layout={layout}>{el.title}</SubTitle>
          {el.options.map((item) => (
            <Option key={item} layout={layout}>
              {item}
            </Option>
          ))}
        </div>
      ))}
    </Wrapper>
  );
};

VacancyContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
};
