import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {InputWrapper, Label} from './form-input';
import {ANIMATE_FROM_BOTTOM} from '../../pages/home/constants/common';
import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';

const TextArea = styled.textarea`
  height: 160px;
  resize: none;
  flex: 1 1 auto;
  border: none;
  font-size: 100%;
  text-shadow: none;
  padding: 22px 20px;
  text-shadow: none;
  align-items: center;
  padding: 13px 12px 13px 16px;
  font-family: Kontora Medium;
  font-style: normal;
  font-weight: 400;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '20px',
    isMobile: '17px',
  })};
  line-height: 120%;
  font-color: #cccccc;
  margin-bottom: 30px;
`;

export const FormTextArea = React.forwardRef(({label, placeholder, register}, ref) => {
  const layout = useAppLayout();

  return (
    <InputWrapper>
      <Label layout={layout}>{label}</Label>
      <TextArea type="text" layout={layout} placeholder={placeholder} {...register('Details')} />
    </InputWrapper>
  );
});

FormTextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
};
