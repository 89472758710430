import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ArrowButton} from '../../../../components/arrowButton';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: ${getCurrentLayoutProperty({
    isDesktop: '100%',
    isLaptop: '100%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '15vh',
    isLaptop: '15vh',
    isTablet: '84px',
    isMobile: '80px',
  })};
  justify-content: center;
  gap: 25px;
  align-items: center;
  align-self: flex-end;
  margin-right: ${getCurrentLayoutProperty({
    isTablet: '20px',
    isMobile: '0',
  })};
  padding-top: ${getCurrentLayoutProperty({
    isTablet: '20px',
    isMobile: '0',
  })};
`;

export const MemberControls = ({getPrevMember, getNextMember, enablePrevButton, enableNextButton}) => {
  const layout = useAppLayout();

  return (
    <NavButtons layout={layout}>
      <ArrowButton iconType={'left'} onClick={getPrevMember} disabled={!enablePrevButton} />
      <ArrowButton onClick={getNextMember} disabled={!enableNextButton} />
    </NavButtons>
  );
};

MemberControls.propTypes = {
  getNextMember: PropTypes.func,
  getPrevMember: PropTypes.func,
  enableNextButton: PropTypes.bool,
  enablePrevButton: PropTypes.bool,
};
