import React, {useMemo} from 'react';
import useAppLayout from '../../utils/useAppLayout';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import TextBlock from './components/TextBlock';
import styled from 'styled-components';
import MainBanner from './components/MainBanner';
import {getCurrentLayoutProperty} from '../../utils/common';
import ColoredBanner from './components/ColoredBanner';
import CheckOurProjects from './components/CheckOurProjects';
import Footer from '../../components/footer';
import VideoPlayer from './components/VideoPlayer';

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const Text = {
  upperHeader: <img src={'/pages/work/cesium.webp'} alt="logo" />,
  mainHeader: 'Adding I3S Building Scene Layer Support for Cesium Engine',
  bages: ['open-source', 'web', 'i3s', 'cesium'],
  sectionHeader1: (
    <>
      Enhancing Mapping
      <br />
      Experience
    </>
  ),
  sectionText1: [
    'CesiumJS is a powerful open-source toolset for creating  interactive maps right in a web browser. In many cases, it is a go-to tool for building applications focused on visualizing and interacting with geographical data',
    'Building Scene Layer (BSL) is a unique data format made by ESRI. It allows visualizing detailed 3D buildings on top of the 3D maps with deep support of visualizing individual buildings’ floors and filtering on and off windows, ceilings, and other architectural, electrical, and structural attributes',
    'Without native support of BSL, it was impossible to include high-fidelity 3D buildings visualizations in applications, build on top of the CesiumJS. Working closely both with ESRI and Cesium teams, we’ve provided an easy way for developers to import BSL data into CesiumJS for enhancing their options when building geospatial applications',
  ],
  sectionHeader2: 'Seamless BSL Integration',
  sectionText2:
    'Main goal of the project was to support the latest format of BSL datasets, including all their attributes and sub-layers. So after the integration, CesiumJS became capable of filtering out building’s walls, windows, doors, etc., or by specific features – like showing only building specific floor. This provided the experience native to what most of developers who work closely with BSL format are used to.',
  sectionHeader3: 'Avoiding Tower of Pisa Experience',
  sectionText3:
    'In almost 100% cases, BSL visualizations on top of the Cesium’s terrains show poor vertical alignment with each other, and buildings tend to lean from the surface. To avoid that, we moved from Cesium terrain to the one from ArcGIS Terrain Provider, that worked perfectly with all of the BSL datasets.',
  sectionHeader41: 'Generating Geometry Normals for Higher Materials Quality',
  sectionText41:
    'Without normals in dataset, building visualization is unable to generate high quality materials that react to the lighting and environment. By standard, if there’s no normals in the source dataset, they should be produced automatically – however, CesiumJS doesn’t have that feature by default. We build this in specifically for BSL data format to make sure this kind of visualization works flawlessly',
  sectionHeader42: 'Adding Material Transparency Support for More Realistic Imagery',
  sectionText42:
    'In previous implementation, CesiumJS could not work with transparent materials along with non-transparent – like windows and the walls. For the better quality of the visuals, we broke materials into two categories, transparent and non-transparent, and focused on only showing transparency where it made sense',
  sectionHeader5_1: (
    <>
      Generating Fun
      <br />
      Visuals With
      <br />
      Edges Highlight
    </>
  ),
  sectionHeader5_2: (
    <>
      Generating Fun Visuals
      <br />
      With Edges Highlight
    </>
  ),
  sectionHeader5_3: (
    <>
      Generating Fun
      <br />
      Visuals With Edges
      <br />
      Highlight
    </>
  ),
  sectionText5:
    'Just for cool visual effects, we implemented the feature where if the source dataset has enough information, platform provides an option to automatically highlight edges of the building',

  banner1Header: '+20% Support In Data Formats',
  banner1Text: 'between CesiumJS and ESRI, as two of the leading players in the GIS space ',
  banner2Header: '40% Share of BSL Data Formats',
  banner2Text:
    'across all GIS data formats. Now with support in CesiumJS, it leads to more unique solutions out of open technologies',
  banner3Header: 'Better Collaboration in Open-Source',
  banner3Text:
    'We connected two big players in open-source GIS, leading to more and higher quality contributions in the future',
};

const tabletImage = {
  isDesktop: '/case-studies/cesium/TabletImage/isDesktop.webp',
  isLaptop: '/case-studies/cesium/TabletImage/isLaptop.webp',
  isTablet: '/case-studies/cesium/TabletImage/isTablet.webp',
  isMobile: '/case-studies/cesium/TabletImage/isMobile.webp',
};

const mainBackgroundImage = {
  isDesktop: '/case-studies/cesium/MainBackgroundImage/isDesktop.webp',
  isLaptop: '/case-studies/cesium/MainBackgroundImage/isLaptop.webp',
  isTablet: '/case-studies/cesium/MainBackgroundImage/isTablet.webp',
  isMobile: '/case-studies/cesium/MainBackgroundImage/isMobile.webp',
};

const deviceImage = {
  isDesktop: '/case-studies/cesium/DeviceImage/isDesktop.webp',
  isLaptop: '/case-studies/cesium/DeviceImage/isLaptop.webp',
  isTablet: '/case-studies/cesium/DeviceImage/isTablet.webp',
  isMobile: '/case-studies/cesium/DeviceImage/isMobile.webp',
};

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const ColoredContainer = styled.div`
  background-color: #f4e11b;
`;

const TwoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TwoGradientContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
  }

  &::before {
    left: 0;
    margin-top: -1px;
    background: linear-gradient(165deg, black 50%, transparent 50%);
  }

  &::after {
    right: 0;
    margin-top: -1px;
    background: linear-gradient(195deg, #713df5 50%, transparent 50%);
  }
`;

const BannersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const DeviceGradient = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #ffffff;

  &:before {
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(
      ${(props) => props.direction || 'to right'},
      #ffffff 50%,
      ${(props) => props.color || '#F4E11B'} 50%
    );
    // this removes the white gap between components
    transform: scale(1.01);
  }

  & > img {
    position: relative;
    max-width: 100%;
    padding-left: ${getCurrentLayoutProperty({
      isDesktop: '92px',
      isLaptop: '60px',
      isTablet: '92px',
      isMobile: '16px',
    })};
    padding-right: ${getCurrentLayoutProperty({
      isDesktop: '92px',
      isLaptop: '60px',
      isTablet: '92px',
      isMobile: '16px',
    })};
    z-index: 1;
  }
`;

const FunVisualsContainer = styled.div`
  display: flex;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '-100px',
    isLaptop: '-100px',
    isTablet: '0',
    isMobile: '0',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1258px',
    isLaptop: '876px',
    isTablet: '1150px',
    isMobile: '750px',
  })};
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '40px',
    isMobile: '24px',
  })};
  flex-direction: ${(props) => (props.isDesktopType ? 'row' : 'column')};
  align-items: ${(props) => (props.isDesktopType ? 'center' : 'left')};
  justify-content: ${(props) => (props.isDesktopType ? 'left' : 'top')};

  &::after {
    content: '';
    height: ${getCurrentLayoutProperty({
      isDesktop: '1098px',
      isLaptop: '716px',
      isTablet: '614px',
      isMobile: '312px',
    })};
    width: ${getCurrentLayoutProperty({
      isDesktop: '2000px',
      isLaptop: '2000px',
      isTablet: 'calc(100vw - 92px)',
      isMobile: 'calc(100vw - 16px)',
    })};
    transform: ${getCurrentLayoutProperty({
      isDesktop: 'rotate(0deg)',
      isLaptop: 'rotate(0deg)',
      isTablet: 'translateY(-3%)',
      isMobile: 'translate(12px, 15%)',
    })};
    background-image: url(${'/videos/cesium2.gif'});
    background-position: right;
    background-size: cover;
    overflow: hidden;
    box-shadow: ${getCurrentLayoutProperty({
      isDesktop: '0 0 5px rgb(0, 0, 0), 0 0 8px rgb(20, 20, 20), 0 0 12px rgb(40, 40, 40), 0 0 0 16px rgb(60, 60, 60)',
      isLaptop: '0 0 2px rgb(0, 0, 0), 0 0 4px rgb(20, 20, 20), 0 0 8px rgb(40, 40, 40), 0 0 0 10px rgb(60, 60, 60)',
      isTablet: '0 0 2px rgb(0, 0, 0), 0 0 4px rgb(20, 20, 20), 0 0 8px rgb(40, 40, 40), 0 0 0 10px rgb(60, 60, 60)',
      isMobile: '0 0 2px rgb(0, 0, 0), 0 0 4px rgb(20, 20, 20), 0 0 8px rgb(40, 40, 40), 0 0 0 10px rgb(60, 60, 60)',
    })};
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    z-index: -1;
  }

  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '60px',
    isTablet: '92px',
    isMobile: '16px',
  })};
`;

const SecondSectionGradient = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getCurrentLayoutProperty({
    isTablet: '40px',
    isMobile: '24px',
  })};
  padding-bottom: ${getCurrentLayoutProperty({
    isTablet: '40px',
    isMobile: '24px',
  })};
  position: relative;
  margin-top: -1px;

  &:before {
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(347deg, #713df5 80%, black 80%);
  }

  & > img {
    position: relative;
    max-width: 90%;
    z-index: 1;
  }
`;

const FinalGradient = styled.div`
  height: ${getCurrentLayoutProperty({
    isTablet: '201px',
    isMobile: '121px',
  })};
  position: relative;
  margin-top: -1px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left bottom, #713df5 50%, white 50%);
    margin-top: -1px;
  }
`;

const GeometryMobileText = styled.div`
  padding: ${getCurrentLayoutProperty({
    isTablet: '40px 0',
    isMobile: '24px 0',
  })};
  background: black;
`;

const GeometryNormalsComponentMobile = () => {
  const layout = useAppLayout();

  return (
    <>
      <GeometryMobileText layout={layout} backgroundColor="black">
        <TextBlock headerText={Text.sectionHeader41} mainText={Text.sectionText41} color="white" headerColor="white" />
      </GeometryMobileText>
      <SecondSectionGradient layout={layout}>
        <VideoPlayer layot={layout} style={{maxWidth: '90%'}} src="/videos/cesium1.gif" shifted={false} />
        <TextBlock headerText={Text.sectionHeader42} mainText={Text.sectionText42} color="white" headerColor="white" />
      </SecondSectionGradient>
      <FinalGradient layout={layout} />
    </>
  );
};

const TextContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '112px',
    isLaptop: '112px',
  })};
  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '60px',
  })};
  padding-right: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '60px',
  })};
`;

const GeometryNormalsComponentDesktop = () => {
  const layout = useAppLayout();
  return (
    <>
      <TwoTextContainer>
        <TextContainer layout={layout} style={{width: '50%'}} backgroundColor="black">
          <TextBlock
            headerText={Text.sectionHeader41}
            mainText={Text.sectionText41}
            color="white"
            headerColor="white"
          />
        </TextContainer>
        <TextContainer layout={layout} backgroundColor="#713DF5" style={{width: '50%'}}>
          <TextBlock
            headerText={Text.sectionHeader42}
            mainText={Text.sectionText42}
            color="white"
            headerColor="white"
          />
        </TextContainer>
      </TwoTextContainer>
      <TwoGradientContainer>
        <VideoPlayer layot={layout} style={{maxWidth: '90%'}} src="/videos/cesium1.gif" />
      </TwoGradientContainer>
    </>
  );
};

const CaseStudiesCesium = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;
  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);

  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        largeTitle
        backgroundImage={mainBackgroundImage}
        upperHeader={<MainBannerUpperHeader>{Text.upperHeader}</MainBannerUpperHeader>}
        mainHeader={Text.mainHeader}
        bages={Text.bages}
        tabletImage={tabletImage}
      />
      <TextBlockComponent.Text
        headerText={Text.sectionHeader1}
        mainText={Text.sectionText1}
        color="#010101"
        headerColor="#000000"
      />
      <DeviceGradient direction="to right bottom" layout={layout}>
        <img src={getCurrentLayoutProperty(deviceImage)({layout})} alt="device image" loading="lazy" width={'100%'} />
      </DeviceGradient>
      <ColoredContainer>
        <TextBlock
          headerText={Text.sectionHeader2}
          mainText={Text.sectionText2}
          color="#010101"
          headerColor="#000000"
          style={{
            maxWidth: '800px',
            margin: getCurrentLayoutProperty({
              isDesktop: '112px 92px',
              isLaptop: '112px 60px',
              isTablet: '40px 0 0 0',
              isMobile: '24px 0px',
            })({layout}),
          }}
        />
      </ColoredContainer>
      <DeviceGradient direction="to right top">
        <VideoPlayer layot={layout} style={{maxWidth: '90%'}} src="/videos/cesium3.gif" shifted={false} />
      </DeviceGradient>
      <TextBlock
        headerText={Text.sectionHeader3}
        mainText={Text.sectionText3}
        color="#010101"
        headerColor="#000000"
        style={{
          marginRight: 'auto',
          maxWidth: getCurrentLayoutProperty({
            isDesktop: '45%',
            isLaptop: '70%',
            isTablet: '100%',
            isMobile: '100%',
          })({layout}),
          margin: getCurrentLayoutProperty({
            isDesktop: '112px 92px',
            isLaptop: '112px 60px',
            isTablet: '40px 0 -40px 0',
            isMobile: '24px 0 15px 0',
          })({layout}),
        }}
      />
      {isDesktopType ? <GeometryNormalsComponentDesktop /> : <GeometryNormalsComponentMobile />}
      <FunVisualsContainer isDesktopType={isDesktopType} layout={layout}>
        <TextBlock
          headerText={getCurrentLayoutProperty({
            isDesktop: Text.sectionHeader5_1,
            isLaptop: Text.sectionHeader5_2,
            isTablet: Text.sectionHeader5_2,
            isMobile: Text.sectionHeader5_3,
          })({layout})}
          mainText={Text.sectionText5}
          color="#010101"
          headerColor="#000000"
          style={{
            paddingLeft: '0px',
            maxWidth: isDesktopType ? '50%' : '100%',
            marginRight: getCurrentLayoutProperty({
              isDesktop: '160px',
              isLaptop: '60px',
              isTablet: '0px',
              isMobile: '0px',
            })({layout}),
          }}
        />
      </FunVisualsContainer>
      <BannersWrapper layout={layout}>
        <ColoredBanner
          headerText={Text.banner1Header}
          contentText={[Text.banner1Text]}
          backgrounColor={'#f4e11b'}
          textColor={'black'}
        />
        <ColoredBanner
          headerText={Text.banner2Header}
          contentText={[Text.banner2Text]}
          backgrounColor={'#713DF5'}
          textColor={'white'}
        />
        <ColoredBanner
          headerText={Text.banner3Header}
          contentText={[Text.banner3Text]}
          backgrounColor={'#010101'}
          textColor={'white'}
        />
      </BannersWrapper>
      <CheckOurProjects currentProjectName={'cesium'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesCesium;
