import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useResolvedPath, useMatch, useNavigate} from 'react-router-dom';

const Linked = ({component: Component, to, onClick, ...others}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    onClick?.();
    navigate(to);
  }, [navigate, to, onClick]);

  return <Component onClick={handlePress} isActive={!!match} {...others} />;
};

Linked.propTypes = {
  component: PropTypes.elementType,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Linked;
