import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import {Header, Text} from './TextBlock';

const Wrapper = styled.div`
  background: ${(props) => props.backgrounColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  min-height: ${(props) =>
    props.minHeight
      ? getCurrentLayoutProperty({
          isDesktop: '320px',
          isLaptop: '280px',
          isTablet: '320px',
          isMobile: '320px',
        })
      : 'auto'};
  height: auto;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 56px',
    isLaptop: '64px 40px',
    isTablet: '40px 40px',
    isMobile: '24px 40px',
  })};
`;

const Title = styled(Header)`
  margin: 0;
`;

const TextContent = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const ColoredBanner = ({backgrounColor, textColor, headerText, contentText, minHeight}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout} backgrounColor={backgrounColor} textColor={textColor} minHeight={minHeight}>
      <Title color={textColor} layout={layout}>
        {headerText}
      </Title>
      <TextContent color={textColor} layout={layout}>
        {contentText}
      </TextContent>
    </Wrapper>
  );
};

ColoredBanner.propTypes = {
  backgrounColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  contentText: PropTypes.array.isRequired,
  minHeight: PropTypes.bool,
};

export default ColoredBanner;
