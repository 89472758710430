export const SUBTITLE_1 = 'Founders';
export const SUBTITLE_2 = 'The Team';

const MELKOFF = {
  work: [
    'Leading team of data scientists',
    'Architected, designed, and implemented a cloud platform for geospatial data analytics and machine learning',
    'Providing technical expertise and leadership for data-driven projects',
    'Maintenance of cloud infrastructure and services',
    'Mentoring for junior engineers',
  ],
  skills: ['Machine Learning', 'Data Engineering', 'Data Analytics', 'Systems Design', 'DevOps', 'Python Programming'],
};

const VOZNESENSKAYA = {
  work: ['Worked in a team  to provide  design solutions for geospatial systems', 'Creating a component design system'],
  skills: ['Design Thinking', 'User Research', 'Prototyping', 'Visual Design', 'Figma'],
};

const VARZIN = {
  work: ['Recruiting', 'Working out HR strategy', 'Administrative work'],
  skills: [
    'Technical recruitment',
    'Organizational development',
    'Talent acquisition',
    'Employee Relations',
    'HR reporting',
  ],
};

const MESCHERYAKOV = {
  work: [
    'Passionate Jack of all trades for the UX team',
    'As a team player: prepared an autonomous traffic management platform for scaling and significant user growth; launched a couple of projects, including very secret ones, from scratch to first investment',
    'Solely: writing about Creative UX and Product Design in my mini-blog, developing skills in no-code development and so many other things',
  ],
  skills: [
    'Design Thinking',
    'User Research',
    'Rapid Prototyping',
    'Visual Design',
    'React Native',
    'Main tools: Figma / Framer / Webflow / Flutterflow',
  ],
};

const GORBACHEV = {
  work: [
    'Contributing to kepler.gl development',
    'Developed a server for displaying tilesets',
    'Developed smart contracts for an Ethereum network',
    'Training and mentoring new employees',
  ],
  skills: ['Solidity', 'Node.js', 'React.js', 'Typescript'],
};

const KIRILLOV = {
  work: ['Support of open source implementation of I3S specification, including data visualization and debug tool'],
  skills: ['JS', 'React JS', 'CSS', 'HTML'],
};

const BELOMESTNOV = {
  work: [
    'Created tile-converter for conversion 3DTiles datasets to I3S',
    '2 years support of open source implementation of I3S specification, including data visualization and debug tool',
    'Contributor of vis.gl GitHub organization and release publisher',
  ],
  skills: [
    'JavaScript, TypeScript, ECMAScript 2015+',
    'React.js',
    'Node.js',
    'I3S, 3DTiles, glTF, Draco, Basis',
    'WebGL',
    'Web Workers',
  ],
};

const SADRETDINOV = {
  work: ['QA automation', 'Manual software testing'],
  skills: ['Creation of autotests', 'Manual testing skills', 'Rest API/Postman', 'Python'],
};

const LEPIGINA = {
  work: [
    'In progress of building geo data preprocessing and visualization pipeline',
    'Provided data-analytics for Geo-services',
  ],
  skills: ['Data Science', 'Python'],
};

const TEREKHOVA = {
  work: [
    'Participated in development of B2B online-shop, meeting scheduling system, CRM system',
    'Participated in translation of a large WebGL library from JavaScript to TypeScript',
  ],
  skills: ['ReactJS', 'HTML/JS/CSS', 'Typescript'],
};

const RYABININ = {
  work: [
    'Contributor of loaders.gl deck.gl luma.gl projects',
    'Tech lead on retail management system project',
    'Mentor for JS developers',
  ],
  skills: ['JavaScript', 'TypeScript', 'React JS', 'CSS', 'Node.js', 'WebGL', 'Styled Components', 'Recoil', 'GIT'],
};

const BOLONICHEVA = {
  work: [
    'Contributor of loaders.gl and kepler.gl projects',
    'Web development using React and WebGL based frameworks',
    'Visualization of geospatial data',
  ],
  skills: [
    'HTML, CSS, styled-components',
    'JavaScript, TypeScript',
    'three.js, deck.gl, kepler.gl, ammo.js',
    'React, React-three-fiber',
    'Recoil',
    'Next.js',
    'Webpack',
  ],
};

const SAVINOV = {
  work: [
    'Manages Sales activities for Action Engine',
    'Acquires new international clients',
    'Creates value propositions: solution-dedicated websites, service offerings',
    'Scrum management of a Sales team to create and test new sales and brand development ideas and strategies',
    'Acts as an IT Project Manager for internal and external Action Engine projects',
  ],
  skills: [
    'Sales strategy',
    'Lead acquisition',
    'Company and Service value proposition',
    'Sales Offer and Project presentation',
    'IT Brand management',
    'Project management, Agile methodologies',
  ],
};

export const membersList = [
  {
    id: 0,
    photo: '/team/Varzin.webp',
    name: 'Dmitry Varzin',
    position: 'HR and recruitment',
    work: VARZIN.work,
    skills: VARZIN.skills,
  },
  {
    id: 1,
    photo: '/team/Savinov.webp',
    name: 'Daniil Savinov',
    position: 'Sales and PM',
    work: SAVINOV.work,
    skills: SAVINOV.skills,
  },
  {
    id: 2,
    photo: '/team/Lepigina.webp',
    name: 'Anastasia Lepigina',
    position: 'Data Scientist',
    work: LEPIGINA.work,
    skills: LEPIGINA.skills,
  },
  {
    id: 3,
    photo: '/team/Mescheryakov.webp',
    name: 'Ilya Mescheryakov',
    position: 'UX Designer',
    work: MESCHERYAKOV.work,
    skills: MESCHERYAKOV.skills,
  },
  {
    id: 4,
    photo: '/team/Voznesenskaya.webp',
    name: 'Anna Voznesenskaya',
    position: 'UX Designer',
    work: VOZNESENSKAYA.work,
    skills: VOZNESENSKAYA.skills,
  },
  {
    id: 5,
    photo: '/team/Gorbachev.webp',
    name: 'Mikhail Gorbachev',
    position: 'Software Developer',
    work: GORBACHEV.work,
    skills: GORBACHEV.skills,
  },
  {
    id: 6,
    photo: '/team/Terekhova.webp',
    name: 'Daria Terekhova',
    position: 'Software Developer',
    work: TEREKHOVA.work,
    skills: TEREKHOVA.skills,
  },
  {
    id: 7,
    photo: '/team/Belomestnov.webp',
    name: 'Victor Belomestnov',
    position: 'Tech Lead',
    work: BELOMESTNOV.work,
    skills: BELOMESTNOV.skills,
  },
  {
    id: 8,
    photo: '/team/Ryabinin.webp',
    name: 'Daniil Ryabinin',
    position: 'Tech Lead',
    work: RYABININ.work,
    skills: RYABININ.skills,
  },
  {
    id: 9,
    photo: '/team/Kirillov.webp',
    name: 'Zakhar Kirillov',
    position: 'Software Developer',
    work: KIRILLOV.work,
    skills: KIRILLOV.skills,
  },
  {
    id: 10,
    photo: '/team/Bolonicheva.webp',
    name: 'Lera Bolonicheva',
    position: 'Software Developer',
    work: BOLONICHEVA.work,
    skills: BOLONICHEVA.skills,
  },
  {
    id: 11,
    photo: '/team/Sadretdinov.webp',
    name: 'Ilshat Sadretdinov',
    position: 'QA Engineer',
    work: SADRETDINOV.work,
    skills: SADRETDINOV.skills,
  },
  {
    id: 12,
    photo: '/team/Melkoff.webp',
    name: 'Alexander Melkoff',
    position: 'Tech Lead',
    work: MELKOFF.work,
    skills: MELKOFF.skills,
  },
];

export const MEMBER_PREVIEW_FIRST_TITLE = 'WORK';
export const MEMBER_PREVIEW_SECOND_TITLE = 'SKILLS';
