import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import SectionOne from './components/sectionOne';
import SectionTwo from './components/sectionTwo';
import ReviewsSection from './components/reviews-section/reviews-section';
import Footer from '../../components/footer';

// TODO rename
const Position = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: 0,
    isMobile: 0,
  })};
`;

const Work = () => {
  const layout = useAppLayout();

  return (
    <Position layout={layout}>
      <SectionOne />
      <SectionTwo headerId="case-studies" />
      {/*<ReviewsSection />*/}
      <Footer />
    </Position>
  );
};

export default Work;
