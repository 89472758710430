import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Kontora ExtraBold";
    src: url("/fonts/KontoraExtraBold.otf");
    font-style: normal;
    font-weight: 800;
    font-display: swap;
  }

  @font-face {
    font-family: "Kontora Medium";
    src: url("/fonts/KontoraMedium.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Kontora SemBd";
    src: url("/fonts/Kontora-SemiBold.otf");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
  }

  #app {
    overflow: hidden
  }

  html, body {
    -webkit-overflow-scrolling : touch !important;
  }

  html {
    overflow-y: hidden;
    height: -webkit-fill-available;
  }

  body {
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.black};
    font-feature-settings: 'ss01' on;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
    overflow-x: hidden;
    font-family: Kontora Medium;
  }

  html, body {
    -webkit-overflow-scrolling : touch !important;
  }

  [data-is-fullscreen="true"] .globe {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  textarea, input {
    background: #f6f6f6;
    border-radius: 4px;
  }

  textarea:hover, input:hover {
    outline: 1px solid #cccccc;
  }

  textarea:focus, input:focus {
    outline: 1px solid #cccccc;
  }

  *:focus {
    outline: 1px solid #cccccc;
  }

  button:focus { 
    outline: none;
  }

  button {
    border: none;
    background: none;
  }

  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
  
  .slick-prev:before, .slick-next:before {
    display: none !important;
  }

  .slick-slider {
    div[tabindex='-1'] {
      :focus {
        outline: none;
      }
    }
  }

  .slick-slide .slick-active .slick-current {
    outline: none !important;
    border: none;
  }

  .slick-slide {
    outline: none !important;
    border: none;

    :hover {
      outline: none !important;
      border: none;
    }
  }

  [data-rsbs-overlay] {
    width: 50vw;
    left: 50%;
    padding: 0 60px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  [data-rsbs-scroll] {
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  [data-rsbs-footer] {
    box-shadow: 0 0 90px white, 0 0 80px white, 0 0 80px white, 0 0 60px white;
    padding: 0;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 1280px) {
    [data-rsbs-overlay] {
      width: 100vw;
      left: 0;
      padding: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    [data-rsbs-overlay] {
      width: 100vw;
      left: 0;
      padding: 0;
    }
  }

`;

export {GlobalStyles};
