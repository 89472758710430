import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {TITLE, CONTENT} from '../../constants/sectionOne';
import {TextBlockWrapper} from '../common';
import Logo from '../logo';
import {Header, Text} from '../text';

const Wrapper = styled(TextBlockWrapper)`
  display: flex;
  flex-direction: column;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '50px',
    isMobile: '40px',
  })};
  width: 100%;
  margin-bottom: 80px;
`;

const TextBlock = () => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <Logo layout={layout} />
      <Header layout={layout}>{TITLE}</Header>
      <Text layout={layout}>{CONTENT}</Text>
    </Wrapper>
  );
};

export default TextBlock;
