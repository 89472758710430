import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {SwipeItemLink} from './swipe-item-link';
import SlickSlider from 'react-slick';
import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';
import BottomSheet from '../bottomSheet';
import SwipeItemCard from './swipe-item-card';
import ArticlePopup from '../../pages/inside/components/article-popup/article-popup';

const Carousel = styled(SlickSlider)`
  width: 100vw;
  outline: none !important;
`;

const SwipeItem = styled.div`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '400',
    isLaptop: '400',
    isTablet: '600',
    isMobile: '600',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '30px',
    isLaptop: '30px',
    isTablet: '30px',
    isMobile: '15px',
  })};
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: ${getCurrentLayoutProperty({
    isDesktop: '750px',
    isLaptop: '750px',
    isTablet: '650px',
    isMobile: '630px',
  })};
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '400px',
    isLaptop: '400px',
    isTablet: '330px',
    isMobile: '300px',
  })};
  cursor: grab;
  outline: none !important;
  }
`;

const Image = styled.div`
  > img {
    width: 100%;
  }

  :hover {
    > img {
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.07);
    }
  }
`;

const sliderSettings = {
  dots: false,
  draggable: true,
  swipe: true,
  speed: 500,
  touchMove: true,
  sledesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  infinite: true,
};

export const SwipeCarousel = ({options}) => {
  const [isOpen, setOpen] = useState(false);
  const [showItemCard, setShowItemCard] = useState(null);
  const layout = useAppLayout();

  const closeItemCard = useCallback(() => {
    setOpen(false);
    setShowItemCard(null);
  }, []);

  return (
    <>
      <Carousel {...sliderSettings}>
        {options.map(({picture, title}, index) => (
          <SwipeItem key={`new-${index}`} layout={layout}>
            <Image>
              <img src={picture} alt="item picture" />
            </Image>
            <SwipeItemLink
              onClick={() => {
                setOpen(true);
                setShowItemCard(options[index]);
              }}
              title={title}
            />
          </SwipeItem>
        ))}
      </Carousel>
      {(layout.isDesktop || layout.isLaptop) && isOpen && (
        <ArticlePopup {...showItemCard} closeItemCard={closeItemCard} isOpen={isOpen} />
      )}
      {(layout.isTablet || layout.isMobile) && (
        <BottomSheet isOpen={isOpen} onClose={closeItemCard}>
          <SwipeItemCard {...showItemCard} closeItemCard={closeItemCard} />
        </BottomSheet>
      )}
    </>
  );
};

SwipeCarousel.propTypes = {
  options: PropTypes.array.isRequired,
};
