export const TITLE = 'News and Insights';
export const SUBTITLE = 'Inside the Engine';

const work = [];

const SUKOV = [
  'Served as Sr. Director, VP and SVP of companies like Teleca (now Harman), Guavus (now Thales), Comprehend (now Saama Technologies) and PayPal, in different engineering, customer success and business roles.',
  'Has worked with numerous clients on topics such as digital platforms, health solutions, embedded products, and has extensive experience in various projects concerning corporate and business strategy development, large-scale transformation and process optimization.',
];

const CARPENTER = [
  'Has led design-driven organization for 20 years through his Design Thinking methodology, that focuses strong on the user, bringing in insights and context to anything he tackles.',
  'Emerging from small agencies based in New York City, working with small business in a competitive new online marketplace, to Harman (now part of Samsung), FutureBrand, Speck Design and many other Fortune 500 brands, Jonathan has helped influence some very well known UI innovations.',
  'A first to design many mobile experiences, a leader in automotive interface design and focused now on data, analytics and geodata accessibility.',
];

const SOKOLOV = [
  'Recognized technology leader in Mobile, Embedded, Web, and Cloud development and architecture, has a career with the recurring theme of developing and applying advanced software and computing technology to understand the physical and virtual world',
  'Served in different engineering roles at Harman, Sony and Saama',
];

export const foundersList = [
  {id: 0, photo: '/team/Sukov.webp', name: 'Sergey Sukov', position: 'CEO', work: SUKOV},
  {id: 1, photo: '/team/Carpenter.webp', name: 'Jonathan Carpenter', position: 'Board Advisor', work: CARPENTER},
  {id: 2, photo: '/team/Sokolov.webp', name: 'Nikolay Sokolov', position: 'CTO', work: SOKOLOV},
];
