import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import {LEFT_TITLE, RIGHT_TITLE, FOOTER_TEXT} from '../../constants/sectionEight';
import InfoBlock from '../../../../components/info-block/info-block';

const Section = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 92px',
    isLaptop: '0 60px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const Content = styled(SectionContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child {
    margin-bottom: ${getCurrentLayoutProperty({
      isDesktop: '160px',
      isLaptop: '120px',
      isTablet: '40px',
      isMobile: '40px',
    })};
  }
`;

const SectionEight = () => {
  const layout = useAppLayout();

  return (
    <Section layout={layout}>
      <Content layout={layout}>
        <InfoBlock
          title={LEFT_TITLE}
          backgroundImage={'pages/home/silicon-valley.webp'}
          arrowLink={'/inside'}
          arrowText={FOOTER_TEXT}
        />
        <InfoBlock
          title={RIGHT_TITLE}
          backgroundImage={'pages/home/western-europe.webp'}
          arrowLink={'/inside'}
          arrowText={FOOTER_TEXT}
        />
      </Content>
    </Section>
  );
};

export default SectionEight;
