import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import TextBlock from './TextBlock';
import Projects from '../../../components/content/projects';

const HEADER = 'Check our other projects';

const CheckOurProjectsContainer = styled.div`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '140px',
    isLaptop: '100px',
    isTablet: '100px',
    isMobile: '60px ',
  })};
`;

const CheckOurProjectsHeaderWrapper = styled.div`
  margin: ${getCurrentLayoutProperty({
    isDesktop: '160px 92px 0 92px',
    isLaptop: '80px 92px 0 92px',
    isTablet: '80px 0 0 0',
    isMobile: '40px 0 0 0',
  })};
`;

const CheckOurProjects = ({headerId, currentProjectName, showHeader = true}) => {
  const layout = useAppLayout();

  return (
    <CheckOurProjectsContainer layout={layout}>
      {showHeader && (
        <CheckOurProjectsHeaderWrapper layout={layout}>
          <TextBlock headerText={HEADER} headerColor="#000000" />
        </CheckOurProjectsHeaderWrapper>
      )}
      <Projects headerId={headerId} currentProjectName={currentProjectName} />
    </CheckOurProjectsContainer>
  );
};

CheckOurProjects.propTypes = {
  currentProjectName: PropTypes.string,
  showHeader: PropTypes.bool,
  headerId: PropTypes.string,
};

export default CheckOurProjects;
