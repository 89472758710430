import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import InfoBlock from '../../../components/info-block/info-block';
import {SectionContent, HeaderMiddle, SectionWrapper} from '../../../components/common';

const TITLE = 'Ready to Provide an Engine to Get to Action with the Help of vis.gl toolset';
const SECOND_TITLE = 'And a Strong Expertize in the Supporting Technologies';

const IMAGE = {
  isDesktop: '/capabilities/instruments-desktop.webp',
  isLaptop: '/capabilities/instruments-laptop.webp',
  isTablet: '/capabilities/instruments-tablet.webp',
  isMobile: '/capabilities/instruments-mobile.webp',
};

const PROJECTS = [
  {title: 'deck.gl', weeklyDownloads: '87,000'},
  {title: 'kepler.gl', weeklyDownloads: '5,000'},
  {title: 'loaders.gl', weeklyDownloads: '136,000'},
  {title: 'luma.gl', weeklyDownloads: '113,000'},
];
const TECHNOLOGIES = ['hubble.gl', 'FlowmapBlue', 'pydeck', 'react-map-gl'];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '120px 92px',
    isLaptop: '80px 53px',
    isTablet: '0 0px 80px 0px',
    isMobile: '0 0px 40px 0px',
  })};

  > :first-child {
    margin-bottom: ${getCurrentLayoutProperty({
      isDesktop: '124px',
      isLaptop: '124px',
      isTablet: '0',
      isMobile: '0',
    })};
  }

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '240px',
    isLaptop: '160px',
    isTablet: '120px',
    isMobile: '80px',
  })};
`;

const Title = styled(HeaderMiddle)`
  color: #ffffff;
  width: ${getCurrentLayoutProperty({
    isDesktop: '85%',
    isLaptop: '65%',
    isTablet: '100%',
    isMobile: '90%',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '50px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '29px',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0s',
    isLaptop: '40px',
    isTablet: '80px',
    isMobile: '30px',
  })};
`;

const ProjectsWrapper = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0 92px',
    isMobile: '0 16px',
  })};

  margin-bottom: 0;
`;

const ProjectsStatisticsBlock = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr 1fr 1fr',
    isLaptop: '1fr 1fr',
    isTablet: '1fr 1fr',
    isMobile: '1fr',
  })};
`;

const ProjectName = styled(HeaderMiddle)`
  color: #f4e11b;
  margin-top: 0;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '72px',
    isTablet: '50px',
    isMobile: '35px',
  })};
`;

const ProjectItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '80px',
    isTablet: '40px',
    isMobile: '20px',
  })};
`;

const WeeklyDownloads = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 500;
  font-feature-settings: 'ss01' on;

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '29px',
    isLaptop: '29px',
    isTablet: '29px',
    isMobile: '20px',
  })};

  line-height: ${getCurrentLayoutProperty({
    isDesktop: '140%',
    isLaptop: '140%',
    isTablet: '140%',
    isMobile: '120%',
  })};
`;

const Donwloads = styled(WeeklyDownloads)`
  opacity: 0.6;
  margin-top: 10px;
`;

const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #ffffff;
  opacity: 0.6;

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '0',
    isTablet: '40px',
    isMobile: '20px',
  })};
`;

const TechnologiesContainer = styled.div`
  display: flex;

  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'center',
    isLaptop: 'flex-start',
    isTablet: 'flex-start',
    isMobile: 'flex-start',
  })};
  flex-flow: ${getCurrentLayoutProperty({
    isDesktop: 'row nowrap',
    isLaptop: 'column nowrap',
    isTablet: 'column',
    isMobile: 'column',
  })};

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '10px',
  })};
`;

const TechnologiesList = styled.div`
  display: flex;

  flex-flow: ${getCurrentLayoutProperty({
    isDesktop: 'row nowrap',
    isLaptop: 'row nowrap',
    isTablet: 'row wrap',
    isMobile: 'column',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: '70%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Technology = styled(ProjectName)`
  font-family: 'Kontora Medium';
  letter-spacing: -0.01em;
  font-weight: 700;
  white-space: nowrap;

  width: fit-content;

  &: last-child {
    margin-right: 0;
  }

  line-height: ${getCurrentLayoutProperty({
    isDesktop: '120%',
    isLaptop: '120%',
    isTablet: '120%',
    isMobile: '140%',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '42px',
    isLaptop: '42px',
    isTablet: '42px',
    isMobile: '24px',
  })};

  margin: ${getCurrentLayoutProperty({
    isDesktop: '35px 40px 35px 0',
    isLaptop: '35px 40px 35px 0',
    isTablet: '0px 40px 20px 0',
    isMobile: '10px 0 10px 0',
  })};
`;

const ProjectsBlock = () => {
  const layout = useAppLayout();

  return (
    <SectionContent layout={layout}>
      <Container layout={layout}>
        <InfoBlock title={TITLE} backgroundImage={getCurrentLayoutProperty(IMAGE)({layout})} titleColor="#FFFFFF" />
        <ProjectsWrapper layout={layout}>
          <ProjectsStatisticsBlock layout={layout}>
            {PROJECTS.map(({title, weeklyDownloads}) => (
              <ProjectItem key={title} layout={layout}>
                <ProjectName layout={layout}>{title}</ProjectName>
                <WeeklyDownloads layout={layout}>
                  {weeklyDownloads}
                  <Donwloads layout={layout}>Weekly Downloads</Donwloads>
                </WeeklyDownloads>
              </ProjectItem>
            ))}
          </ProjectsStatisticsBlock>
          <HorizontalLine layout={layout} />
          <TechnologiesContainer layout={layout}>
            <Title layout={layout}>{SECOND_TITLE}</Title>
            <TechnologiesList layout={layout}>
              {TECHNOLOGIES.map((technology) => (
                <Technology key={technology} layout={layout}>
                  {technology}
                </Technology>
              ))}
            </TechnologiesList>
          </TechnologiesContainer>
        </ProjectsWrapper>
      </Container>
    </SectionContent>
  );
};

export default ProjectsBlock;
