import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';

import {getCurrentLayoutProperty} from '../utils/common';
import useAppLayout from '../utils/useAppLayout';
import {ArrowButton} from './arrowButton';
import Linked from '../components/navigation/linked';

const bannerImage = {
  isDesktop: '/logo/aeBannerDesktop.webp',
  isLaptop: '/logo/aeBannerLaptop.webp',
  isTablet: '/logo/aeBannerTablet.webp',
  isMobile: '/logo/aeBannerMobile.webp',
};

const FooterContainer = styled.div`
  display: flex;
  background: #010101;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1656px;

  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};

  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'center',
    isLaptop: 'center',
    isTablet: 'flex-start',
    isMobile: 'flex-start',
  })};
`;

const FooterContentContainer = styled(ContentContainer)`
  flex-direction: column;
  align-items: flex-start;

  padding-right: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '60px',
    isTablet: '92px',
    isMobile: '16px',
  })};
`;

const LetsGoActionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #333333;

  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 60px',
    isTablet: '80px 92px',
    isMobile: '40px 16px',
  })};

  align-items: ${getCurrentLayoutProperty({
    isDesktop: 'center',
    isLaptop: 'center',
    isTablet: 'flex-start',
    isMobile: 'flex-start',
  })};

  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};

  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '92px',
    isLaptop: '60px',
    isTablet: '92px',
    isMobile: '16px',
  })};
`;

const Header = styled.div`
  font-style: normal;
  color: #ffffff;
  font-family: 'Kontora ExtraBold';

  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '800',
    isLaptop: '800',
    isTablet: '800',
    isMobile: '700',
  })};

  line-height: ${getCurrentLayoutProperty({
    isDesktop: '133%',
    isLaptop: '133%',
    isTablet: '133%',
    isMobile: '140%',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '86px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '35px',
  })};

  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: '-0.02em',
    isLaptop: '-0.01em',
    isTablet: '-0.01em',
    isMobile: '-0.01em',
  })};
`;

const ContactsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: url(${(props) => props.image}) 100% / 100% no-repeat;
  position: relative;

  background-position: ${getCurrentLayoutProperty({
    isDesktop: 'bottom 20% right 0',
    isLaptop: 'bottom 0 right 0',
    isTablet: 'bottom 35% right 0',
    isMobile: 'bottom 30% right 0',
  })};

  padding: ${getCurrentLayoutProperty({
    isDesktop: '92px 80px',
    isLaptop: '60px 80px',
    isTablet: '92px 0px 80px 92px',
    isMobile: '40px 16px',
  })};

  > :last-child {
    margin-bottom: 0;
  }
`;

const AELogoImage = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 40px;
`;

const InLogoWrapper = styled.div`
  cursor: pointer;

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '16px',
  })};

  padding-right: ${getCurrentLayoutProperty({
    isDesktop: '120px',
    isLaptop: '120px',
    isTablet: '120px',
    isMobile: '0',
  })};
`;

const InLogoImage = styled.img`
  width: 47px;
  height: 40px;
`;

const CommonFontStyles = styled.div`
  font-family: 'Kontora Medium';
  font-style: normal;
  line-height: 140%;

  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '400',
    isLaptop: '400',
    isTablet: '400',
    isMobile: '600',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const LinksHeader = styled(CommonFontStyles)`
  color: rgba(255, 255, 255, 0.4);

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '16px',
    isLaptop: '16px',
    isTablet: '16px',
    isMobile: '8px',
  })};
`;

const AllRightsReservedWrapper = styled(LinksHeader)`
  margin-bottom: 0;
  margin-top: 16px;
`;

const Link = styled(CommonFontStyles)`
  color: #ffffff;

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '24px',
    isLaptop: '24px',
    isTablet: '24px',
    isMobile: '17px',
  })};
`;

const LinkWithGap = styled(Link)`
  margin-bottom: 16px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0.9;

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const ArrowButtonWrapper = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1656px;
  width: 100%;
`;

const BottomLogoContainer = styled.div`
  display: flex;
  width: 100%;

  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'row',
    isMobile: 'column',
  })};

  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'space-between',
    isLaptop: 'space-between',
    isTablet: 'space-between',
    isMobile: 'flex-start',
  })};

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '227px',
    isLaptop: '131px',
    isTablet: '64px',
    isMobile: '48px',
  })};
`;

const BottomLine = styled.div`
  width: 80px;
  height: 1px;
  background: white;
  margin: 16px 0; ;
`;

const Footer = ({skipContactButton}) => {
  const layout = useAppLayout();
  const banner = getCurrentLayoutProperty(bannerImage)({layout});
  const [showAddress, setShowaddress] = useState(false);

  useEffect(() => {
    axios.get('https://ip-api.io/json').then(
      (response) => {
        response.data.country_name === 'Russia' && setShowaddress(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <FooterContainer layout={layout}>
      {!skipContactButton && (
        <LetsGoActionContainer layout={layout}>
          <ContentContainer layout={layout}>
            <Header layout={layout}>Let’s get to action</Header>
            <ArrowButtonWrapper layout={layout}>
              <Linked
                component={(props) => <ArrowButton {...props} />}
                to={'/home#contact-us'}
                textColor={'white'}
                text={'Get in touch'}
              />
            </ArrowButtonWrapper>
          </ContentContainer>
        </LetsGoActionContainer>
      )}
      <ContactsContainer layout={layout} image={banner}>
        <FooterContentContainer layout={layout}>
          <AELogoImage layout={layout} src={'/logo/ae-logo.webp'} alt="AE-logo" />
          <ContactsWrapper layout={layout}>
            <BlockWrapper layout={layout}>
              <LinksHeader layout={layout}>Silicon Valley</LinksHeader>
              <Link layout={layout}>1900 S Norfolk,</Link>
              <Link layout={layout}>Suite 350 San Mateo, CA</Link>
              <Link layout={layout}>94403</Link>
            </BlockWrapper>
            <BlockWrapper layout={layout}>
              <Linked
                component={(props) => (
                  <LinkWithGap {...props} layout={layout}>
                    Home
                  </LinkWithGap>
                )}
                to={'/home'}
              />
              <Linked
                component={(props) => (
                  <LinkWithGap {...props} layout={layout}>
                    Capabilities
                  </LinkWithGap>
                )}
                to={'/capabilities'}
              />
              <Linked
                component={(props) => (
                  <LinkWithGap {...props} layout={layout}>
                    Our work
                  </LinkWithGap>
                )}
                to={'/work'}
              />
              <Linked
                component={(props) => (
                  <LinkWithGap {...props} layout={layout}>
                    Inside the Engine
                  </LinkWithGap>
                )}
                to={'/inside'}
              />
            </BlockWrapper>
          </ContactsWrapper>

          <BlockWrapper>
            <LinksHeader layout={layout}>Eastern Europe</LinksHeader>
            {showAddress && (
              <>
                <Link layout={layout}>Osharskaya st, 77A</Link>
                <Link layout={layout}>BC &quot;London&quot;, office 406-409</Link>
                <Link layout={layout}>Nizhny Novgorod, Russia</Link>
                <BottomLine />
              </>
            )}
            <Link layout={layout}>Serbia, Belgrade, 231, Jurija</Link>
            <Link layout={layout}>Gagarina Street, retail unit</Link>
            <Link layout={layout}>329</Link>
          </BlockWrapper>
          <BottomLogoContainer layout={layout}>
            <BlockWrapper>
              <Link layout={layout}>info@actionengine.com</Link>
              <AllRightsReservedWrapper layout={layout}>
                © Action Engine Inc. – All Rights Reserved
              </AllRightsReservedWrapper>
            </BlockWrapper>
            <InLogoWrapper layout={layout}>
              <a href="https://www.linkedin.com/company/actionengine/" aria-label="LinkedIn">
                <InLogoImage src={'/logo/InIcon.webp'} alt="LinkedIn logo" />
              </a>
            </InLogoWrapper>
          </BottomLogoContainer>
        </FooterContentContainer>
      </ContactsContainer>
    </FooterContainer>
  );
};

Footer.propTypes = {
  skipContactButton: PropTypes.bool,
};

export default Footer;
