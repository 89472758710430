import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';

const Logo = '/case-studies/ae-logo.svg';

const WholePageContainer = styled.div`
  position: relative;
  color: #ffffff;
  padding-top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '80px',
    isMobile: '48px',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '160px',
    isTablet: '100px',
    isMobile: '40px',
  })};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: ${getCurrentLayoutProperty({
    isDesktop: '112px',
    isLaptop: '112px',
    isTablet: '0',
    isMobile: '0',
  })};

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${getCurrentLayoutProperty({
      isDesktop: '100%',
      isLaptop: '100%',
      isTablet: 'calc(100% - 22px)',
      isMobile: 'calc(100% - 11px)',
    })};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'flex-end',
    isLaptop: 'flex-end',
    isTablet: 'flex-start',
    isMobile: 'flex-start',
  })};
`;

const LogoContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  height: 112px;
  padding: 40px;
`;

const ContentLeft = styled.div`
  position: relative;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0 80px',
    isLaptop: '0 80px',
    isTablet: '40px 40px 0px',
    isMobile: '24px 16px 0px',
  })};
`;

const UpperHeader = styled.div``;

const MainHeader = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '16px',
  })};

  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 700;
  font-size: ${getCurrentLayoutProperty({
    isDesktop: (props) => (props.largeTitle ? '86px' : '103px'),
    isLaptop: (props) => (props.largeTitle ? '50px' : '60px'),
    isTablet: (props) => (props.largeTitle ? '50px' : '60px'),
    isMobile: (props) => (props.largeTitle ? '35px' : '42px'),
  })};
  line-height: 120%;
  /* or 50px */

  letter-spacing: -0.01em;
`;

const BagesContainer = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '48px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '16px',
  })};
  display: flex;
  flex-wrap: wrap;
`;

const Bage = styled.div`
  font-family: 'Kontora SemBd';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;

  letter-spacing: 0.01em;

  border: 1px solid #ffffff;
  height: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '34px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '0 24px 24px 0',
    isLaptop: '0 24px 24px 0',
    isTablet: '0 16px 16px 0',
    isMobile: '0 16px 16px 0',
  })};
  border-radius: ${getCurrentLayoutProperty({
    isDesktop: '20px',
    isLaptop: '20px',
    isTablet: '20px',
    isMobile: '17px',
  })};
`;

const TabletContainer = styled.div`
  position: relative;
  text-align: right;
`;

const propTypes = {
  backgroundImage: PropTypes.shape({
    isDesktop: PropTypes.string.isRequired,
    isLaptop: PropTypes.string.isRequired,
    isTablet: PropTypes.string.isRequired,
    isMobile: PropTypes.string.isRequired,
  }).isRequired,
  upperHeader: PropTypes.node.isRequired,
  mainHeader: PropTypes.string.isRequired,
  bages: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabletImage: PropTypes.shape({
    isDesktop: PropTypes.string.isRequired,
    isLaptop: PropTypes.string.isRequired,
    isTablet: PropTypes.string.isRequired,
    isMobile: PropTypes.string.isRequired,
  }).isRequired,
  largeTitle: PropTypes.bool,
};

const MainBanner = ({backgroundImage, upperHeader, mainHeader, bages, tabletImage, largeTitle}) => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;

  return (
    <WholePageContainer backgroundImage={backgroundImage} layout={layout}>
      <img src={getCurrentLayoutProperty(backgroundImage)({layout})} alt="main background" loading="eager" />
      {isDesktopType && (
        <LogoContainer>
          <img src={Logo} alt="logo" />
        </LogoContainer>
      )}
      <FlexContainer layout={layout}>
        <ContentLeft layout={layout}>
          <UpperHeader layout={layout}>{upperHeader}</UpperHeader>
          <MainHeader layout={layout} largeTitle={largeTitle}>
            {mainHeader}
          </MainHeader>
          <BagesContainer layout={layout}>
            {bages.map((bageText, i) => (
              <Bage key={i} layout={layout}>
                {bageText}
              </Bage>
            ))}
          </BagesContainer>
        </ContentLeft>
        <TabletContainer>
          <img src={getCurrentLayoutProperty(tabletImage)({layout})} alt="tablet image" loding="eager" />
        </TabletContainer>
      </FlexContainer>
    </WholePageContainer>
  );
};

MainBanner.propTypes = propTypes;

export default MainBanner;
