import React from 'react';
import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../../../utils/common';
import useAppLayout from '../../../utils/useAppLayout';
import PropTypes from 'prop-types';

const VideoContainer = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '102px',
    isLaptop: '102px',
    isTablet: '30px',
    isMobile: '14px',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '102px',
    isLaptop: '102px',
    isTablet: '30px',
    isMobile: '14px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '16px',
    isLaptop: '10px',
    isTablet: '10px',
    isMobile: '10px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: 'calc(100vw - 204px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  height: auto;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgb(52, 52, 52));
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
`;

const StyledVideo = styled.img`
  width: 100%;
  border-radius: 20px;
  height: auto;
  display: block;
`;

function VideoPlayer({src}) {
  const layout = useAppLayout();
  return (
    <VideoContainer layout={layout}>
      <StyledVideo layout={layout} src={src} alt={src} />
    </VideoContainer>
  );
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default VideoPlayer;
