import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useAppLayout from '../../utils/useAppLayout';
import {SubHeader, Text} from '../../components/common';
import {ACTION_GREETINGS, APPLY_GREETENGS} from './constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  margin-top: 40px;
`;

const Address = styled.span`
  color: red;
  display: inline;
`;

export const GreetingsCard = ({isActionForm, isApplyForm}) => {
  const layout = useAppLayout();

  return (
    <Wrapper>
      <SubHeader layout={layout}>Thanks!</SubHeader>
      <Text layout={layout}>
        {isApplyForm && APPLY_GREETENGS}
        {isActionForm && ACTION_GREETINGS}
        <Address>{isApplyForm ? 'Dmitry.Varzin@actionengine.com' : 'info@actionengine.com'}</Address>
      </Text>
    </Wrapper>
  );
};

GreetingsCard.propTypes = {
  isActionForm: PropTypes.bool,
  isApplyForm: PropTypes.bool,
};
