import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {HeaderMiddle, BoldText} from '../../components/common';
import {ArrowButton} from '../arrowButton';
import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';
import Linked from '../navigation/linked';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: ${({reverse}) =>
    getCurrentLayoutProperty({
      isDesktop: reverse ? 'row-reverse' : 'row',
      isLaptop: reverse ? 'row-reverse' : 'row',
      isTablet: 'column-reverse',
      isMobile: 'column-reverse',
    })};
`;

const TextContent = styled.div`
  width: ${({reverse}) =>
    getCurrentLayoutProperty({
      isDesktop: reverse ? '40%' : '50%',
      isLaptop: reverse ? '40%' : '50%',
      isTablet: '100%',
      isMobile: '100%',
    })};
  display: flex;
  margin: auto 0;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({fullscreen}) =>
    getCurrentLayoutProperty({
      isDesktop: 'auto',
      isLaptop: 'auto',
      isTablet: `80px ${fullscreen ? 92 : 0}px`,
      isMobile: `40px 16px`,
    })};
`;

const PictureContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '520px',
    isTablet: '768px',
    isMobile: '360px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '520px',
    isTablet: '100%',
    isMobile: '100%',
  })};
  background: yellow;
  background: url(${(props) => props.background}) center center no-repeat;
  background-size: ${({bgSize}) => bgSize};
`;

const Picture = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '360px',
    isLaptop: '260px',
    isTablet: '340px',
    isMobile: '175px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '360px',
    isLaptop: '260px',
    isTablet: '340px',
    isMobile: '175px',
  })};
  background: url(${(props) => props.picture}) center center no-repeat;
  background-size: cover;
`;

const Title = styled(HeaderMiddle)`
  width: ${getCurrentLayoutProperty({
    isDesktop: '85%',
    isLaptop: '73%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Paragraph = styled(BoldText)`
  width: ${getCurrentLayoutProperty({
    isDesktop: '85%',
    isLaptop: '85%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '60px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const InfoBlock = ({
  title,
  text,
  arrowLink,
  arrowText,
  backgroundImage,
  picture,
  fullscreen = true,
  reverse = false,
  bgSize = 'cover',
  titleColor,
}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout} reverse={reverse}>
      <TextContent layout={layout} fullscreen={fullscreen} reverse={reverse}>
        <Title layout={layout} color={titleColor}>
          {title}
        </Title>
        {text && <Paragraph layout={layout}>{text}</Paragraph>}
        {arrowLink && <Linked component={(props) => <ArrowButton {...props} />} to={arrowLink} text={arrowText} />}
      </TextContent>
      <PictureContent layout={layout} background={backgroundImage} bgSize={bgSize}>
        {picture && <Picture layout={layout} picture={picture} />}
      </PictureContent>
    </Wrapper>
  );
};

export default InfoBlock;

InfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  text: PropTypes.string,
  picture: PropTypes.string,
  arrowText: PropTypes.string,
  arrowLink: PropTypes.string,
  titleColor: PropTypes.string,
  bgSize: PropTypes.string,
  fullscreen: PropTypes.bool,
  reverse: PropTypes.bool,
};
