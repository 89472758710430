import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useAppLayout from '../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../utils/common';

const Wrapper = styled.div`
  position: ${getCurrentLayoutProperty({
    isDesktop: 'fixed',
    isLaptop: 'fixed',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  top: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: 'unset',
    isMobile: 'unset',
  })};
  background: white;
  width: ${(props) =>
    getCurrentLayoutProperty({
      isDesktop: props.member ? 'fit-content' : '50vw',
      isLaptop: props.member ? 'fit-content' : '50vw',
      isTablet: 'fit-content',
      isMobile: 'calc(100vw - 16px)',
    })};
  animation: ${(props) => props.isOpen && 'openCard 0.3s ease-in-out'};
  animation-delay: 0;
  height: ${getCurrentLayoutProperty({
    isDesktop: '100vh',
    isLaptop: '100vh',
    isTablet: 'auto',
    isMobile: 'auto',
  })};

  @keyframes openCard {
    0% {
      transform: translateX(550px);
    }
    100% {
      transform: translateX(0);
    }
  }
  z-index: 3;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.div`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
  })};
  position: absolute;
  top: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
  })};
  cursor: pointer;
  transition: all 0.5s;
  z-index: 5;

  :hover {
    background: white;
  }
`;

const FadeBackground = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: ${getCurrentLayoutProperty({
    isDesktop: 'flex',
    isLaptop: 'flex',
    isTablet: 'none',
    isMobile: 'none',
  })};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) =>
    props.article
      ? '100vh'
      : getCurrentLayoutProperty({
          isDesktop: '85vh',
          isLaptop: '85vh',
          isTablet: 'auto',
          isMobile: 'auto',
        })};
  width: fit-content;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none;
  position: relative;

  ${({article, layout}) =>
    !article &&
    (layout.isDesktop || layout.isLaptop) &&
    `
      &:after {
        content: '';
        position: fixed;
        bottom: 15vh;
        width: 100%;
        background-image: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 1) 100%);
        height: 150px;
      }
  `}
`;

export const PopupWrapper = ({content, controls, isOpen, member, onClick, article, setOpen, setVacancy}) => {
  const layout = useAppLayout();
  const contentRef = useRef();
  const popupRef = useRef();

  useEffect(() => {
    contentRef.current.scrollTo(0, 0);
  }, [content]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpen && setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, setOpen, setVacancy]);

  return (
    <>
      {(layout.isDesktop || layout.isLaptop) && <FadeBackground layout={layout} />}
      <Wrapper layout={layout} isOpen={isOpen} member={member} ref={popupRef}>
        <ContentWrapper ref={contentRef} layout={layout} article={article}>
          {(layout.isDesktop || layout.isLaptop) && (
            <CloseButton layout={layout} onClick={onClick}>
              <img src="/icons/close.svg" alt="close" />
            </CloseButton>
          )}
          {content}
        </ContentWrapper>
        {controls}
      </Wrapper>
    </>
  );
};

PopupWrapper.propTypes = {
  controls: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  content: PropTypes.object,
  member: PropTypes.bool,
  article: PropTypes.bool,
  setVacancy: PropTypes.func,
  setOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};
