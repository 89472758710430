import {useMediaQuery} from 'react-responsive';

export default () => {
  const isDesktop = useMediaQuery({
    minWidth: 1920,
  });
  const isLaptop = useMediaQuery({
    minWidth: 1280,
    maxWidth: 1919.99,
  });
  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 1279.99,
  });
  const isMobile = useMediaQuery({
    minWidth: 320,
    maxWidth: 767.99,
  });
  const isPortrait = useMediaQuery({
    orientation: 'portrait',
  });
  const isUnsupported = useMediaQuery({
    maxWidth: 319,
  });
  const isTouchDevice = useMediaQuery({
    query: '(hover: none) and (pointer: coarse)',
  });
  const isBigScreen = useMediaQuery({
    minWidth: 1856,
  });

  return {
    isDesktop,
    isLaptop,
    isTablet,
    isMobile,
    isPortrait,
    isUnsupported,
  };
};
