import React, {useEffect} from 'react';
import {Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {scrollToTop} from './components/scrollToTop';

import * as Pages from './pages';

export default function App() {
  const {pathname, hash, key} = useLocation();

  /**
   * Enables autoscroll to element with particular id on the page using hash param in router.
   */
  useEffect(() => {
    if (hash === '') {
      scrollToTop();
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to="/home" replace={true} />} />
      <Route path={'/home'} index element={<Pages.Home />} />
      <Route path={'/inside'} element={<Pages.Inside />} />
      <Route path={'/work'} element={<Pages.Work />} />
      <Route path={'/casestudies-fireproof'} element={<Pages.CaseStudiesAqueous />} />
      <Route path={'/casestudies-cesium'} element={<Pages.CaseStudiesCesium />} />
      <Route path={'/casestudies-hayden'} element={<Pages.CaseStudiesHayden />} />
      <Route path={'/casestudies-esri'} element={<Pages.CaseStudiesFoursquare />} />
      <Route path={'/casestudies-deforestation'} element={<Pages.CaseStudiesDeforestation />} />
      <Route path={'/casestudies-kepler'} element={<Pages.CaseStudiesKepler />} />
      <Route path={'/capabilities'} element={<Pages.Capabilities />} />
    </Routes>
  );
}
