import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import Linked from './linked';
import Link from './link';
import {LINKS} from './constants';
import {OurWorkList} from './our-work-list';
import BottomSheet from '../bottomSheet';

const OurWorkContent = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100px;
`;

const MobileLinksMenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.background.color};
  transition: background 0.3s;
  height: 100vh;
  box-shadow: 10px 5px 15px rgba(0, 0, 0, 0.03);
  overflow: auto;
  padding: 0 24px;
  padding-top: 110px;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none; /* Firefox 64 */
`;

const TabletLinksMenuWrapper = styled(MobileLinksMenuWrapper)`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 168px;
`;

const DesktopLinksMenuWrapper = styled(MobileLinksMenuWrapper)`
  padding-top: 128px;
  padding-right: 120px;
  padding-left: 120px;
  background: ${(props) =>
    `linear-gradient(to right, ${props.background.color}, ${props.background.color} 50%, ${props.background.opacityColor} 50%)`};
`;

const LaptopLinksMenuWrapper = styled(DesktopLinksMenuWrapper)`
  padding-top: 54px;
  padding-left: 80px;
`;

const MobileLinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  height: 100%;

  > li {
    text-decoration: none;
    font-family: Kontora ExtraBold;
    font-size: 42px;
    transition: all 0.5s ease;
    font-weight: 700;
    letter-spacing: -0.01em;
    cursor: pointer;
    opacity: 0.6;
    display: flex;
    margin-bottom: 76px;

    > div > a {
      transition: all 0.5s;
    }

    > div > img {
      display: none;
    }
  }

  > li:hover {
    padding-left: 40px;
    transition: padding-left 0.5s;
  }

  > li.active {
    opacity: 1;
    transition: padding-left 0.5s, opacity 0.5s;

    > div > img {
      display: flex;
      margin: 0 20px 30px 20px;
      width: 32px;
      height: 32px;
    }
  }
`;

const TabletLinksWrapper = styled(MobileLinksWrapper)`
  justify-content: flex-start;

  > li {
    margin-bottom: 97px;
    font-size: 86px;
    letter-spacing: -0.02em;
  }

  > li:hover {
    padding-left: 60px;
    transition: padding-left 0.5s;
  }
`;

const LaptopLinksWrapper = styled(MobileLinksWrapper)`
  height: 100vh;
  justify-content: space-between;

  > li {
    font-size: 60px;
    padding-left: 40px;
    margin-bottom: 108px;
  }

  > li:hover {
    padding-left: 70px;
    transition: padding-left 0.5s;
  }

  > li.active {
    > div > img {
      margin: 0 20px 30px 20px;
      width: 32px;
      height: 38px;
    }
  }
`;

const DesktopLinksWrapper = styled(MobileLinksWrapper)`
  justify-content: space-between;
  width: 100%;

  > li {
    font-size: 86px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-bottom: 97px;
  }

  > li:hover {
    padding-left: 80px;
    transition: padding-left 0.5s;
  }

  > li.active {
    > div > img {
      margin: 0 20px 50px 20px;
      width: 42px;
      height: 51px;
    }
  }
`;

const MobileImageWrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.background.color};
  transition: background 0.5s ease;
  z-index: 1;

  padding: 14px 0;
  > img {
    height: 20px;
    width: 155px;
  }
`;

const TabletImageWrapper = styled(MobileImageWrapper)`
  padding: 24px 0;

  > img {
    height: 33px;
    width: 250px;
  }
`;

const LaptopImageWrapper = styled(MobileImageWrapper)`
  display: none;
`;

const propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  background: PropTypes.shape({color: PropTypes.string, opacityColor: PropTypes.string}),
  detectColor: PropTypes.func,
  navColor: PropTypes.string,
  onCounterClick: PropTypes.func,
  onCloseOurWorkPanel: PropTypes.func,
  showOurWorkPanel: PropTypes.bool,
};

const getLinksView = (layout) => {
  switch (true) {
    case layout.isDesktop:
      return {
        LinksMenuWrapper: DesktopLinksMenuWrapper,
        LinksWrapper: DesktopLinksWrapper,
        ImageWrapper: LaptopImageWrapper,
      };
    case layout.isLaptop:
      return {
        LinksMenuWrapper: LaptopLinksMenuWrapper,
        LinksWrapper: LaptopLinksWrapper,
        ImageWrapper: LaptopImageWrapper,
      };
    case layout.isTablet:
      return {
        LinksMenuWrapper: TabletLinksMenuWrapper,
        LinksWrapper: TabletLinksWrapper,
        ImageWrapper: TabletImageWrapper,
      };
    case layout.isMobile:
      return {
        LinksMenuWrapper: MobileLinksMenuWrapper,
        LinksWrapper: MobileLinksWrapper,
        ImageWrapper: MobileImageWrapper,
      };
    default:
      return {
        LinksMenuWrapper: () => null,
        LinksWrapper: () => null,
        ImageWrapper: () => null,
      };
  }
};

const LinksList = ({
  innerRef,
  onClick,
  background,
  navColor,
  onHover,
  onCounterClick,
  showOurWorkPanel,
  onCloseOurWorkPanel,
}) => {
  const layout = useAppLayout();
  const LinksList = useMemo(() => getLinksView(layout), [layout]);

  const isTabletOrMobile = layout.isTablet || layout.isMobile;

  return (
    <>
      <LinksList.LinksMenuWrapper ref={innerRef} background={background}>
        <LinksList.ImageWrapper background={background}>
          {navColor === 'white' ? (
            <img src="/logo/black-AE-logo.svg" alt="AE Logo" />
          ) : (
            <img src="/logo/white-AE-logo.svg" alt="AE Logo" />
          )}
        </LinksList.ImageWrapper>
        <LinksList.LinksWrapper>
          {LINKS.map((item) => {
            return (
              <Linked
                key={item.id}
                component={Link}
                to={item.path}
                text={item.text}
                onClick={onClick}
                navColor={navColor}
                counter={item.counter}
                onHover={() => onHover(item, layout)}
                onCounterClick={onCounterClick}
              />
            );
          })}
        </LinksList.LinksWrapper>
        {!isTabletOrMobile && showOurWorkPanel && (
          <OurWorkContent>
            <OurWorkList onWorkItemClick={onClick} />
          </OurWorkContent>
        )}
      </LinksList.LinksMenuWrapper>
      {isTabletOrMobile && (
        <BottomSheet isOpen={showOurWorkPanel} onClose={onCloseOurWorkPanel}>
          <OurWorkList onWorkItemClick={onClick} />
        </BottomSheet>
      )}
    </>
  );
};

LinksList.propTypes = propTypes;

export default LinksList;
