import React from 'react';
import PropTypes from 'prop-types';
import CheckOurProjects from '../../../case-studies/components/CheckOurProjects';

const SectionTwo = ({headerId}) => <CheckOurProjects headerId={headerId} showHeader={false} />;

SectionTwo.propTypes = {
  headerId: PropTypes.string,
};

export default SectionTwo;
