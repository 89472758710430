import React from 'react';
import PropTypes from 'prop-types';

import {CvForm} from '../../../../components/forms/cv-form';
import {PopupWrapper} from '../../../../components/popup-wrapper';
import {VacancyContent} from './vacancy-content';
import {VacancyControls} from './vacancy-controls';
import BottomSheet from '../../../../components/bottomSheet';
import useAppLayout from '../../../../utils/useAppLayout';

const VacancyPopup = (
  {
    title,
    content,
    closeVacancy,
    vacancyOpen,
    setVacancyOpen,
    setVacancy,
    openForm,
    closeForm,
    closeFormAndVacancy,
    CVopen,
    setCVOpen,
  },
  index
) => {
  const layout = useAppLayout();

  return (
    <>
      <PopupWrapper
        key={`new-${index}`}
        onClick={closeVacancy}
        isOpen={vacancyOpen}
        content={<VacancyContent content={content} title={title} />}
        controls={(layout.isDesktop || layout.isLaptop) && <VacancyControls openForm={openForm} />}
        setOpen={!CVopen && setVacancyOpen}
        setVacancy={setVacancy}
      />
      {(layout.isDesktop || layout.isLaptop) && CVopen && (
        <CvForm
          title={title}
          handleCloseForm={closeForm}
          closeFormAndVacancy={closeFormAndVacancy}
          setCVOpen={setCVOpen}
          setVacancyOpen={setVacancyOpen}
          setVacancy={setVacancy}
        />
      )}
      {(layout.isTablet || layout.isMobile) && (
        <BottomSheet isOpen={CVopen} onClose={closeFormAndVacancy} openForm={openForm}>
          <CvForm title={title} handleCloseForm={closeForm} setVacancyOpen={setVacancyOpen} setVacancy={setVacancy} />
        </BottomSheet>
      )}
    </>
  );
};

export default VacancyPopup;

VacancyPopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
  closeVacancy: PropTypes.func,
  vacancyOpen: PropTypes.bool,
  setVacancyOpen: PropTypes.func,
  setVacancy: PropTypes.func,
  openForm: PropTypes.func,
  closeForm: PropTypes.func,
  closeFormAndVacancy: PropTypes.func,
  CVopen: PropTypes.bool,
  setCVOpen: PropTypes.func,
};
