import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../utils/common';
import useAppLayout from '../utils/useAppLayout';

const LogoWrapper = styled.div`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '64px',
    isTablet: '64px',
    isMobile: '48px',
  })};

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const Logo = () => {
  const layout = useAppLayout();

  return (
    <LogoWrapper layout={layout}>
      <img src="/logo/AE-logo.webp" alt="AE Logo" />
    </LogoWrapper>
  );
};
