import React from 'react';
import styled from 'styled-components';
import {getCurrentLayoutProperty} from '../../../../utils/common';

import useAppLayout from '../../../../utils/useAppLayout';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import InfoBlock from '../../../../components/info-block/info-block';

const SectionPageTwoWrapper = styled(SectionWrapper)`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '240px',
    isLaptop: '160px',
    isTablet: '120px',
    isMobile: '80px',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '172px',
    isLaptop: '130px',
    isTablet: '0',
    isMobile: '30px',
  })};
`;

const TITLE = 'GIS Software Development, Design and Data Services';
const TEXT =
  'Focused on major service offerings around Platform & Apps, GeoData Engineering, Visualization & Mapping, and Powered Analytics.  We look to solve big problems related to climate change, environmental studies, forestry, insurance, supply chain, urban planning, banking, health and human services.';

const GisImage = {
  isDesktop: '/pages/home/gis-routing-desktop-laptop.webp',
  isLaptop: '/pages/home/gis-routing-desktop-laptop.webp',
  isTablet: '/pages/home/gis-routing-tablet.webp',
  isMobile: '/pages/home/gis-routing-mobile.webp',
};

const SectionTwo = () => {
  const layout = useAppLayout();

  return (
    <SectionPageTwoWrapper layout={layout}>
      <SectionContent layout={layout}>
        <InfoBlock
          title={TITLE}
          text={TEXT}
          backgroundImage={getCurrentLayoutProperty(GisImage)({layout})}
          fullscreen={false}
        />
      </SectionContent>
    </SectionPageTwoWrapper>
  );
};

export default SectionTwo;
