import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import SectionFive from './sections/section-Five';
import SectionOne from './sections/section-One';
import SectionTwo from './sections/section-Two';
import SectionThree from './sections/section-Three';
import SectionFour from './sections/section-Four';
import SectionSix from './sections/section-Six';
import Footer from '../../components/footer';
import OurApproachSection from './components/our-approach';

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '150px',
    isLaptop: '150px',
    isTablet: '100px',
    isMobile: '50px',
  })};
  padding-bottom: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '50px',
    isTablet: '50px',
    isMobile: '30px',
  })};
  max-width: 1925px;
  margin: auto;
`;

const PageWrapper = styled.div`
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: 0,
    isMobile: 0,
  })};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '150px',
    isLaptop: '150px',
    isTablet: '100px',
    isMobile: '50px',
  })};
`;

const Inside = () => {
  const layout = useAppLayout();

  return (
    <PageWrapper layout={layout}>
      <SectionsWrapper layout={layout}>
        <SectionOne />
        <SectionTwo />
        <OurApproachSection />
        <SectionThree />
        <SectionFour />
        {/* <SectionFive /> */}
        <SectionSix />
      </SectionsWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Inside;
