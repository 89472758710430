import React from 'react';
import styled from 'styled-components';
import {Header} from '../../../../components/common';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {SectionFiveContent} from '../../components/section-six-content';
import {TITLE} from '../../constants/sectionSix';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column-reverse',
    isMobile: 'column-reverse',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: '100%',
    isLaptop: 'calc(100% - 80px)',
    isTablet: 'auto 92px',
    isMobile: 'auto 16px',
  })};
  margin: ${getCurrentLayoutProperty({
    isDesktop: 'auto 92px',
    isLaptop: 'auto 80px',
    isTablet: 'auto 92px',
    isMobile: '0 16px 30px 16px',
  })};
`;

const CareersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: 'calc(30% - 80px)',
    isTablet: 'calc(100vw - 184px)',
    isMobile: 'calc(100vw - 32px)',
  })};
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'column',
    isLaptop: 'column',
    isTablet: 'row',
    isMobile: 'row',
  })};
  margin: ${getCurrentLayoutProperty({
    isTablet: '50px auto',
    isMobile: '50px auto',
  })};
  align-items: center;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '32px',
    isTablet: '7px',
    isMobile: '14px',
  })};
  > img {
    width: ${getCurrentLayoutProperty({
      isDesktop: '360px',
      isLaptop: '368px',
      isTablet: 'calc(33vw - 55px)',
      isMobile: 'calc(33vw - 18px)',
    })};
    height: ${getCurrentLayoutProperty({
      isDesktop: '360px',
      isLaptop: '368px',
      isTablet: 'calc(33vw - 55px)',
      isMobile: 'calc(33vw - 18px)',
    })};
  }
`;

const TabletHeader = styled(Header)`
  margin: 0;
`;

const SectionSix = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <CareersWrapper>
        {(layout.isDesktop || layout.isLaptop) && <Header layout={layout}>{TITLE}</Header>}
        <SectionFiveContent />
      </CareersWrapper>
      <ImageWrapper layout={layout}>
        <img src={'/pages/inside/careers-1.webp'} alt="pattern1" />
        <img src={'/pages/inside/careers-2.webp'} alt="pattern2" />
        <img src={'/pages/inside/careers-3.webp'} alt="pattern3" />
      </ImageWrapper>
      {(layout.isTablet || layout.isMobile) && <TabletHeader layout={layout}>{TITLE}</TabletHeader>}
    </SectionWrapper>
  );
};

export default SectionSix;
