import React from 'react';
import PropTypes from 'prop-types';

import {FormInput} from '../form-input';

export const MobileForm = ({register, errors}) => {
  return (
    <>
      <FormInput
        id={'name'}
        label={'Name'}
        placeholder={'Required'}
        register={register}
        required={true}
        pattern={/[A-Za-z]{3}/}
        errors={errors}
      />
      <FormInput
        id={'company'}
        label={'Company'}
        placeholder={'Required'}
        register={register}
        required={true}
        pattern={/[A-Za-z]{3}/}
        errors={errors}
      />
      <FormInput
        id={'email'}
        label={'Email'}
        placeholder={'Required'}
        register={register}
        required={true}
        pattern={/^\S+@\S+\.\S+$/}
        errors={errors}
      />
      <FormInput
        id={'phone'}
        label={'Phone'}
        placeholder={'Optional'}
        register={register}
        required={false}
        pattern={/^[+]?[0-9-()]+[0-9]$/}
        errors={errors}
      />
    </>
  );
};

MobileForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
};
