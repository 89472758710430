import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const GridWrapper = styled.div`
  display: grid;
  max-width: 1925px;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto;

  grid-column-gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '24px',
  })};
  grid-row-gap: ${getCurrentLayoutProperty({
    isDesktop: '64px',
    isLaptop: '40px',
    isTablet: '24px',
    isMobile: '24px',
  })};
  grid-template-columns: ${getCurrentLayoutProperty({
    isDesktop: '1fr 1fr 1fr',
    isLaptop: '1fr 1fr 1fr',
    isTablet: '1fr 1fr',
    isMobile: '1fr 1fr',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '224px',
    isLaptop: '80px',
    isTablet: '40px',
    isMobile: '40px',
  })};

  padding: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '0 16px',
  })};
`;

const Client = styled.img`
  max-width: 100%;
`;

const ClientsGrid = () => {
  const layout = useAppLayout();

  return (
    <GridWrapper layout={layout}>
      <Client src={'/pages/home/logo-descartes.webp'} alt="descartes-logo" />
      <Client src={'/pages/home/logo-esri.webp'} alt="esri-logo" />
      <Client src={'/pages/home/logo-foursquare.webp'} alt="foursquare-logo" />
      <Client src={'/pages/home/logo-aqueous.webp'} alt="aqueous-logo" />
      <Client src={'/pages/home/logo-hayden.webp'} alt="hayden-logo" />
      <Client src={'/pages/home/logo-burnbot.webp'} alt="burnbot-logo" />
    </GridWrapper>
  );
};

export default ClientsGrid;
