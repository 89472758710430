import React from 'react';
import styled from 'styled-components';
import useAppLayout from '../../../utils/useAppLayout';

import ColoredRectangle from './ColoredRectangle';

const TABLET_HEIGHTS = {
  top: 471,
  left: 174,
  right: 429,
};

const MOBILE_HEIGHTS = {
  top: 205,
  left: 76,
  right: 181,
};

const rectanglesConfig = {
  top: [{height: 471, text: 'Brand Blue', fillColor: '#2831F4', textColor: 'white'}],
  bottom: {
    left: [
      {
        text: 'Strong Black',
        fillColor: '#1A1A1A',
        textColor: 'white',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Dark Grey',
        fillColor: '#9192A1',
        textColor: 'white',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Medium Grey',
        fillColor: '#C2C2D5',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Light Grey',
        fillColor: '#E4E9FB',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
      {
        text: 'Background White',
        fillColor: '#F3F5F9',
        textColor: 'black',
        textPositioning: 'centerLeft',
      },
    ],
    right: [
      {text: 'Red Color Coding', fillColor: '#EB4C55', textColor: 'white'},
      {text: 'Green Color Coding', fillColor: '#1AA053', textColor: 'white'},
    ],
  },
};

const RectanglesContainer = styled.div`
  display: flex;
  margin: 0 40px;
  flex-direction: column;
`;

const TopRectangleWrapper = styled.div`
  width: 100%;
`;

const RectanglesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RectanglesGapWrapperVertical = styled(RectanglesWrapper)`
  gap: 16px;
`;

const RectanglesGapWrapperHorizontal = styled(RectanglesWrapper)`
  flex-flow: row;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
`;

const getRectangleHeights = (layout) => {
  switch (true) {
    case layout.isTablet:
      return TABLET_HEIGHTS;
    case layout.isMobile:
      return MOBILE_HEIGHTS;
    default:
      return TABLET_HEIGHTS;
  }
};

const TabletAndMobileRectanglesLayout = () => {
  const layout = useAppLayout();

  const rectangleHeights = getRectangleHeights(layout);

  const renderTopRectangles = () =>
    rectanglesConfig.top.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={rectangleHeights.top}
        textPositioning={rectangle.textPositioning}
      />
    ));

  const renderBottomLeftRectangles = () =>
    rectanglesConfig.bottom.left.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={rectangleHeights.left}
        textPositioning={rectangle.textPositioning}
      />
    ));

  const renderRightBottomRectangles = () =>
    rectanglesConfig.bottom.right.map((rectangle) => (
      <ColoredRectangle
        key={rectangle.text}
        text={rectangle.text}
        textColor={rectangle.textColor}
        fillColor={rectangle.fillColor}
        height={rectangleHeights.right}
        textPositioning={rectangle.textPositioning}
      />
    ));

  return (
    <RectanglesContainer>
      <TopRectangleWrapper>{renderTopRectangles()}</TopRectangleWrapper>
      <RectanglesGapWrapperHorizontal>
        <RectanglesWrapper>{renderBottomLeftRectangles()}</RectanglesWrapper>
        <RectanglesGapWrapperVertical>{renderRightBottomRectangles()}</RectanglesGapWrapperVertical>
      </RectanglesGapWrapperHorizontal>
    </RectanglesContainer>
  );
};

export default TabletAndMobileRectanglesLayout;
