import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ArrowButton} from '../../../../components/arrowButton';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  margin: ${getCurrentLayoutProperty({
    isDesktop: '20px 92px',
    isLaptop: '20px 92px',
    isTablet: '20px 72px auto 72px',
    isMobile: '20px 16px',
  })};
`;

export const VacancyControls = ({openForm}) => {
  const layout = useAppLayout();

  return (
    <Wrapper layout={layout}>
      <ArrowButton iconType={'right'} text={'contact us'} onClick={openForm} />
    </Wrapper>
  );
};

VacancyControls.propTypes = {
  openForm: PropTypes.func,
};
