import React from 'react';
import styled from 'styled-components';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import {PANEL_TEXT} from '../../constants/sectionSeven';
import {ANIMATE_FROM_BOTTOM} from '../../constants/common';
import {Header} from '../text';

const Section = styled(SectionWrapper)`
  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '160px',
    isTablet: '120px',
    isMobile: '80px',
  })};
  background: #11eaa3;
`;

const Content = styled(SectionContent)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 0',
    isLaptop: '80px 0',
    isTablet: '80px 0',
    isMobile: '40px 16px',
  })};
`;

const SectionHeader = styled(Header)`
  width: ${getCurrentLayoutProperty({
    isDesktop: '78%',
    isLaptop: '60%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const SectionSeven = () => {
  const layout = useAppLayout();

  return (
    <Section layout={layout} backgroundUrl={'/pages/home/wireframe-map.png'}>
      <Content layout={layout}>
        <SectionHeader layout={layout}>{PANEL_TEXT}</SectionHeader>
      </Content>
    </Section>
  );
};

export default SectionSeven;
