import React, {useMemo} from 'react';
import styled from 'styled-components';
import useAppLayout from '../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../utils/common';
import Footer from '../../components/footer';
import CheckOurProjects from './components/CheckOurProjects';

//----------- Page 1 Imports

import MainBanner from './components/MainBanner';
import TextBlock from './components/TextBlock';
import HorizontalTextBlock from './components/HorizontalTextBlock';
import DesktopRectanglesLayout from './components/DesktopRectanglesLayout';
import LaptopRectanglesLayout from './components/LaptopRectanglesLayout';
import TabletAndMobileRectanglesLayout from './components/TabletAndMobileRectanglesLayout';
import SloganPanel from './components/SloganPanel';

const mainBackgroundImageDesktop = '/case-studies/hayden/page1/BackgroundImageDesktop.webp';
const mainBackgroundImageLaptop = '/case-studies/hayden/page1/BackgroundImageLaptop.webp';
const mainBackgroundImageTablet = '/case-studies/hayden/page1/BackgroundImageTablet.webp';
const mainBackgroundImageMobile = '/case-studies/hayden/page1/BackgroundImageMobile.webp';

const tabletImageDesktop = '/case-studies/hayden/page1/TabletImageDesktop.webp';
const tabletImageLaptop = '/case-studies/hayden/page1/TabletImageLaptop.webp';
const tabletImageTablet = '/case-studies/hayden/page1/TabletImageTablet.webp';
const tabletImageMobile = '/case-studies/hayden/page1/TabletImageMobile.webp';

const HaydenLogoDesktop = '/case-studies/hayden/HaydenLogoDesktop.webp';
const HaydenLogoLaptop = '/case-studies/hayden/HaydenLogoLaptop.webp';
const HaydenLogoTablet = '/case-studies/hayden/HaydenLogoTablet.webp';
const HaydenLogoMobile = '/case-studies/hayden/HaydenLogoMobile.webp';

const statsDesktop = '/case-studies/hayden/page2/StatsDesktop.webp';
const statsLaptop = '/case-studies/hayden/page2/StatsLaptop.webp';
const statsTablet = '/case-studies/hayden/page2/StatsTablet.webp';
const statsMobile = '/case-studies/hayden/page2/StatsMobile.webp';

const dashboardDesktop = '/case-studies/hayden/page2/DashboardDesktop.webp';
const dashboardLaptop = '/case-studies/hayden/page2/DashboardLaptop.webp';
const dashboardTablet = '/case-studies/hayden/page2/DashboardTablet.webp';
const dashboardMobile = '/case-studies/hayden/page2/DashboardMobile.webp';

const page3TabletImageDesktop = '/case-studies/hayden/page3/tabletImageDesktop.webp';
const page3TabletImageLaptop = '/case-studies/hayden/page3/tabletImageLaptop.webp';
const page3TabletImageTablet = '/case-studies/hayden/page3/tabletImageTablet.webp';
const page3TabletImageMobile = '/case-studies/hayden/page3/tabletImageMobile.webp';

const informationSchemeDesktop = '/case-studies/hayden/page4/informationSchemeDesktop.webp';
const informationSchemeLaptop = '/case-studies/hayden/page4/informationSchemeLaptop.webp';
const informationSchemeTablet = '/case-studies/hayden/page4/InformationSchemeTablet.webp';
const informationSchemeMobile = '/case-studies/hayden/page4/informationSchemeMobile.webp';

const redundantTabletImageDesktop = '/case-studies/hayden/page5/tabletImageDesktop.webp';
const redundantTabletImageLaptop = '/case-studies/hayden/page5/tabletImageLaptop.webp';
const redundantTabletImageTablet = '/case-studies/hayden/page5/tabletImageTablet.webp';
const redundantTabletImageMobile = '/case-studies/hayden/page5/tabletImageMobile.webp';

const ongoingRelationshipDesktop = '/case-studies/hayden/page7/ongoingRelationshipDesktop.webp';
const ongoingRelationshipLaptop = '/case-studies/hayden/page7/ongoingRelationshipLaptop.webp';
const ongoingRelationshipTablet = '/case-studies/hayden/page7/ongoingRelationshipTablet.webp';
const ongoingRelationshipMobile = '/case-studies/hayden/page7/ongoingRelationshipMobile.webp';

const mainBackgroundImage = {
  isDesktop: mainBackgroundImageDesktop,
  isLaptop: mainBackgroundImageLaptop,
  isTablet: mainBackgroundImageTablet,
  isMobile: mainBackgroundImageMobile,
};

const tabletImage = {
  isDesktop: tabletImageDesktop,
  isLaptop: tabletImageLaptop,
  isTablet: tabletImageTablet,
  isMobile: tabletImageMobile,
};

const haydenHeaderLogo = {
  isDesktop: HaydenLogoDesktop,
  isLaptop: HaydenLogoLaptop,
  isTablet: HaydenLogoTablet,
  isMobile: HaydenLogoMobile,
};

const Text = {
  upperHeader: 'The secret one',
  mainHeader: 'Technology-First Approach to Road Safety and Traffic Flow',
  bages: ['product', 'web', 'digital cartography'],
  sectionHeader1: 'Moving cities forward',
  sectionText1: [
    'Hayden.ai is a fully autonomous traffic management platform. It is already used by the authorities of the four largest cities in the United States, and their presence area is constantly spreading.',
    'Together with their internal development team we were challenged to refine the user interface of the platform in order to prepare it for the scaling and significant user growth.',
  ],
};

//----------- End Page 1 Data

//----------- Page 2 Data

const statsImage = {
  isDesktop: statsDesktop,
  isLaptop: statsLaptop,
  isTablet: statsTablet,
  isMobile: statsMobile,
};

const dashboardImage = {
  isDesktop: dashboardDesktop,
  isLaptop: dashboardLaptop,
  isTablet: dashboardTablet,
  isMobile: dashboardMobile,
};

//----------- End Page 2 Data

//----------- Page 3 Data

const Page3Text = {
  header: 'Handled with care',
  description: `
    Working on such platforms requires a deep understanding of
    both the product itself and its users. One of our main
    challenges was to refresh the UI and prepare platform
    UX for scaling and significant user growth while
    keeping interface consistent with the Hayden
    branding and their other existing products.`,
};

const page3TabletImage = {
  isDesktop: page3TabletImageDesktop,
  isLaptop: page3TabletImageLaptop,
  isTablet: page3TabletImageTablet,
  isMobile: page3TabletImageMobile,
};

//----------- End Page 3 Data

//----------- Page 4 Data

const Page4Text = {
  header: 'Improved information architecture',
  description1: `
    The major improvement we provided to Hayden.ai
    was the information architecture of the platform.
    The first and second level of navigation
    needed huge refactoring as it was difficult
    to quickly find the way to solve particular problem.`,
  description2: `
    We suggested grouping all major sections into four
    main categories and giving them self-documenting names.
    Thus, we turned 13 navigation items into 4,
    and added three additional for the future scope.`,
};

const page4SchemeImage = {
  isDesktop: informationSchemeDesktop,
  isLaptop: informationSchemeLaptop,
  isTablet: informationSchemeTablet,
  isMobile: informationSchemeMobile,
};

//----------- End Page 4 Data

//----------- Page 5 Data

const redundantPageText = {
  header1: 'Hide the redundant',
  header2: 'Reveal the helpful',
  description1: `
    From pictures metadata to incidents less important details
    – we’ve hidden and totally removed a lot of things
    to keep users focused and not distract them
    from anything unimportant.`,
  description2: `
    Instead, we’ve brought everything that affects the final decision
    to the fore – so that the user surely does not miss anything critical`,
};

const redundantImage = {
  isDesktop: redundantTabletImageDesktop,
  isLaptop: redundantTabletImageLaptop,
  isTablet: redundantTabletImageTablet,
  isMobile: redundantTabletImageMobile,
};

//----------- End Page 5 Data

//----------- Page 6 Data

const updatedColorsPageText = {
  header: 'Updated colors',
  description: `
    We looked for refreshing the platform
    color palette, while maintaining
    continuity with the current
    client’s branding, and add
    new colors that would be
    convenient to use across
    the interface.`,
};

//----------- End Page 6 Data

//----------- Page 7 Data

const ongoingRelationshipPageText = {
  header: 'Ongoing relationship',
  description1: `
    Hayden.ai’s customer and internal web portals
    are now consistent and at production quality,
    ready for scaling across their customer market.
  `,
  description2: `
    Currently we are looking for an opportunity to estabilish
    ongoing collaboration across all Hayden.ai’s platforms
    as an embedded product design team.
  `,
};

const ongoingRelationshipImage = {
  isDesktop: ongoingRelationshipDesktop,
  isLaptop: ongoingRelationshipLaptop,
  isTablet: ongoingRelationshipTablet,
  isMobile: ongoingRelationshipMobile,
};

//----------- End Page 7 Data

//----------- Page 8 Data

const slogan1 = {
  header: '$20 million',
  description: 'Was raised by Hayden.ai in 2021',
  headerColor: '#010101',
  descriptionColor: '#010101',
  backgroundColor: '#F4E11B',
};
const slogan2 = {
  header: '4 major cities',
  description: 'Startup activity area',
  headerColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
  backgroundColor: '#2831F4',
};
const slogan3 = {
  header: '"Clean, familiar and easy to use"',
  description: 'General feedback from expert review',
  headerColor: '#FFFFFF',
  descriptionColor: '#FFFFFF',
  backgroundColor: '#010101',
};

const sloganFragmentHeight = {
  isDesktop: '320px',
  isLaptop: '271px',
  isTablet: '271px',
  isMobile: '186px',
};

//----------- End Page 8 Data

const WholePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  max-width: 1925px;
  margin: auto;
  ${(props) => (props.isDesktopType ? 'padding-left: 80px;' : 'margin-top: 0px')}
`;

const MainBannerUpperHeader = styled.div`
  color: #ffffff;
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;

const DashboardStatsGradient = styled.div`
  display: flex;
  justify-content: center;
  background-color: #2730f5;
  position: relative;

  &:before {
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(194deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%);
  }
`;

const DashboardStats = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const HandledWithCareGradient = styled.div`
  background-color: #010101;
  position: relative;
  padding-top: 40px;

  &:before {
    bottom: 0;
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    background: ${getCurrentLayoutProperty({
      isDesktop: 'linear-gradient(334deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%)',
      isLaptop: 'linear-gradient(334deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%)',
      isTablet: 'linear-gradient(334deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%)',
      isMobile: 'linear-gradient(341deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%)',
    })};
  }
`;

const TextBlockWrapper = styled.div`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: '45%',
    isLaptop: '45%',
    isTablet: '100%',
    isMobile: '100%',
  })};

  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'flex-start',
    isLaptop: 'flex-start',
    isTablet: 'center',
    isMobile: 'center',
  })};

  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const PageImageWrapper = styled.div`
  display: flex;
  padding-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '100px',
    isMobile: '40px',
  })};
  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'flex-end',
    isLaptop: 'center',
    isTablet: 'center',
    isMobile: 'center',
  })};
  margin-right: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const Image = styled.img`
  z-index: 1;
`;

const InformationArchitectureGradient = styled.div`
  display: flex;
  justify-content: center;
  background-color: #2730f5;
  position: relative;

  &:before {
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(to right, #ffffff, #ffffff 40%, rgba(0, 0, 0, 0) 40%),
      linear-gradient(69deg, #ffffff, #ffffff 50%, rgba(0, 0, 0, 0) 50%);
  }
`;

const RedundantPageGradientDesktopAndLaptop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(to right, #f4e11b, #f4e11b 50%, #010101 50%);
  }

  &:after {
    bottom: 0;
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(25deg, white 25%, rgba(0, 0, 0, 0) 25%),
      linear-gradient(335deg, white 25%, rgba(0, 0, 0, 0) 25%);
  }
`;

const RedundantPageTabletAndMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(189deg, #f4e11b 40%, black 40%);

  &:after {
    bottom: 0;
    height: 50vw;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(351deg, white 25%, rgba(0, 0, 0, 0) 25%);
  }
`;

const RedundantTextWrapperTabletAndMobile = styled.div`
  padding-top: 40px;
`;

const RedundantTextWrapperTabletAndMobile2 = styled(RedundantTextWrapperTabletAndMobile)`
  padding-bottom: 160px;
`;

const RedundantPageWrapperDesktopAndLaptop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 80px;
`;

const RedundantTextWrapperDesktopAndLaptop = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
`;

const InformationArchitectureWrapper = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};

  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '142px',
    isLaptop: '142px',
    isTablet: '0',
    isMobile: '0',
  })};

  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const InformationTextBlockWrapper = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '160px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const UpdatedColorsTextWrapper = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '180px',
    isLaptop: '98px',
    isTablet: '80px',
    isMobile: '30px',
  })};
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(50% - 200px)',
    isLaptop: 'calc(50% - 200px)',
    isTablet: '100%',
    isMobile: '100%',
  })};

  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '264px',
    isLaptop: '200px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const OngoingRelationshipGradient = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  justify-content: ${getCurrentLayoutProperty({
    isDesktop: 'space-between',
    isLaptop: 'rowspace-between',
    isTablet: 'center',
    isMobile: 'center',
  })};
  position: relative;
  background: #010101;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  z-index: -1;

  &:after {
    bottom: 0;
    height: ${getCurrentLayoutProperty({
      isDesktop: '50vw',
      isLaptop: '50vw',
      isTablet: '100vw',
      isMobile: '100vw',
    })};
    width: 100%;
    content: '';
    position: absolute;
    background: ${getCurrentLayoutProperty({
      isDesktop: 'linear-gradient(155deg, #010101, #010101 50%, #2831f4 50%);',
      isLaptop: 'linear-gradient(155deg, #010101, #010101 50%, #2831f4 50%);',
      isTablet: 'linear-gradient(155deg, #010101, #010101 25%, #2831f4 25%);',
      isMobile: 'linear-gradient(155deg, #010101, #010101 25%, #2831f4 25%);',
    })};
  }
`;

const OngoingRelationShipTextWrapper = styled.div`
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '149px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '0',
    isMobile: '0',
  })};
  z-index: 1;
`;

const OngoingImage = styled(Image)`
  z-index: 1;
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '-350px',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '0',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1416px',
    isLaptop: '1088px',
    isTablet: '1123px',
    isMobile: '614px',
  })};
`;

const getTextView = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return {
        Text: HorizontalTextBlock,
      };
    case layout.isTablet:
    case layout.isMobile:
      return {
        Text: TextBlock,
      };
    default:
      return {
        Text: TextBlock,
      };
  }
};

const DeskTopLaptopRedundantPage = (layout) => (
  <RedundantPageGradientDesktopAndLaptop layout={layout}>
    <RedundantPageWrapperDesktopAndLaptop>
      <RedundantTextWrapperDesktopAndLaptop>
        <TextBlock
          headerText={redundantPageText.header1}
          mainText={redundantPageText.description1}
          color="black"
          headerColor="black"
        />
      </RedundantTextWrapperDesktopAndLaptop>
      <RedundantTextWrapperDesktopAndLaptop>
        <TextBlock
          headerText={redundantPageText.header2}
          mainText={redundantPageText.description2}
          color="white"
          headerColor={'#F4E11B'}
          mainTextOpacity={'0.8'}
        />
      </RedundantTextWrapperDesktopAndLaptop>
    </RedundantPageWrapperDesktopAndLaptop>
    <Image src={getCurrentLayoutProperty(redundantImage)({layout})} alt="redundant image" />
  </RedundantPageGradientDesktopAndLaptop>
);

const TabletMobileRedundantPage = (layout) => (
  <RedundantPageTabletAndMobileContainer layout={layout}>
    <RedundantTextWrapperTabletAndMobile>
      <TextBlock
        headerText={redundantPageText.header1}
        mainText={redundantPageText.description1}
        color="black"
        headerColor="black"
      />
    </RedundantTextWrapperTabletAndMobile>

    <Image src={getCurrentLayoutProperty(redundantImage)({layout})} alt="redundant image" />
    <RedundantTextWrapperTabletAndMobile2>
      <TextBlock
        headerText={redundantPageText.header2}
        mainText={redundantPageText.description2}
        color="white"
        headerColor={'#F4E11B'}
        mainTextOpacity={'0.8'}
      />
    </RedundantTextWrapperTabletAndMobile2>
  </RedundantPageTabletAndMobileContainer>
);

const getRectanglesLayout = (layout) => {
  switch (true) {
    case layout.isDesktop:
      return DesktopRectanglesLayout;
    case layout.isLaptop:
      return LaptopRectanglesLayout;
    case layout.isTablet:
    case layout.isMobile:
      return TabletAndMobileRectanglesLayout;
    default:
      return DesktopRectanglesLayout;
  }
};

const renderRedundantPage = (layout) => {
  switch (true) {
    case layout.isDesktop:
    case layout.isLaptop:
      return DeskTopLaptopRedundantPage(layout);
    case layout.isTablet:
    case layout.isMobile:
      return TabletMobileRedundantPage(layout);
    default:
      return DeskTopLaptopRedundantPage(layout);
  }
};

const CaseStudiesSecond = () => {
  const layout = useAppLayout();
  const isDesktopType = layout.isDesktop || layout.isLaptop;
  const TextBlockComponent = useMemo(() => getTextView(layout), [layout]);

  const RectanglesLayout = getRectanglesLayout(layout);

  return (
    <WholePageContainer isDesktopType={isDesktopType}>
      <MainBanner
        backgroundImage={mainBackgroundImage}
        upperHeader={
          <MainBannerUpperHeader>
            <Image src={getCurrentLayoutProperty(haydenHeaderLogo)({layout})} alt="hayden header logo" />
          </MainBannerUpperHeader>
        }
        mainHeader={Text.mainHeader}
        bages={Text.bages}
        tabletImage={tabletImage}
      />
      <TextBlockComponent.Text
        headerText={Text.sectionHeader1}
        mainText={Text.sectionText1}
        color="#010101"
        headerColor="#000000"
      />
      <DashboardStatsGradient>
        <DashboardStats>
          <Image src={getCurrentLayoutProperty(statsImage)({layout})} alt="stats image" loading="lazy" />
          <Image src={getCurrentLayoutProperty(dashboardImage)({layout})} alt="dashboard image" loading="lazy" />
        </DashboardStats>
      </DashboardStatsGradient>
      <HandledWithCareGradient layout={layout}>
        <TextBlockWrapper layout={layout}>
          <TextBlock
            headerText={Page3Text.header}
            mainText={Page3Text.description}
            color="white"
            headerColor="white"
            mainTextOpacity={'0.8'}
          />
        </TextBlockWrapper>
        <PageImageWrapper layout={layout}>
          <Image src={getCurrentLayoutProperty(page3TabletImage)({layout})} alt="tablet image" loading="lazy" />
        </PageImageWrapper>
      </HandledWithCareGradient>
      <InformationArchitectureWrapper layout={layout}>
        <InformationTextBlockWrapper layout={layout}>
          <TextBlock
            headerText={Page4Text.header}
            mainText={[Page4Text.description1, Page4Text.description2]}
            color="black"
            headerColor="black"
          />
        </InformationTextBlockWrapper>
        <InformationArchitectureGradient layout={layout}>
          <Image src={getCurrentLayoutProperty(page4SchemeImage)({layout})} alt="scheme image" loading="lazy" />
        </InformationArchitectureGradient>
      </InformationArchitectureWrapper>
      {renderRedundantPage(layout)}
      <UpdatedColorsTextWrapper layout={layout}>
        <TextBlock
          headerText={updatedColorsPageText.header}
          mainText={updatedColorsPageText.description}
          color="black"
          headerColor="black"
        />
      </UpdatedColorsTextWrapper>
      <RectanglesLayout />
      <OngoingRelationshipGradient layout={layout}>
        <OngoingRelationShipTextWrapper layout={layout}>
          <TextBlock
            headerText={ongoingRelationshipPageText.header}
            mainText={[ongoingRelationshipPageText.description1, ongoingRelationshipPageText.description2]}
            color="white"
            headerColor="white"
          />
        </OngoingRelationShipTextWrapper>
        <OngoingImage
          layout={layout}
          src={getCurrentLayoutProperty(ongoingRelationshipImage)({layout})}
          alt="relationship image"
        />
      </OngoingRelationshipGradient>
      <SloganPanel
        slogan1={slogan1}
        slogan2={slogan2}
        slogan3={slogan3}
        height={getCurrentLayoutProperty(sloganFragmentHeight)({layout})}
      />
      <CheckOurProjects currentProjectName={'hayden'} />
      <Footer />
    </WholePageContainer>
  );
};

export default CaseStudiesSecond;
