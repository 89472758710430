import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextBlock from './TextBlock';
import useAppLayout from '../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../utils/common';

const propTypes = {
  slogan1: PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerColor: PropTypes.string.isRequired,
    descriptionColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  slogan2: PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerColor: PropTypes.string.isRequired,
    descriptionColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  slogan3: PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerColor: PropTypes.string.isRequired,
    descriptionColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  height: PropTypes.string.isRequired,
};

const SloganPanelWrapper = styled.div`
  display: flex;
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '160px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '80px',
  })};
  margin-left: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '79px',
    isTablet: '0',
    isMobile: '0',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: '89%',
    isLaptop: '86%',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const SlogansContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalPanel = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tile = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => (props.width ? `calc(100% - ${props.width})` : props.autoWidth ? 'auto' : '100%')};
  background: ${(props) => props.slogan.backgroundColor};

  display: flex;
  align-items: center;
  white-space: ${getCurrentLayoutProperty({
    isDesktop: 'nowrap',
    isLaptop: 'nowrap',
    isTablet: 'none',
    isMobile: 'none',
  })};

  padding-left: ${getCurrentLayoutProperty({
    isDesktop: '56px',
    isLaptop: '40px',
    isTablet: '0',
    isMobile: '0',
  })};

  padding-right: ${getCurrentLayoutProperty({
    isDesktop: '93px',
    isLaptop: '64px',
    isTablet: '0',
    isMobile: '0',
  })};
`;

const GradientTile = styled.div`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  position: relative;

  &:before {
    bottom: 0;
    height: ${(props) => props.size};
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(
      to right bottom,
      ${(props) => props.firstColor} 50%,
      ${(props) => props.secondColor} 50%
    );
  }
`;

const SloganPanel = (props) => {
  const layout = useAppLayout();

  const getPanel = (layout, props) => {
    switch (true) {
      case layout.isDesktop:
      case layout.isLaptop:
        return <DesktopAndLaptopPanel {...props} />;
      case layout.isTablet:
        return <TabletPanel {...props} />;
      case layout.isMobile:
        return <MobilePanel {...props} />;
      default:
        return <DesktopAndLaptopPanel {...props} />;
    }
  };

  const renderTile = (slogan, height, autoWidth = false, width = 0) => (
    <Tile slogan={slogan} height={height} autoWidth={autoWidth} width={width} layout={layout}>
      <TextBlock
        headerText={slogan.header}
        mainText={slogan.description}
        color={slogan.descriptionColor}
        headerColor={slogan.headerColor}
        headerLineHeight={'133%'}
        headerMarginBottom={'0px'}
      />
    </Tile>
  );

  const DesktopAndLaptopPanel = ({slogan1, slogan2, slogan3, height}) => (
    <SlogansContainer>
      <HorizontalPanel>
        {renderTile(slogan1, height, true)}
        {renderTile(slogan2, height)}
      </HorizontalPanel>
      <HorizontalPanel>
        {renderTile(slogan3, height, false, height)}
        <GradientTile size={height} firstColor={slogan2.backgroundColor} secondColor={slogan3.backgroundColor} />
      </HorizontalPanel>
    </SlogansContainer>
  );

  const TabletPanel = ({slogan1, slogan2, slogan3, height}) => (
    <SlogansContainer>
      {renderTile(slogan1, height, true)}
      {renderTile(slogan2, height)}
      <HorizontalPanel>
        {renderTile(slogan3, height, false, height)}
        <GradientTile size={height} firstColor={slogan2.backgroundColor} secondColor={slogan3.backgroundColor} />
      </HorizontalPanel>
    </SlogansContainer>
  );

  const MobilePanel = ({slogan1, slogan2, slogan3, height}) => (
    <SlogansContainer>
      {renderTile(slogan1, height, true)}
      {renderTile(slogan2, height)}
      {renderTile(slogan3, height)}
      <HorizontalPanel>
        {renderTile({backgroundColor: slogan2.backgroundColor}, height, false, height)}
        <GradientTile size={height} firstColor={slogan3.backgroundColor} secondColor={slogan2.backgroundColor} />
      </HorizontalPanel>
    </SlogansContainer>
  );

  const panel = getPanel(layout, props);

  DesktopAndLaptopPanel.propTypes = propTypes;
  TabletPanel.propTypes = propTypes;
  MobilePanel.propTypes = propTypes;

  return <SloganPanelWrapper layout={layout}>{panel}</SloganPanelWrapper>;
};

export default SloganPanel;
