import React from 'react';
import styled from 'styled-components';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {
  FIRST_IMAGE_TITLE,
  SECOND_IMAGE_TITLE,
  THIRD_IMAGE_TITLE,
  FOURTH_IMAGE_TITLE,
  FIRST_IMAGE_DESCRIPTION,
  SECOND_IMAGE_DESCRIPTION,
  THIRD_IMAGE_DESCRIPTION,
  FOURTH_IMAGE_DESCRIPTION,
} from '../../constants/sectionThree';
import ImageBlock from './ImageBlock';

const ImagesBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
  padding: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '0',
    isMobile: '0 16px',
  })};
`;

const TopImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

const BottomImages = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '40px',
    isTablet: '40px',
    isMobile: '24px',
  })};
`;

// TODO move backgroundUrl to constants
const ImagesBlock = () => {
  const layout = useAppLayout();

  return (
    <ImagesBlockWrapper layout={layout}>
      <TopImages layout={layout}>
        <ImageBlock
          title={FIRST_IMAGE_TITLE}
          description={FIRST_IMAGE_DESCRIPTION}
          image={'/pages/home/card-one.webp'}
          backgroundColor={'#713df5'}
        />
        <ImageBlock
          title={SECOND_IMAGE_TITLE}
          description={SECOND_IMAGE_DESCRIPTION}
          image={'/pages/home/card-two.webp'}
          textColor={'black'}
          backgroundColor={'#11eaa3'}
        />
      </TopImages>
      <BottomImages layout={layout}>
        <ImageBlock
          title={THIRD_IMAGE_TITLE}
          description={THIRD_IMAGE_DESCRIPTION}
          image={'/pages/home/card-three.webp'}
          backgroundColor={'#010101'}
          centered
        />
        <ImageBlock
          title={FOURTH_IMAGE_TITLE}
          description={FOURTH_IMAGE_DESCRIPTION}
          image={'/pages/home/card-four.webp'}
          backgroundColor={'#713df5'}
          centered
        />
      </BottomImages>
    </ImagesBlockWrapper>
  );
};

export default ImagesBlock;
