import React from 'react';

import useAppLayout from '../../../../utils/useAppLayout';
import {SectionWrapper, SectionContent} from '../../../../components/common';
import ImagesBlock from './ImagesBlock';

const SectionThree = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <SectionContent layout={layout}>
        <ImagesBlock />
      </SectionContent>
    </SectionWrapper>
  );
};

export default SectionThree;
