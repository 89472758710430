import React, {useMemo} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import useAppLayout from '../../utils/useAppLayout';
import Project from './project';
import {projects} from './constants';

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Projects = ({headerId = '', currentProjectName}) => {
  const layout = useAppLayout();
  const projectsToShow = useMemo(
    () => projects.filter((project) => project.name !== currentProjectName),
    [currentProjectName]
  );

  return projectsToShow.map(
    (
      {
        desktopImage,
        tabletImage,
        mobileImage,
        logo,
        description,
        link,
        backgroundColor,
        textColor,
        arrowButtonBackground,
        arrowColor,
        solution,
      },
      index
    ) => (
      <ProjectWrapper id={headerId} layout={layout} key={`project-${index}`}>
        <Project
          logo={logo}
          description={description}
          link={link}
          image={layout.isDesktop || layout.isLaptop ? desktopImage : layout.isTablet ? tabletImage : mobileImage}
          backgroundColor={backgroundColor}
          textColor={textColor}
          arrowButtonBackground={arrowButtonBackground}
          arrowColor={arrowColor}
          solution={solution}
        />
      </ProjectWrapper>
    )
  );
};

Projects.propTypes = {
  headerId: PropTypes.string,
  currentProjectName: PropTypes.string,
};

export default Projects;
