import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {HeaderSmall, SubHeaderSmall, BoldText} from '../../../../components/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  box-shadow: 0px 33px 80px rgba(0, 0, 0, 0.05), 0px 7.37098px 17.869px rgba(0, 0, 0, 0.0298054),
    0px 2.19453px 5.32008px rgba(0, 0, 0, 0.0201946);
  border-radius: 24px;
  height: fit-content;

  padding: ${getCurrentLayoutProperty({
    isDesktop: '42px 32px 61px 32px',
    isLaptop: '41px 32px 60px 32px',
    isTablet: '42px 32px 60px 32px',
    isMobile: '25px 16px 24px 16px',
  })};

  width: ${getCurrentLayoutProperty({
    isDesktop: '370px',
    isLaptop: '340px',
    isTablet: '100%',
    isMobile: '100%',
  })};

  margin-bottom: ${getCurrentLayoutProperty({
    isDesktop: '0',
    isLaptop: '0',
    isTablet: '30px',
    isMobile: '24px',
  })};
`;

const ReviewerInfo = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Picture = styled.div`
  margin-right: 17px;
  background: url(${({background}) => background}) center center no-repeat;
  background-size: contain;

  width: ${getCurrentLayoutProperty({
    isDesktop: '75px',
    isLaptop: '75px',
    isTablet: '75px',
    isMobile: '75px',
  })};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled(HeaderSmall)`
  width: 100%;
  margin-top: 0;
  margin-bottom: 8px;
`;

const ReviewerCard = ({name, position, text, image}) => {
  const layout = useAppLayout();

  return (
    <Container layout={layout}>
      <ReviewerInfo>
        <Picture layout={layout} background={image} />
        <InfoWrapper>
          <Header layout={layout}>{name}</Header>
          <SubHeaderSmall layout={layout}>{position}</SubHeaderSmall>
        </InfoWrapper>
      </ReviewerInfo>
      <BoldText layout={layout}>{text}</BoldText>
    </Container>
  );
};

ReviewerCard.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default ReviewerCard;
