import React, {useRef, useState} from 'react';
import styled from 'styled-components';

import useAppLayout from '../../../utils/useAppLayout';
import {FormTextArea} from '../form-textArea';
import {useForm} from 'react-hook-form';

import {FOOTER_TEXT} from '../../../pages/home/constants/sectionNine';
import {getCurrentLayoutProperty} from '../../../utils/common';
import {ArrowButton} from '../../arrowButton';
import {GreetingsCard} from '../greetings-card';
import {DesktopForm} from './desktop-contact-form';
import {MobileForm} from './mobile-contact-form';

const Container = styled.div`
  max-width: ${getCurrentLayoutProperty({
    isDesktop: '808px',
    isLaptop: '808px',
    isTablet: '100%',
    isMobile: '100%',
  })};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContactForm = () => {
  const layout = useAppLayout();
  const [sendEmail, setSendEmail] = useState(false);
  const {register, handleSubmit, formState} = useForm();
  const {errors} = formState;
  const refForm = useRef();

  const handleApply = (data) => {
    setSendEmail(!sendEmail);

    fetch('https://formsubmit.co/ajax/info@actionengine.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  };

  return (
    <Container layout={layout}>
      {sendEmail ? (
        <GreetingsCard isActionForm />
      ) : (
        <Form onSubmit={handleSubmit(handleApply)}>
          {layout.isMobile ? (
            <MobileForm register={register} errors={errors} />
          ) : (
            <DesktopForm register={register} errors={errors} />
          )}
          <FormTextArea label={'Project Details or Topic of Interest'} placeholder="Optional" register={register} />
          <ArrowButton text={FOOTER_TEXT} onClick={() => refForm.current?.submit()} />
        </Form>
      )}
    </Container>
  );
};
