import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Text} from '../../components/common';
import {getCurrentLayoutProperty} from '../../utils/common';
import useAppLayout from '../../utils/useAppLayout';
import {CloseButton} from '../popup-wrapper';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  position: relative;
  padding: ${getCurrentLayoutProperty({
    isTablet: '0 92px 45px 92px',
    isMobile: '0 16px 45px 16px',
  })};

  > h3 {
    font-weight: bold;
    line-height: 140%;
    font-size: ${getCurrentLayoutProperty({
      isTablet: '24px',
      isMobile: '17px',
    })};
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  background: url(${(props) => props.picture}) center;
  background-size: cover;
  height: 48vh;
  width: 100vw;

  > p {
    font-weight: 800;
    color: white;
    margin: 0;
    line-height: ${getCurrentLayoutProperty({
      isTablet: '90px',
      isMobile: '50px',
    })};
    padding: ${getCurrentLayoutProperty({
      isTablet: '70px 92px',
      isMobile: '150px 16px 32px 16px',
    })};
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    font-size: ${getCurrentLayoutProperty({
      isTablet: '86px',
      isMobile: '42px',
    })};
  }
`;

const SwipeItemCard = ({title, picture, description, content, closeItemCard}, index) => {
  const layout = useAppLayout();

  return (
    <Wrapper key={`new-${index}`} layout={layout}>
      <CloseButton layout={layout} onClick={closeItemCard}>
        <img src="/icons/close.svg" alt="close" />
      </CloseButton>
      <HeaderWrapper picture={picture} layout={layout}>
        <p>{title}</p>
      </HeaderWrapper>
      <h3>{description}</h3>
      {content?.map((el, i) => (
        <Text key={`content-${i}`} layout={layout}>
          {el}
        </Text>
      ))}
    </Wrapper>
  );
};

export default SwipeItemCard;

SwipeItemCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  picture: PropTypes.string,
  content: PropTypes.array,
  closeItemCard: PropTypes.func,
};
