import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {Canvas, useFrame, useThree} from '@react-three/fiber';
import countries from '../../../resources/countries.json';
import useAppLayout from '../../../utils/useAppLayout';
import ThreeGlobe from 'three-globe';
import {Glow} from '../../../styles/glow-mixin';
import {getCurrentLayoutProperty} from '../../../utils/common';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

export const GlowBox = styled.div`
  position: absolute;
  top: ${getCurrentLayoutProperty({
    isDesktop: '90px',
    isLaptop: '90px',
    isTablet: '80px',
    isMobile: '20px',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '-35px',
    isLaptop: '-115px',
    isTablet: '-415px',
    isMobile: '-400px',
  })};
  border-radius: 50%;
  width: ${getCurrentLayoutProperty({
    isDesktop: '990px',
    isLaptop: '850px',
    isTablet: '990px',
    isMobile: '680px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '980px',
    isLaptop: '850px',
    isTablet: '990px',
    isMobile: '665px',
  })};
  background-color: transparent;
  z-index: -2;
  ${Glow({
    x: '0',
    y: '0',
    spread: '3px',
    size: '1',
    colorA: '#feec34',
    colorB: '#11eaa3',
    duration: '17s',
    radius: '50%',
  })};
  animation: appear 10s ease;
  opacity: 0.85;
  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: ${getCurrentLayoutProperty({
        isDesktop: '0.85',
        isLaptop: '0.85',
        isTablet: '0.75',
        isMobile: '0.75',
      })};
    }
  }
`;

export const PlanetPicture = styled.div`
  width: ${getCurrentLayoutProperty({
    isDesktop: '1300px',
    isLaptop: '1130px',
    isTablet: '1300px',
    isMobile: '900px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1000px',
    isLaptop: '920px',
    isTablet: '1100px',
    isMobile: '885px',
  })};
  position: absolute;
  top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '55px',
    isTablet: '20px',
    isMobile: '-90px',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '-25px',
    isLaptop: '-120px',
    isTablet: '-405px',
    isMobile: '-415px',
  })};
  z-index: -3;
  background: url('/pages/home/Planet.svg') no-repeat;
  background-position: right;
  background-size: 76% auto;
  animation: dissappear both 10s ease;
  animation-name: dissappear, zoomOut;
  animation-duration: 5s, 1s;
  @keyframes dissappear {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes zoomOut {
    from {
      transform: scale(0.2);
    }
    to {
      transform: scale(1);
    }
  }
`;

const CanvasWrapper = styled.div`
  position: absolute;
  width: ${getCurrentLayoutProperty({
    isDesktop: '1080px',
    isLaptop: '900px',
    isTablet: '960px',
    isMobile: '660px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: '1000px',
    isLaptop: '870px',
    isTablet: '960px',
    isMobile: '660px',
  })};
  top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '20px',
  })};
  right: ${getCurrentLayoutProperty({
    isDesktop: '-80px',
    isLaptop: '-140px',
    isTablet: '-370px',
    isMobile: '-370px',
  })};
  animation: appear both 12s ease;
  display: block;
  cursor: grabbing;
  z-index: ${(props) =>
    props.form
      ? '-1'
      : getCurrentLayoutProperty({
          isDesktop: 'inherit',
          isLaptop: 'inherit',
          isTablet: '-1',
          isMobile: '-1',
        })};
  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const CameraController = () => {
  const {camera, gl} = useThree();

  useEffect(() => {
    const controls = new OrbitControls(camera, gl.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.minPolarAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 2;

    return () => {
      controls.dispose();
    };
  }, [camera, gl]);
  return null;
};

const Globe = () => {
  const {scene} = useThree();

  useEffect(() => {
    const animateGlobe = () => {
      const MyGlobe = new ThreeGlobe();
      MyGlobe.hexPolygonsData(countries.features)
        .showGlobe(false)
        .showAtmosphere(false)
        .hexPolygonResolution(2)
        .hexPolygonMargin(0.05)
        .hexPolygonColor((e) => {
          if (['RUS'].includes(e.properties.ISO_A3)) {
            return '#006400';
          } else if (['USA'].includes(e.properties.ISO_A3)) {
            return 'orange';
          } else if (['SRB'].includes(e.properties.ISO_A3)) {
            return 'lime';
          } else return '#004b23';
        });
      scene.add(MyGlobe);
    };

    animateGlobe();
  }, [scene]);

  return null;
};

const GlobeWrapper = () => {
  const globeRef = useRef();

  useFrame((state) => {
    state.scene.rotation.y -= 0.0007;
  });

  return (
    <mesh ref={globeRef}>
      <Globe />
    </mesh>
  );
};

const Planet = ({form}) => {
  const layout = useAppLayout();

  return (
    <>
      <CanvasWrapper layout={layout} form={form ? 1 : 0}>
        <Canvas camera={{fov: 45, position: [0, 50, 260]}}>
          <CameraController />
          <GlobeWrapper />
          <ambientLight />
          <directionalLight color={'yellow'} intensity={2} position={[-200, 500, 200]} />
          <pointLight color={'green'} intensity={2} position={[-200, 400, 300]} />
        </Canvas>
      </CanvasWrapper>
      <PlanetPicture layout={layout} />
      <GlowBox layout={layout} />
    </>
  );
};

Planet.propTypes = {
  form: PropTypes.bool,
};

export default Planet;
