export const LINKS = [
  {
    id: 0,
    background: {
      color: '#713df5',
      opacityColor: 'rgba(113, 61, 245, 0.85)',
    },
    textColor: 'white',
    path: '/home',
    text: 'Home',
  },
  {
    id: 1,
    background: {
      color: '#11EAA3',
      opacityColor: 'rgba(17, 234, 163, 0.85)',
    },
    textColor: 'black',
    path: '/capabilities',
    text: 'Capabilities',
  },
  {
    id: 2,
    background: {
      color: '#f4e11b',
      opacityColor: 'rgba(244, 225, 27, 0.85)',
    },
    textColor: 'black',
    path: '/work',
    text: 'Our Work',
    counter: '06',
  },
  {
    id: 3,
    background: {
      color: '#ff4242',
      opacityColor: 'rgba(255, 66, 66, 0.85)',
    },
    textColor: 'white',
    path: '/inside',
    text: 'Inside the Engine',
  },
];
