export const FIRST_IMAGE_TITLE = 'Platforms & Apps';
export const SECOND_IMAGE_TITLE = 'Visualization & Mapping';
export const THIRD_IMAGE_TITLE = 'GeoData Engineering';
export const FOURTH_IMAGE_TITLE = 'Powered Analytics';
export const FOOTER_TEXT = 'learn more about our services';

export const FIRST_IMAGE_DESCRIPTION =
  'Custom engineered geospatial apps and platforms personalized to your unique needs';
export const SECOND_IMAGE_DESCRIPTION =
  'A designed approach to layered visualization with vis.gl toolset including kepler.gl and deck.gl, with extremely fast rendering of large 3D data sets';
export const THIRD_IMAGE_DESCRIPTION =
  'Ingestion and enrichment of petabyte of data in real-time to power your platforms and applications.';
export const FOURTH_IMAGE_DESCRIPTION =
  'Leveraging AI and ML to extract insights from geodata, to produce actionable intelligence';
