export const projects = [
  {
    id: 0,
    name: 'cesium',
    desktopImage: '/pages/work/cesium-desktop.webp',
    tabletImage: '/pages/work/cesium-tablet.webp',
    mobileImage: '/pages/work/cesium-mobile.webp',
    logo: '/pages/work/cesium-logo.webp',
    description: 'Adding I3S Building Scene Layer Support for Cesium Engine',
    link: '/casestudies-cesium',
    arrowButtonBackground: '#ff4242',
    backgroundColor: '#F4E11B',
    textColor: 'black',
  },
  {
    id: 1,
    name: 'foursquare',
    desktopImage: '/pages/work/foursquare-desktop.webp',
    tabletImage: '/pages/work/foursquare-tablet.webp',
    mobileImage: '/pages/work/foursquare-mobile.webp',
    logo: '/pages/work/esri-logo.webp',
    description: 'Geospatial Data Conversions for ESRI',
    link: '/casestudies-esri',
    backgroundColor: '#713df5',
    textColor: 'white',
  },
  {
    id: 2,
    name: 'aqueous',
    desktopImage: '/pages/work/aqueous-desktop.webp',
    tabletImage: '/pages/work/aqueous-tablet.webp',
    mobileImage: '/pages/work/aqueous-mobile.webp',
    logo: '/pages/work/fireproof.webp',
    description: 'Commercial Real-Estate Analytics & Investment',
    link: '/casestudies-fireproof',
    backgroundColor: '#010101',
    textColor: 'white',
  },
  {
    id: 3,
    name: 'hayden',
    desktopImage: '/pages/work/hayden-desktop.webp',
    tabletImage: '/pages/work/hayden-tablet.webp',
    mobileImage: '/pages/work/hayden-mobile.webp',
    logo: '/pages/work/hayden-logo.webp',
    description: 'Technology-First Approach to Road Safety and Traffic Flow',
    link: '/casestudies-hayden',
    backgroundColor: '#11eaa3',
    textColor: 'black',
  },
  {
    id: 4,
    name: 'kepler',
    desktopImage: '/pages/work/kepler-desktop.webp',
    tabletImage: '/pages/work/kepler-tablet.webp',
    mobileImage: '/pages/work/kepler-mobile.webp',
    logo: '/pages/work/foursquare.webp',
    description: 'Improving and Optimizing Kepler.gl with Typescript',
    link: '/casestudies-kepler',
    backgroundColor: '#713df5',
    textColor: 'white',
  },
  {
    id: 5,
    name: 'deforestation',
    desktopImage: '/pages/work/deforest-desktop.webp',
    tabletImage: '/pages/work/deforest-tablet.webp',
    mobileImage: '/pages/work/deforest-mobile.webp',
    logo: '/pages/work/initiative.webp',
    description: 'Monitoring Deforestation with Actionable Geospatial Data Analytics',
    link: '/casestudies-deforestation',
    backgroundColor: '#f4e11b',
    textColor: 'black',
    arrowButtonBackground: '#ff4242',
    arrowColor: 'white',
    solution: true,
  },
];
