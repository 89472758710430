import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {VacancyCard} from './vacancy-card';

const FlexContainer = styled.div`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: '45vw',
    isLaptop: '50vw',
    isTablet: 'calc(100vw - 100px)',
    isMobile: 'calc(100vw - 25px)',
  })};

  overflow: auto;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  scrollbar-width: none;

  flex-flow: ${getCurrentLayoutProperty({
    isDesktop: 'row wrap',
    isLaptop: 'row wrap',
    isTablet: 'none',
  })};
  gap: ${getCurrentLayoutProperty({
    isDesktop: '40px',
    isLaptop: '34px',
    isTablet: '24px',
    isMobile: '16px',
  })};
  height: ${getCurrentLayoutProperty({
    isDesktop: 'auto',
    isLaptop: 'auto',
    isTablet: 'auto',
    isMobile: 'auto',
  })};
`;

export const VacanciesSlider = ({options, openVacancy, vacancy}) => {
  const layout = useAppLayout();

  return (
    <FlexContainer layout={layout}>
      {options.map(({title, description, id}) => (
        <div key={id} onClick={() => openVacancy(options[id])}>
          <VacancyCard
            key={id}
            onClick={() => openVacancy(options[id])}
            id={id}
            title={title}
            description={description}
            optionId={vacancy?.id}
          />
        </div>
      ))}
    </FlexContainer>
  );
};

VacanciesSlider.propTypes = {
  options: PropTypes.array,
  openVacancy: PropTypes.func,
  vacancy: PropTypes.object,
};
