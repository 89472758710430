import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {getCurrentLayoutProperty} from '../utils/common';
import useAppLayout from '../utils/useAppLayout';

const DEFAULT_TEXT_COLOR = '#010101';

const Container = styled.li`
  display: flex;
  align-items: ${({bulletImage}) => (bulletImage ? 'flex-start' : 'center')};
  gap: ${({bulletImage}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletImage ? '40px' : '35px',
      isLaptop: bulletImage ? '40px' : '35px',
      isTablet: bulletImage ? '40px' : '35px',
      isMobile: bulletImage ? '24px' : '16px',
    })};
  ${({bulletImage, itemNumber}) => {
    if (itemNumber) {
      return css`
        &:before {
          align-self: flex-start;
          flex-shrink: 0;
          content: \'0${itemNumber}\';

          color: #ff4242;
          font-feature-settings: 'ss01' on;
          line-height: 120%;

          width: ${getCurrentLayoutProperty({
            isDesktop: '116px',
            isLaptop: '116px',
            isTablet: '116px',
            isMobile: '75px',
          })};

          font-weight: ${getCurrentLayoutProperty({
            isDesktop: '400',
            isLaptop: '400',
            isTablet: '400',
            isMobile: '800',
          })};

          font-size: ${getCurrentLayoutProperty({
            isDesktop: '103px',
            isLaptop: '103px',
            isTablet: '103px',
            isMobile: '60px',
          })};

          letter-spacing: ${getCurrentLayoutProperty({
            isDesktop: '-0.02em',
            isLaptop: '-0.02em',
            isTablet: '-0.02em',
            isMobile: '-0.01em',
          })};
        }
      `;
    } else if (!bulletImage) {
      return css`
        &:before {
          flex-shrink: 0;
          height: 24px;
          width: 24px;
          background-color: #11eaa3;
          content: '';
        }
      `;
    }
  }}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({header}) => (header ? 'flex-start' : 'center')};
  color: ${({textColor}) => textColor || DEFAULT_TEXT_COLOR};
`;

const Header = styled.div`
  font-family: 'Kontora Medium';
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  font-feature-settings: 'ss01' on;

  letter-spacing: ${getCurrentLayoutProperty({
    isDesktop: 'normal',
    isLaptop: 'normal',
    isTablet: 'normal',
    isMobile: '0.01em',
  })};

  font-size: ${getCurrentLayoutProperty({
    isDesktop: '35px',
    isLaptop: '29px',
    isTablet: '29px',
    isMobile: '24px',
  })};
`;

const Text = styled.div`
  margin-top: ${({header}) => (header ? '6px' : 0)};
  font-style: normal;
  font-feature-settings: 'ss01' on;

  font-family: ${({bulletImage}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletImage ? 'Kontora SemBd' : 'Kontora Medium',
      isLaptop: bulletImage ? 'Kontora Medium' : 'Kontora SemBd',
      isTablet: bulletImage ? 'Kontora Medium' : 'Kontora SemBd',
      isMobile: 'Kontora SemBd',
    })}};

  line-height: ${({bulletImage}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletImage ? '150%' : '140%',
      isLaptop: bulletImage ? '130%' : '150%',
      isTablet: bulletImage ? '130%' : '150%',
      isMobile: '140%',
    })};

  font-weight: ${({bulletImage}) =>
    getCurrentLayoutProperty({
      isDesktop: bulletImage ? '600' : '500',
      isLaptop: bulletImage ? '700' : '600',
      isTablet: bulletImage ? '700' : '600',
      isMobile: '600',
    })};

  font-size: ${({header}) =>
    getCurrentLayoutProperty({
      isDesktop: header ? '24px' : '29px',
      isLaptop: header ? '20px' : '24px',
      isTablet: header ? '20px' : '24px',
      isMobile: '17px',
    })};

    letter-spacing: ${({bulletImage}) =>
      getCurrentLayoutProperty({
        isDesktop: 'normal',
        isLaptop: bulletImage ? '0.02em' : 'normal',
        isTablet: 'normal',
        isMobile: '0.01em',
      })}};
`;

const BulletImage = styled.img`
  object-fit: contain;

  width: ${getCurrentLayoutProperty({
    isDesktop: '102px',
    isLaptop: '60px',
    isTablet: '60px',
    isMobile: '60px',
  })};
`;

const BulletItem = ({header, text, bulletImage, bulletImageAlt, textColor, itemNumber}) => {
  const layout = useAppLayout();

  return (
    <Container layout={layout} bulletImage={bulletImage} textColor={textColor} itemNumber={itemNumber}>
      {bulletImage && <BulletImage layout={layout} src={bulletImage} alt={bulletImageAlt} />}
      <Content textColor={textColor} header={header}>
        {header && <Header layout={layout}>{header}</Header>}
        <Text layout={layout} bulletImage={bulletImage} header={header}>
          {text}
        </Text>
      </Content>
    </Container>
  );
};

BulletItem.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string.isRequired,
  bulletImage: PropTypes.string,
  bulletImageAlt: PropTypes.string,
  textColor: PropTypes.string,
  itemNumber: PropTypes.number,
};

export default BulletItem;
