import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useAppLayout from '../../../../utils/useAppLayout';
import {getCurrentLayoutProperty} from '../../../../utils/common';
import {Header, SectionWrapper, SectionContent, AEBackgroundWrapper} from '../../../../components/common';
import Logo from '../logo';
import {ContactForm} from '../../../../components/forms/contact-form/contact-form';
import {HEADER} from '../../constants/sectionNine';
import {VideoBackground} from '../../../../components/planet-video';
import Planet from '../planet';

const Section = styled(SectionWrapper)`
  padding: ${getCurrentLayoutProperty({
    isDesktop: '80px 92px',
    isLaptop: '80px 92px',
    isTablet: '80px 92px',
    isMobile: '40px 16px',
  })};
  margin: auto;
  margin-bottom: 0;
  position: relative;
  max-width: 1925px;
`;

const Title = styled(Header)`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '72px',
    isLaptop: '72px',
    isTablet: '72px',
    isMobile: '29px',
  })};
  width: 100%;
  margin: 60px 0;
`;

const Background = styled(AEBackgroundWrapper)`
  height: auto;
`;

const SectionNine = ({headerId, isWebGL}) => {
  const layout = useAppLayout();

  return (
    <Background layout={layout} isWebGL={isWebGL}>
      <Section layout={layout}>
        <SectionContent layout={layout} id={headerId}>
          {isWebGL ? <Planet form /> : <VideoBackground />}
          <Logo layout={layout} />
          <Title layout={layout}>{HEADER}</Title>
          <ContactForm />
        </SectionContent>
      </Section>
    </Background>
  );
};

SectionNine.propTypes = {
  headerId: PropTypes.string,
  isWebGL: PropTypes.bool,
};

export default SectionNine;
