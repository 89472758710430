import React from 'react';
import styled from 'styled-components';
import {Header, Text} from '../../../../components/common';

import {getCurrentLayoutProperty} from '../../../../utils/common';
import useAppLayout from '../../../../utils/useAppLayout';
import {TEAM_INFO, TITLE} from '../../constants/sectionThree';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${getCurrentLayoutProperty({
    isDesktop: 'calc(100vw - 264px)',
    isLaptop: 'calc(100vw - 200px)',
    isTablet: '100vw',
    isMobile: '100vw',
  })};
  margin: auto;
  max-width: 1925px;
`;

const Title = styled(Header)`
  margin: ${getCurrentLayoutProperty({
    isTablet: 'auto 92px',
    isMobile: 'auto 16px',
  })};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${getCurrentLayoutProperty({
    isDesktop: 'row',
    isLaptop: 'row',
    isTablet: 'column',
    isMobile: 'column',
  })};
  margin-top: ${getCurrentLayoutProperty({
    isDesktop: '80px',
    isLaptop: '80px',
    isTablet: '80px',
    isMobile: '40px',
  })};
`;

const ImageWrapper = styled.div`
  display: flex;
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  > img {
    width: 100%;
    height: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${getCurrentLayoutProperty({
    isDesktop: '50%',
    isLaptop: '50%',
    isTablet: '100%',
    isMobile: '100%',
  })};
  background: #ff4242;
  color: white;
  gap: 40px;
  padding: ${getCurrentLayoutProperty({
    isDesktop: '40px 134px',
    isLaptop: '40px 35px',
    isTablet: '230px 150px 230px 97px',
    isMobile: '80px 40px 80px 16px',
  })};
  > img {
    width: ${getCurrentLayoutProperty({
      isDesktop: '113px',
      isLaptop: '73px',
      isTablet: '73px',
      isMobile: '73px',
    })};
    height: ${getCurrentLayoutProperty({
      isDesktop: '113px',
      isLaptop: '73px',
      isTablet: '73px',
      isMobile: '73px',
    })};
  }
`;

const Description = styled(Text)`
  font-size: ${getCurrentLayoutProperty({
    isDesktop: '35px',
    isLaptop: '35px',
    isTablet: '35px',
    isMobile: '17px',
  })};
  letter-spacing: 0.01em;
  font-weight: ${getCurrentLayoutProperty({
    isDesktop: '700',
    isLaptop: '700',
    isTablet: '700',
    isMobile: '600',
  })};
  line-height: 140%;
`;

const SectionThree = () => {
  const layout = useAppLayout();

  return (
    <SectionWrapper layout={layout}>
      <Title layout={layout}>{TITLE}</Title>
      <Wrapper layout={layout}>
        <ImageWrapper layout={layout}>
          <img src={'/pages/inside/rocket.webp'} alt="rocket" />
        </ImageWrapper>
        <TextWrapper layout={layout}>
          <img src={'/pages/inside/union.webp'} alt="union" />
          <Description layout={layout}>{TEAM_INFO}</Description>
        </TextWrapper>
      </Wrapper>
    </SectionWrapper>
  );
};

export default SectionThree;
